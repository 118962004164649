import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { Partition } from '../../bx-services/subscription/partition-proxy.model';
import { Customer } from '../../bx-services/user/user-self-proxy.model';
import { PersistenceService } from '../../core/services/persistence.service';
interface SelectedData {
  customer?: Customer;
  partitions: Partition[];
}

export const compareSelectedData = (a: SelectedData, b: SelectedData): boolean => {
  if (a.customer?.id != b.customer?.id || 
    a.partitions.length != b.partitions.length) {
    return false;
  }

  const aIds = a.partitions.map(partition => partition.id);
  const bIds = a.partitions.map(partition => partition.id);
  const matchingPartitions = aIds.filter(id => bIds.includes(id));
  return matchingPartitions.length === aIds.length;
}

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  private readonly selectedDataBehaviorSub = new BehaviorSubject<SelectedData>({ customer: undefined, partitions: [] });

  constructor(private readonly persistenceService: PersistenceService) {}

  /* eslint-disable-next-line @typescript-eslint/member-ordering */
  public selectedData$ = this.selectedDataBehaviorSub.asObservable().pipe(distinctUntilChanged((prev, curr) => compareSelectedData(prev, curr)));
  
  public setSelectedData(customer: Customer | undefined, partitions: Partition[]): void {
    if (!customer) {
      return;
    }
    this.persistenceService.customerId = customer.id;
    this.persistenceService.partitionIds = partitions
      .filter(partition => Boolean(partition))
      .map(partition => partition.id);
    this.selectedDataBehaviorSub.next({ customer, partitions });
  }
}
