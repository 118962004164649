import { Injectable, OnDestroy } from "@angular/core";
import { SiteSelectionServiceBase } from "@gms-flex/services";
import { SummaryBarSnapInServiceBase } from "@gms-flex/summary-bar";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

@Injectable()
export class SummaryBarBxSubstituteService extends SummaryBarSnapInServiceBase implements OnDestroy {

  private readonly subscriptions: Subscription[] = [];

  private readonly _selectedCategory: BehaviorSubject<number | undefined>
    = new BehaviorSubject<number | undefined>(undefined);
  private readonly _disableFrameSwitch: BehaviorSubject<boolean>
    = new BehaviorSubject<boolean>(undefined);
  
  public constructor(siteSelection: SiteSelectionServiceBase) {
    super();
    this.subscriptions.push(siteSelection.selectedSite.subscribe(site => {
      // we want disable the switch if we are in multiSite mode
      this._disableFrameSwitch.next(!site.singleSiteActive);
    }));
  }

  public setSelectedCategory(category: number | undefined): void {
    this._selectedCategory.next(category);
  }

  public get getSelectedCategory(): Observable<number | undefined> {
    return this._selectedCategory.asObservable();
  }

  public buzzerVisible(): boolean {
    return false;
  }

  public disableFrameSwitch(): Observable<boolean> {
    return this._disableFrameSwitch;
  }
  
  public showTotalEvNumberOnly(): boolean {
    return true;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
  }
}
