import { Injectable, OnInit } from "@angular/core";
import { SystemsServiceBase } from "@gms-flex/services";
import { TranslateService } from "@ngx-translate/core";
import { Borders, Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';

import { TrendExportMapperBaseService } from "./trend-export-mapper-base-service";

@Injectable()
export class TrendExcelExportService extends TrendExportMapperBaseService {
  
  constructor(protected readonly translateService: TranslateService, protected readonly systemsService: SystemsServiceBase) {
    super(translateService, systemsService);
  }

  public exportFile(): void {
    const filename = `${this.fileName}.${this.fileType}`;
    const contentType = this.fileType === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv;charset=utf-8;';
    const workbook = this.getTrendDataContent();
  
    let combinedCSV = "";
    try {
      if (this.fileType == 'csv') {
        workbook.worksheets.forEach((worksheet, index) => {
          combinedCSV += `Sheet: ${worksheet.name}\n`;
        
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            const rowData = Array.isArray(row.values) ? row.values
              .map(cell => {
                let cellValue = cell?.toString() ?? '';
                // If the cell contains a comma, wrap the value in double quotes to preserve it as a single field
                if (cellValue.includes(',')) {
                  cellValue = `"${cellValue.replace(/"/g, '""')}"`;
                }
                return cellValue;
              })
              .join(',') : '';
            combinedCSV += rowData + "\n";
          });
          combinedCSV += "\n\n";
        });
        const csvBlob = new Blob([combinedCSV], { type: contentType });
        fs.saveAs(csvBlob, filename);
      } else {
        workbook[this.fileType].writeBuffer().then(buffer => {
          const blob = new Blob([buffer], {
            type: contentType
          });
          fs.saveAs(blob, filename);
        });
      }
    } catch (error) {
      // console.error("Error during file export:", error);
    }
  }

  protected getTrendDataContent(): Workbook {
    const workbook = new Workbook();
    const thinBorder: Partial<Borders> = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
    
    let startColumn = 1;
    const startRow = 1;

    this.dataToExport.tableValues.forEach(table => {
      const worksheet = workbook.addWorksheet(table.pointValues[0]);
      this.addHeaders(worksheet, this.dataToExport.sheetHeaders, startRow, startColumn, thinBorder);
      this.addDataValues(worksheet, [this.dataToExport.sheetValues], startRow + 1, startColumn, thinBorder);
      this.addHeaders(worksheet, this.dataToExport.pointHeaders, startRow + 3, startColumn, thinBorder);
      this.addDataPointInfo(worksheet, [table.pointValues], startRow + 4, startColumn, thinBorder);
      this.addHeaders(worksheet, table.dataHeaders, startRow + 6, startColumn, thinBorder);
      this.addDataValues(worksheet, table.dataValues, startRow + 7, startColumn, thinBorder, table.resolution);
      startColumn = 1;
      this.adjustColumnWidths(worksheet);
    });
    
    return workbook;
  }

  protected calculateDynamicColumnWidths(data: (string | number)[][]): string[] {
    throw new Error("Method not implemented.");
  }

  private adjustColumnWidths(worksheet: Worksheet): void {
    worksheet.eachRow(row => {
      row.eachCell((cell, colNumber) => {
        const column = worksheet.getColumn(colNumber);
        const cellValue = cell.value ? cell.value.toString() : "";
        column.width = Math.max(column.width || 10, cellValue.length + 1);
      });
    });
  }

  private addHeaders(worksheet: Worksheet, headers: string[], startRow: number, startColumn: number, border: Partial<Borders>): void {
    headers.forEach((header: string, colIndex: number) => {
      const cell = worksheet.getCell(startRow, startColumn + colIndex);
      cell.value = header;
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFCC' }
      };
      cell.border = border;
    });
  }

  private addDataValues(worksheet: Worksheet, dataValues: string[][], startRow: number, startColumn: number, border: Partial<Borders>, 
    _resolution: number = 0): void {
    dataValues.forEach((row: string[], rowIndex: number) => {
      row.forEach((value, colIndex) => {
        const cell = worksheet.getCell(startRow + rowIndex, startColumn + colIndex);
        const val = this.checkStringType(value)
        if (val == 'Number') {
          const [cellValue, excelFormatString] = this.getFormattedDecimalValue(value, _resolution);
          cell.value = cellValue;
          cell.numFmt = excelFormatString;
        } else if (val == 'Date') {
          cell.value = this.formatDateToBrowserLocale(value);
          cell.numFmt = this.getExcelDateFormat(value);
        } else {
          cell.value = value;
        }
        cell.border = border;
      });
    });
  }

  private addDataPointInfo(worksheet: Worksheet, dataValues: string[][], startRow: number, startColumn: number, border: Partial<Borders>, 
    _resolution: number = 0): void {
    dataValues.forEach((row: string[], rowIndex: number) => {
      row.forEach((value, colIndex) => {
        const cell = worksheet.getCell(startRow + rowIndex, startColumn + colIndex);
        cell.value = value;
        cell.border = border;
      });
    });
  }

  private getFormattedDecimalValue(cellValue: string, resolutionValue: number): [number, string] {
    const currentLocale = this.locale; 

    const cellVal = parseFloat(cellValue);

    const excelFormatString = this.generateExcelFormatString(currentLocale, resolutionValue);

    return [cellVal, excelFormatString];
  }

  private generateExcelFormatString(locale: string, decimalDigits: number): string {
    const numberFormatter = new Intl.NumberFormat(locale);

    const groupSeparator = numberFormatter.formatToParts(12345).find(part => part.type === 'group')?.value || '';
    const decimalSeparator = numberFormatter.formatToParts(1.1).find(part => part.type === 'decimal')?.value || '.';

    let formatString = "#,##0";

    if (decimalDigits > 0) {
      formatString += decimalSeparator + new Array(decimalDigits + 1).join('0');
    }
    if (groupSeparator) {
      formatString = formatString.replace(',', groupSeparator);
    }
    return formatString;
  }

  private getExcelDateFormat(date: string): string {
    const formattedDate = this.formatDateToBrowserLocale(date);
    const is12HourFormat = formattedDate.includes('AM') || formattedDate.includes('PM');
    let dateFormat = '';
    if (formattedDate.includes('/')) {
      dateFormat = 'mm/dd/yyyy';
    } else if (formattedDate.includes('.')) {
      dateFormat = 'dd.mm.yyyy';
    } else if (formattedDate.includes('-')) {
      dateFormat = 'yyyy-mm-dd';
    }

    if (is12HourFormat) {
      dateFormat += ', hh:mm AM/PM';
    } else {
      dateFormat += ', HH:mm';
    }
    return dateFormat;
  }
}
