import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PointAttributes } from "src/app/bx-services/point/point-proxy.model";

import { formatNumericString } from "../../../core/shared/string-utils";

export interface DccUnit {
  id: number;
  textTable: string;
  unit: string;
}

export class PropertyMapper {
  private static unitCounter = 0;
  private static readonly qudtUnitToDccUnit: Map<string, DccUnit> = new Map<string, DccUnit>();
  private readonly unitTextTable = 'TxG_Unit';
  
  protected getDccUnit(qudtPipe: SiqudtUnitConversionPipe, qudtUnit: string | undefined): DccUnit | undefined {
    if (!qudtUnit) {
      return undefined;
    }

    if (!PropertyMapper.qudtUnitToDccUnit.has(qudtUnit)) {
      PropertyMapper.qudtUnitToDccUnit.set(qudtUnit, 
        {
          id: PropertyMapper.unitCounter++, 
          textTable: this.unitTextTable, 
          unit: String(qudtPipe.transform(qudtUnit)) 
        });
    }
    return PropertyMapper.qudtUnitToDccUnit.get(qudtUnit);
  }

  protected showDisplayValue(quality?: number): boolean {
    if (quality) {
      return (quality === 0 || quality === 1);
    } else {
      return true;
    }
  }

  // Retrieves the resolution from pointAttributes.precision.
  // The resolution is the number of decimal digits used to display a value.
  // 
  // Information about precision according to GET Point Definition endpoint
  // (https://code.siemens.com/horizon/platform-verticals/Point/point-nb-information/-/blob/master/api/openapi-spec-v2.yml):
  // precision: string
  // Indicates the smallest recognizable change of a number value, applicable to point.dataType number and integer. 
  // If a precision is provided it must be > 0. Value range will not be validated by the cloud.
  // examples: 0.1
  // 
  // This function gets the number of decimal digits of pointAttributes.precision to use as resolution.
  // In case of undefined or invalid precision value (e.g. non numeric value, Infinity, null, empty), undefined is returned.
  protected evalResolution(pointAttributes: PointAttributes): number | undefined {
    const precision = formatNumericString(pointAttributes?.precision);
    if (!precision || precision === '0' || precision.startsWith('-')) {
      // Invalid precision value
      return undefined;
    }

    const decimals = precision.split('.')[1];
    if (!decimals) {
      return 0;
    }
    return decimals.length;
  }

  protected roundByPrecision(pointAttributes: PointAttributes): number | undefined {
    // We can update precision value for point from Point Definition PATCH api with string value
    // API_URL: https://product-int.point.horizonint.cloud/api/v2/organizations/{organizationID}/points/pointID

    if (!pointAttributes?.precision) {
      return undefined;
    }

    try {
      // Parse precision to a number
      const precision = parseFloat(pointAttributes?.precision);

      if (isNaN(precision)) {
        return 0;
      }

      return Math.round(precision);
    } catch (error) {
      // Handle any unexpected characters 
      return 0;
    } 
  }
}
