import { Injectable } from '@angular/core';

import { SystemBrowserSettingsServiceBase } from './system-browser-settings.service.base';

/**
 * SystemBrowserSettings service.
 * Provides the methods to get if system browser functionalities are visible.
 *
 * @export
 * @class SystemBrowserSettingsService
 * @extends {SystemBrowserSettingsServiceBase}
 */

@Injectable({
  providedIn: 'root'
})
export class SystemBrowserSettingsService implements SystemBrowserSettingsServiceBase {
  public showFilterButton(): boolean {
    return true;
  }
}
