import { A11yModule } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostAttributeToken,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  positionBottomCenter,
  positionBottomEnd,
  positionBottomStart,
  positionTopCenter,
  positionTopEnd,
  positionTopStart
} from '@simpl/element-ng/common';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getMaxDate, getMinDate } from './date-time-helper';
import { SiDateInputDirective } from './si-date-input.directive';
import { SiDatepickerOverlayComponent } from './si-datepicker-overlay.component';
import { CloseCause, SiDatepickerOverlayDirective } from './si-datepicker-overlay.directive';
import { DatepickerInputConfig, DateRange } from './si-datepicker.model';

@Component({
  selector: 'si-date-range',
  templateUrl: './si-date-range.component.html',
  styleUrl: './si-date-range.component.scss',
  host: {
    class: 'form-control d-flex align-items-center pe-2',
    role: 'group'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiDateRangeComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiDateRangeComponent,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiDateRangeComponent
    }
  ],
  hostDirectives: [
    {
      directive: SiDatepickerOverlayDirective,

      outputs: ['siDatepickerClose']
    }
  ],
  standalone: true,
  imports: [FormsModule, SiDateInputDirective, SiTranslateModule, A11yModule]
})
export class SiDateRangeComponent
  implements ControlValueAccessor, Validator, AfterViewInit, OnChanges, OnDestroy, SiFormItemControl
{
  private static idCounter = 0;

  @ViewChildren(SiDateInputDirective) private inputDirectives!: QueryList<SiDateInputDirective>;
  @ViewChild('startInput', { static: true }) private startInput!: NgModel;
  @ViewChild('endInput', { static: true }) private endInput!: NgModel;
  @ViewChild('button', { read: ElementRef, static: true }) private button!: ElementRef<HTMLElement>;

  @Input() id = `__si-date-range-${SiDateRangeComponent.idCounter++}`;

  @HostBinding('attr.aria-labelledby') labelledby =
    inject(new HostAttributeToken('aria-labelledby'), {
      optional: true
    }) ?? `${this.id}-label`;

  /**
   * Date range component configuration.
   *
   * @defaultValue
   * ```
   * { enableDateRange: true }
   * ```
   */
  @Input() siDatepickerConfig: DatepickerInputConfig = { enableDateRange: true };
  /**
   * Placeholder of the start date input.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.START_DATE_PLACEHOLDER:Start date`
   * ```
   */
  @Input() startDatePlaceholder = $localize`:@@SI_DATEPICKER.START_DATE_PLACEHOLDER:Start date`;
  /**
   * Placeholder of the end date input.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.END_DATE_PLACEHOLDER:End date`
   * ```
   */
  @Input() endDatePlaceholder = $localize`:@@SI_DATEPICKER.END_DATE_PLACEHOLDER:End date`;
  /**
   * Aria label of the date-range calendar toggle button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.CALENDAR_TOGGLE_BUTTON:Open calendar`
   * ```
   */
  @Input()
  ariaLabelCalendarButton = $localize`:@@SI_DATEPICKER.CALENDAR_TOGGLE_BUTTON:Open calendar`;
  /**
   * Form label of the start timepicker.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.START_TIME_LABEL:from`
   * ```
   */
  @Input() startTimeLabel = $localize`:@@SI_DATEPICKER.START_TIME_LABEL:from`;
  /**
   * Form label of the start timepicker.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.END_TIME_LABEL:to`
   * ```
   */
  @Input() endTimeLabel = $localize`:@@SI_DATEPICKER.END_TIME_LABEL:to`;
  /**
   * @deprecated Property has no effect and will be removed without a replacement.
   *
   * @defaultValue 200
   */
  @Input() debounceTime = 200;
  /**
   * Automatically close overlay on date selection.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) autoClose = false;
  /** Emits on the date range value changes. */
  @Output() readonly siDatepickerRangeChange = new EventEmitter<DateRange>();
  /**
   * Emits an event to notify about disabling the time from the range picker.
   * When time is disable, we construct a pure date objects in UTC 00:00:00 time.
   */
  @Output() readonly disabledTimeChange = new EventEmitter<boolean>();

  /**
   * Whether the date range input is disabled.
   *
   * @defaultValue false
   */
  @HostBinding('class.disabled')
  @Input({ transform: booleanAttribute })
  disabled = false;

  /**
   * Whether the date range input is readonly.
   *
   * @defaultValue false
   */
  @HostBinding('class.readonly')
  @Input({ transform: booleanAttribute })
  readonly = false;

  get value(): typeof this._value {
    return this._value;
  }

  /**
   * Set the date-range object displayed in the control.
   * The input can be used if the control is used outside Angular forms.
   * @defaultref {@link _value}
   */
  @Input()
  set value(value) {
    this.updateValue(value);
  }

  private _value?: {
    start: Date | undefined;
    end: Date | undefined;
  };
  private validator!: ValidatorFn;
  private onChange = (val: any): void => {};
  private onTouch = (): void => {};
  private readonly destroyer$ = new Subject<void>();
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly overlayToggle = inject(SiDatepickerOverlayDirective);
  private readonly elementRef = inject(ElementRef);
  private readonly defaultPlacement = [
    positionBottomCenter,
    positionBottomStart,
    positionBottomEnd,
    positionTopCenter,
    positionTopStart,
    positionTopEnd
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.siDatepickerConfig) {
      this.siDatepickerConfig = {
        ...changes.siDatepickerConfig.currentValue,
        enableDateRange: true,
        startTimeLabel: changes.siDatepickerConfig.currentValue.startTimeLabel
          ? changes.siDatepickerConfig.currentValue.startTimeLabel
          : this.startTimeLabel,
        endTimeLabel: changes.siDatepickerConfig.currentValue.endTimeLabel
          ? changes.siDatepickerConfig.currentValue.endTimeLabel
          : this.endTimeLabel
      };
    }
    this.overlayToggle.setInputs({ config: this.siDatepickerConfig, dateRange: this.value });
  }

  ngAfterViewInit(): void {
    this.validator = Validators.compose([
      () => this.endAfterStartValidator(),
      this.childValidation
    ])!;

    this.overlayToggle.placement = this.defaultPlacement;
    this.overlayToggle.siDatepickerClose.pipe(takeUntil(this.destroyer$)).subscribe(cause => {
      if ([CloseCause.Escape, CloseCause.Select].includes(cause)) {
        this.button.nativeElement.focus();
      } else {
        // Mark component as touch when the focus isn't recovered on input
        this.onTouch();
        this.cdRef.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  writeValue(dateRange: DateRange): void {
    this.updateValue(dateRange);
    this.overlayToggle.setInputs({ dateRange });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator ? this.validator(c) : null;
  }

  /**
   * Focus out shall close the datepicker except we are moving the focus to the datepicker or one of the input elements.
   * @param event - focus out event with the related target
   */
  @HostListener('focusout', ['$event'])
  protected onFocusOut(event: FocusEvent): void {
    const target = event.relatedTarget as HTMLInputElement;
    if (!this.overlayToggle.contains(target)) {
      this.overlayToggle.closeOverlay();

      // Only mark the component as touched when the focus is not moved to the datepicker or one of the input elements.
      if (!this.elementRef.nativeElement.contains(target)) {
        this.onTouch();
      }
    }
  }

  /** Forward date range input changes to datepicker overlay */
  protected onInputChanged(dateRange: DateRange): void {
    this.updateValue(dateRange);
    this.onChange(this.value);
    this.siDatepickerRangeChange.emit(this.value);
  }

  protected show(): void {
    if (this.readonly || this.disabled) {
      return;
    }
    this.subscribeRangeChanges(
      this.overlayToggle.showOverlay(true, {
        config: this.siDatepickerConfig,
        dateRange: this.value
      })
    );
  }

  private subscribeRangeChanges(overlay?: ComponentRef<SiDatepickerOverlayComponent>): void {
    overlay?.instance.dateRangeChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(d => this.onRangeChanged(d));
    overlay?.instance.disabledTimeChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(disabledTime => {
        this.inputDirectives.forEach(inputDirective => inputDirective.onDisabledTime(disabledTime));
        this.disabledTimeChange.emit(disabledTime);
      });
  }

  private onRangeChanged(range: DateRange): void {
    this.updateValue(range);
    this.onChange(range);
    this.siDatepickerRangeChange.emit(range);
    this.validateChildren();

    if (this.autoClose && this.value?.start && this.value?.end) {
      this.overlayToggle.closeAfterSelection();
    }
    this.cdRef.markForCheck();
  }

  /** Run validators on the start/end inputs. */
  private validateChildren(): void {
    this.inputDirectives.forEach(d => d.validatorOnChange());
  }

  /** The form control validator for the end date is greater equal start date. */
  private endAfterStartValidator(): ValidationErrors | null {
    const endDate = this.endInput.value;
    const startDate = this.startInput.value;

    return !endDate || !startDate || endDate >= startDate
      ? null
      : {
          endBeforeStart: {
            start: startDate,
            end: endDate
          }
        };
  }

  private readonly childValidation: ValidatorFn = () => {
    const errors: Record<string, any> = {};
    this.readErrorsFromInnerControl(this.startInput, 'Start', errors);
    this.readErrorsFromInnerControl(this.endInput, 'End', errors);

    if (Object.keys(errors).length) {
      return errors;
    }

    return null;
  };

  private readErrorsFromInnerControl(
    control: NgModel,
    type: 'Start' | 'End',
    errors: Record<string, any>
  ): void {
    if (control.invalid) {
      const formatError = control.getError('dateFormat');
      if (formatError) {
        errors[`invalid${type}DateFormat`] = formatError;
      }
      const minError = control.getError('minDate');
      if (minError) {
        errors.rangeBeforeMinDate = {
          min: getMinDate(this.siDatepickerConfig?.minDate),
          start: this.startInput.value,
          end: this.endInput.value
        };
      }
      const maxError = control.getError('maxDate');
      if (maxError) {
        errors.rangeAfterMaxDate = {
          max: getMaxDate(this.siDatepickerConfig?.maxDate),
          start: this.startInput.value,
          end: this.endInput.value
        };
      }
    }
  }

  private updateValue(value?: DateRange): void {
    if (value !== this._value) {
      this._value = value;
      // this allows angular's built in required validator to work correctly
      if (!value?.start && !value?.end) {
        this._value = undefined;
      }
    }
  }
}
