import { Component, inject, OnInit } from '@angular/core';
import { AVAILABLE_LANGUAGES, SiCookiesService } from '@building-x/common-ui-ng';
import { TranslateService } from '@ngx-translate/core';
import { SiTranslateService } from '@simpl/element-translate-ng/translate';

import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  protected availableLangs = AVAILABLE_LANGUAGES;
  protected mfaStartDate = new Date(environment.mfaStartDate);
  protected mfaEndDate = new Date(environment.mfaEndDate);
  protected mfaDocLink = environment.mfaDocLink;
  protected bxCommonAssetsPath = environment.bxCommon;

  protected readonly translate = inject(SiTranslateService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly cookieService: SiCookiesService
  ) {
  }

  public ngOnInit(): void {
    const storedLang = this.cookieService.getCookie('lang') || 'en';
    this.translate.setCurrentLanguage(storedLang);

    this.translateService.onLangChange.subscribe(languageInfo => {
      this.cookieService.setCookie('lang', languageInfo.lang);
    });
  }
}
