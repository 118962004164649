import { Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BrowserObject, ConnectionState, DpIdentifier, EventProxyServiceBase, Page, SiteSelectionServiceBase, SystemBrowserServiceBase,
  TraceModules, ValidationInput, WSIEvent } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, BehaviorSubject, concatMap, map, Observable, of, scheduled, Subject, Subscription, tap, throwError, timer, zip } from 'rxjs';

import { EventBx } from '../../bx-services/alarm/events-proxy.model';
import { EventsProxyService } from '../../bx-services/alarm/events-proxy.service';
import { LocationService } from '../../bx-services/location/location.service';
import { ContextService } from '../state/context.service';
import { SystemBrowserBxSubstituteService } from '../system-browser/system-browser-bx-substitute.service';
import { EventMapperBxToGmsService, EventStateGms } from './event-mapper-bx-to-gms.service';

const pollRateEvents = 30000;

const delayEventReadingAtStartup = 100;
const disableEventsReading = false;
const enableBulkPointResolve = true;

export interface EventResponseOfBuilding {
  partitionId: string;
  buildingId: string;
  eventResponse: EventBx[];
}

export interface SelectedSite {
  objectId: string | undefined;
  singleSiteActive: boolean | undefined;
  siteName: string | undefined;
  objectType: string | undefined;
  buildingsIds: string[] | undefined;
}

@Injectable()
export class EventBxSubstituteProxyService extends EventProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _eventsWsi: Subject<WSIEvent[]> = new Subject<WSIEvent[]>();
  private readonly _eventsBuildingX: BehaviorSubject<EventResponseOfBuilding[]> = new BehaviorSubject<EventResponseOfBuilding[]>([]);
  private readonly eventsPerId: Map<string, WSIEvent> = new Map<string, WSIEvent>();
  private readonly partitionIdPerEventId: Map<string, string> = new Map<string, string>();
  private timerSubscription: Subscription;
  private selectedPartitions: string[] = [];
  private readonly sourceFailedToBeResolved: Map<string, string> = new Map<string, string>();
  private selectedSite: SelectedSite = undefined;
  private subscriberCount = 0;
  private readEventsReqSubscription: Subscription[] = [];
  private resolveObjReqSubscription: Subscription[] = [];

  public constructor(
    private readonly traceService: TraceService,
    private readonly eventsProxy: EventsProxyService,
    private readonly eventMapper: EventMapperBxToGmsService,
    private readonly locationService: LocationService,
    private readonly systemBrowserOmService: SystemBrowserServiceBase,
    private readonly siteSelectionService: SiteSelectionServiceBase,
    private readonly contextService: ContextService,
    private readonly ngZone: NgZone) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered.  There is no constant streaming channel.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.contextService.selectedData$
      .pipe(takeUntilDestroyed())
      .subscribe(selectedData => {

        if (selectedData.partitions.length > 0) {
          this.traceService.info(TraceModules.events,
            `EventBxSubstituteProxyService: Current selected partitions: ${selectedData.partitions.map(partition => partition.id).join()}`);

          // Find partitions that have been unselected to clear their events
          const partitionsToBeDeleted: string[] = [];
          this.selectedPartitions.forEach(selectedPartition => {
            if (selectedData.partitions.findIndex(newPartition => newPartition.id === selectedPartition) === -1) {
              partitionsToBeDeleted.push(selectedPartition);
            }
          });
          this.clearEventsOfPartitions(partitionsToBeDeleted);

          let delayEventReading = 100;
          if (this.selectedPartitions.length === 0) {
          // at startup, we do delay the event reading... (only POC)
          // TODO: remove? or delay dependent on the current active snapin?
          // TODO: app start up and selecting customer/partitions is separate feature.
            delayEventReading = delayEventReadingAtStartup;
          }
          this.selectedPartitions = selectedData.partitions.map(partition => partition.id);

          this.stopTimerForSubscription();
          if (!disableEventsReading) {
            this.startTimerForSubscription(delayEventReading, true);
          }
        } else {
          this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService: Current selected partitions: No partitions selected`);
          this.clearAllEvents();
          this.selectedPartitions = [];
        }
      });

    this.siteSelectionService.selectedSite.subscribe(selectedSite => {
      this.selectedSite = selectedSite;
      this.stopAndStartTimerForSubscription();
    });

    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService created.');
  }

  public getEvents(): Observable<WSIEvent[]> {
    // TODO: implement properly!
    return of([]);
  }

  public eventsNotification(): Observable<WSIEvent[]> {
    return this._eventsWsi.asObservable();
  }

  public eventsNotificationBuildingX(): Observable<EventResponseOfBuilding[]> {
    return this._eventsBuildingX.asObservable();
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public unsubscribeEvents(): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService.unSubscribeEvents() called');

    if (this.subscriptionActive) {
      this.subscriberCount--;
    }
    return scheduled([true], asapScheduler);
  }

  // manages bulk commanding when addressing more than 1 event
  public postCommand2Events(evIds: string[], commandId: string, treatmentType?: string, validationInput?: ValidationInput): Observable<boolean> {
    return throwError(() => new Error('EventBxSubstituteProxyService.postCommand2Events(): Not Implemented!'));
  }

  public subscribeEvents(hiddenEvents = false): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService.subscribeEvents() called.');

    this.subscriberCount++;
    return scheduled([true], asapScheduler);
  }

  public serverClientTimeDiff(isoString: string): Observable<any> {
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService.serverClientTimeDiff() called. timestamp: %s', isoString);

    // TODO: check the semantic and the behavior in the client
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    return of({ NTPt1t0: 10, ServerDateTime: isoString, ClientDateTime: isoString });
  }

  private stopAndStartTimerForSubscription(): void {
    this.stopTimerForSubscription();
    if (!disableEventsReading) {
      this.startTimerForSubscription(delayEventReadingAtStartup, true);
    }
  }

  private readAllEvents(): Observable<EventResponseOfBuilding[]> {
    const startTime = performance.now();
    this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService.readAllEvents() called`);

    // If multi site view is shown
    if (!this.selectedSite?.singleSiteActive) {
      // return events of all partitions 
      return this.getAllPartitionsEvents();
    }

    const partitionId = this.selectedSite?.objectId?.split(':')[0];

    // If partition id is invalid, no events are returned
    if (!partitionId) {
      this.traceService.error(TraceModules.events,
        `EventBxSubstituteProxyService.readAllEvents();
          invalid selected site data`);
      return;
    }

    const getEventsOfBuilding$: Observable<EventBx[]>[] = [];

    this.selectedSite.buildingsIds.forEach(buildingId => getEventsOfBuilding$.push(this.eventsProxy.getEventsOfBuilding(partitionId, buildingId)));

    return zip(getEventsOfBuilding$).pipe(
      map(eventsPerBuilding => {
        const eventResponseAll: EventResponseOfBuilding[] = [];
        for (let buildingIdx = 0; buildingIdx < eventsPerBuilding.length; buildingIdx++) {
          const eventRes: EventBx[] = eventsPerBuilding[buildingIdx] ?? [];
          eventResponseAll.push({ buildingId: this.selectedSite?.buildingsIds[buildingIdx], partitionId: partitionId, eventResponse: eventRes });
        }
        return eventResponseAll;
      }),
      tap(result => {
        let eventsTotal = 0;
        result.forEach(eventsPerBuilding => eventsTotal += eventsPerBuilding.eventResponse.length);
        this.traceService.info(TraceModules.events,
          `EventBxSubstituteProxyService.readAllEvents() done;
            total time used: ${performance.now() - startTime} ms; for number of events: ${eventsTotal}`);
      })
    );
  }

  private getAllPartitionsEvents(): Observable<EventResponseOfBuilding[]> {
    const startTime = performance.now();

    if (this.selectedPartitions.length === 0) {
      return of([]);
    }

    const getAllBuilding$ = this.selectedPartitions.map(partId => this.locationService.getLocationBuilding(partId));

    return zip(getAllBuilding$).pipe(
      concatMap(buildingsPerPartition => {
        const getEventsOfBuilding$: Observable<EventBx[]>[] = [];
        const partitionsOfBuilding: string[] = [];
        const buildingIds: string[] = [];
        for (let idx = 0; idx < buildingsPerPartition.length; idx++) {
          getEventsOfBuilding$.push(...buildingsPerPartition[idx].map(building =>
            this.eventsProxy.getEventsOfBuilding(this.selectedPartitions[idx], building.id)));
          partitionsOfBuilding.push(...buildingsPerPartition[idx].map(building => this.selectedPartitions[idx]));
          buildingIds.push(...buildingsPerPartition[idx].map(building => building.id));

        }
        return zip(getEventsOfBuilding$).pipe(
          map(eventsPerBuilding => {
            const eventResponseAll: EventResponseOfBuilding[] = [];
            for (let buildingIdx = 0; buildingIdx < eventsPerBuilding.length; buildingIdx++) {
              const eventRes: EventBx[] = (eventsPerBuilding[buildingIdx] !== undefined) ? eventsPerBuilding[buildingIdx] : [];
              eventResponseAll.push({ buildingId: buildingIds[buildingIdx], partitionId: partitionsOfBuilding[buildingIdx], eventResponse: eventRes });
            }
            return eventResponseAll;
          }),
          tap(result => {
            let eventsTotal = 0;
            result.forEach(eventsPerBuilding => eventsTotal += eventsPerBuilding.eventResponse.length);
            this.traceService.info(TraceModules.events,
              `EventBxSubstituteProxyService.readAllEvents() done;
                total time used: ${performance.now() - startTime} ms; for number of events: ${eventsTotal}`);
          })
        );
      })
    );
  }

  private get subscriptionActive(): boolean {
    return (this.subscriberCount > 0);
  }

  private clearAllEvents(): void {
    // TODO: Consider optimization: clearing shall consider only the added/removed partitions
    if (this.subscriptionActive) {
      const eventsToBeCleared: WSIEvent[] = [];
      this.eventsPerId.forEach((event, id) => {
        eventsToBeCleared.push(event);
      });
      // clear the counters...
      this._eventsBuildingX.next([]);
      // clear the events
      this.notifyClosedEvents(eventsToBeCleared);
    }
  }

  private clearEventsOfPartitions(partitionIds: string[]): void {

    if (this.subscriptionActive && partitionIds.length > 0) {
      const eventsToBeCleared: WSIEvent[] = [];
      this.eventsPerId.forEach((event, id) => {
        if (partitionIds.findIndex(partId => partId === this.partitionIdPerEventId.get(id)) !== -1) {
          eventsToBeCleared.push(event);
        }
      });
      // clear the counters...
      const eventsBldg = this._eventsBuildingX.getValue();
      const remainingEvtsBldg: EventResponseOfBuilding[] = [];
      eventsBldg.forEach(evtBldg => {
        if (partitionIds.findIndex(partId => partId === evtBldg.partitionId) === -1) {
          remainingEvtsBldg.push(evtBldg);
        }
      });

      this._eventsBuildingX.next(remainingEvtsBldg);
      // clear the events
      this.notifyClosedEvents(eventsToBeCleared);
    }
  }

  private onTimerSubscription(): void {
    if (this.selectedPartitions.length > 0) {
      if (enableBulkPointResolve) {
        this.readAndResolveEventsOptimizedAndNotifyInBulk();      
      } else {
        this.readAndResolveEventsSingleAndNotifyInBulk();
      }
      this.startTimerForSubscription(pollRateEvents, false);
    }
  }

  /**
   * Reads all events first and then resolves the source of the events with single requests.
   * Afterwards the eventlist is notified with one notification
   */
  private readAndResolveEventsSingleAndNotifyInBulk(): void {
    const startTime = performance.now();
    this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService.readAndResolveEventsAndNotifyInBulk() called`);

    this.ngZone.runOutsideAngular(() => {
      this.readEventsReqSubscription.push(this.readAllEvents().subscribe(eventsPerBuilding => {
        const eventsRead = this.flattenEvents(eventsPerBuilding);
        const closedEvents = this.evaluateClosedEvents(eventsRead);
        this.notifyClosedEvents(closedEvents);
        this._eventsBuildingX.next(eventsPerBuilding);
        const eventsTotal = eventsRead.length;
        let eventsCount = 0;
        this.traceService.info(TraceModules.events,
          `EventBxSubstituteProxyService.readAndResolveEventsAndNotifyInBulk(): number of events: ${eventsTotal}; closed events: ${closedEvents.length}`);

        const wsiEventsToNotify: WSIEvent[] = [];
        const resolveEntitiesObs: Observable<Page>[] = [];
        eventsPerBuilding.forEach(eventsOfBuilding => {
          if (eventsOfBuilding.eventResponse !== undefined) {
            eventsOfBuilding.eventResponse.forEach(event => {
              let sourceId = event.source?.id;
              let sourceIsPoint = true;
              if (sourceId === undefined) {
                sourceId = event.deviceId;
                sourceIsPoint = false;
              }
              if (!this.sourceFailedToBeResolved.has(sourceId)) {
                if (sourceIsPoint) {
                  resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolvePoint(
                    sourceId,
                    eventsOfBuilding.partitionId).pipe(
                    concatMap(page => {
                      return (this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(event.siteId, eventsOfBuilding.partitionId).pipe(
                        tap(pageLocation => {
                          const wsiEvent = this.createEvent(page, sourceId, event, pageLocation);
                          wsiEventsToNotify.push(wsiEvent);
                          eventsCount++;
                          this.doEventsBookkeeping(wsiEvent, eventsOfBuilding.partitionId, eventsCount, eventsTotal, startTime);
                        })
                      );
                    })
                  ));
                } else {
                  resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveDevice(
                    sourceId,
                    eventsOfBuilding.partitionId).pipe(
                    concatMap(page => {
                      return (this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(event.siteId, eventsOfBuilding.partitionId).pipe(
                        tap(pageLocation => {
                          const wsiEvent = this.createEvent(page, sourceId, event, pageLocation);
                          wsiEventsToNotify.push(wsiEvent);
                          eventsCount++;
                          this.doEventsBookkeeping(wsiEvent, eventsOfBuilding.partitionId, eventsCount, eventsTotal, startTime);
                        })
                      );
                    })
                  ));
                }
              } else {
                // notify event without source info
                resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(
                  event.siteId,
                  eventsOfBuilding.partitionId).pipe(
                  tap(pageLocation => {
                    const wsiEvent = this.createEventWoSource(event, pageLocation);
                    wsiEventsToNotify.push(wsiEvent);
                    eventsCount++;
                    this.doEventsBookkeeping(wsiEvent, eventsOfBuilding.partitionId, eventsCount, eventsTotal, startTime);
                  })
                ));
              }
            });
          }
        });

        if (resolveEntitiesObs.length > 0) {
          this.resolveObjReqSubscription.push(zip(resolveEntitiesObs).subscribe(_result => {
            this.notifyEvents(wsiEventsToNotify);
            this.traceUnresolvedSourceObjects();
          }));
        } else {
          this.notifyEvents(wsiEventsToNotify);
          this.traceUnresolvedSourceObjects();
        }
      }));
    });
  }

  /**
   * Reads all events first and then resolves the source of the events with bulks of 50 points.
   * Afterwards the eventlist is notified with one notification
   */
  private readAndResolveEventsOptimizedAndNotifyInBulk(): void {
    const startTime = performance.now();
    this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService.readAndResolveEventsOptimzedAndNotifyInBulk() called`);

    this.ngZone.runOutsideAngular(() => {
      this.readEventsReqSubscription.push(this.readAllEvents().subscribe(eventsPerBuilding => {
        const eventsRead = this.flattenEvents(eventsPerBuilding);
        const closedEvents = this.evaluateClosedEvents(eventsRead);
        this.notifyClosedEvents(closedEvents);
        this._eventsBuildingX.next(eventsPerBuilding);
        const eventsTotal = eventsRead.length;
        let eventsCount = 0;
        this.traceService.info(TraceModules.events,
          `EventBxSubstituteProxyService.readAndResolveEventsOptimzedAndNotifyInBulk():
          number of events: ${eventsTotal}; closed events: ${closedEvents.length}`);

        const eventsPerPartition: Map<string, EventBx[]> = new Map<string, EventBx[]>();
        eventsPerBuilding.forEach(eventsOfBuilding => {
          if (!eventsPerPartition.has(eventsOfBuilding.partitionId)) {
            eventsPerPartition.set(eventsOfBuilding.partitionId, []);
          }
          if (eventsOfBuilding.eventResponse !== undefined) {
            eventsPerPartition.get(eventsOfBuilding.partitionId).push(...eventsOfBuilding.eventResponse);
          }
        });
        
        const wsiEventsToNotify: WSIEvent[] = [];
        const resolveEntitiesObs: Observable<Page>[] = [];
        eventsPerPartition.forEach((eventsOfPartition, partId) => {
          const sourcePointsToResolve: string[] = [];
          const eventsOfPointsToResolve: EventBx[] = [];
          eventsOfPartition.forEach(event => {
            let sourceId = event.source?.id;
            let sourceIsPoint = true;
            if (sourceId === undefined) {
              sourceId = event.deviceId;
              sourceIsPoint = false;
            }
            if (!this.sourceFailedToBeResolved.has(sourceId)) {
              if (sourceIsPoint) {
                sourcePointsToResolve.push(sourceId);
                eventsOfPointsToResolve.push(event);
              } else {
                // no source point for the alarm available, use the assigned device as source of the alarm
                resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveDevice(
                  sourceId,
                  partId).pipe(
                  concatMap(page => {
                    return (this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(event.siteId, partId).pipe(
                      tap(pageLocation => {
                        const wsiEvent = this.createEvent(page, sourceId, event, pageLocation);
                        wsiEventsToNotify.push(wsiEvent);
                        eventsCount++;
                        this.doEventsBookkeeping(wsiEvent, partId, eventsCount, eventsTotal, startTime);
                      })
                    );
                  })
                ));
              }
            } else {
              // Source of the alarm could not be retrieved -> notify event without source info
              resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(
                event.siteId,
                partId).pipe(
                tap(pageLocation => {
                  const wsiEvent = this.createEventWoSource(event, pageLocation);
                  wsiEventsToNotify.push(wsiEvent);
                  eventsCount++;
                  this.doEventsBookkeeping(wsiEvent, partId, eventsCount, eventsTotal, startTime);
                })
              ));
            }
          });

          if (sourcePointsToResolve.length > 0) {
            // source points can be resolved in bulk calls
            resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolvePointsBulk(
              sourcePointsToResolve,
              partId).pipe(
              concatMap(page => {
                const resolveLocationsObs: Observable<Page>[] = [];
                const nodesPerSourcePoint: Map<string, BrowserObject> = new Map<string, BrowserObject>();
                page.Nodes.forEach(brNode => {
                  const dpId = new DpIdentifier(brNode.ObjectId);
                  nodesPerSourcePoint.set(dpId.objectIdWoSystem, brNode);
                });
                eventsOfPointsToResolve.forEach(event => {
                  resolveLocationsObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(
                    event.siteId, partId).pipe(
                    tap(pageLocation => {
                      const wsiEvent = this.createEvent2(nodesPerSourcePoint.get(event.source.id), event.source.id, event, pageLocation);
                      wsiEventsToNotify.push(wsiEvent);
                      eventsCount++;
                      this.doEventsBookkeeping(wsiEvent, partId, eventsCount, eventsTotal, startTime);
                    })
                  ));                  
                });
                return zip(resolveLocationsObs).pipe(map(_pages => page));
              })
            ));
          }
        });

        if (resolveEntitiesObs.length > 0) {
          this.resolveObjReqSubscription.push(zip(resolveEntitiesObs).subscribe(_result => {
            this.notifyEvents(wsiEventsToNotify);
            this.traceUnresolvedSourceObjects();
          }));
        } else {
          this.notifyEvents(wsiEventsToNotify);
          this.traceUnresolvedSourceObjects();
        }
      }));
    });
  }

  private createEvent(pageSource: Page, sourceId: string, event: EventBx, pageLocation: Page): WSIEvent {
    if (pageSource.Nodes.length === 0) {
      // remember the id, do not try to resolve again later!
      this.sourceFailedToBeResolved.set(sourceId, sourceId);
    }
    const foundSource = (pageSource.Nodes.length >= 1) ? pageSource.Nodes[0] : undefined;
    const foundLocation = (pageLocation?.Nodes.length >= 1) ? pageLocation.Nodes[0] : undefined;
    return this.eventMapper.createEvent(event, foundSource, foundLocation);
  }

  private createEvent2(sourceNode: BrowserObject, sourceId: string, event: EventBx, pageLocation: Page): WSIEvent {
    if (sourceNode === undefined) {
      // remember the id, do not try to resolve again later!
      this.sourceFailedToBeResolved.set(sourceId, sourceId);
    }
    const foundLocation = (pageLocation?.Nodes.length >= 1) ? pageLocation.Nodes[0] : undefined;
    return this.eventMapper.createEvent(event, sourceNode, foundLocation);
  }

  private createEventWoSource(event: EventBx, pageLocation: Page): WSIEvent {
    const foundLocation = (pageLocation?.Nodes.length >= 1) ? pageLocation.Nodes[0] : undefined;
    return this.eventMapper.createEvent(event, undefined, foundLocation);
  }

  private traceUnresolvedSourceObjects(): void {
    if (this.sourceFailedToBeResolved.size > 0) {
      this.traceService.warn(TraceModules.events,
        `Events could not be resolved to source object. No of unresolved objects: ${this.sourceFailedToBeResolved.size}`);
    }
  }

  private notifyEvents(events: WSIEvent[]): void {
    this._eventsWsi.next(events);
  }

  private notifyClosedEvents(events: WSIEvent[]): void {
    if (events.length > 0) {
      events.forEach(event => {
        event.State = EventStateGms.Closed;
        this.eventsPerId.delete(event.Id);
        this.partitionIdPerEventId.delete(event.Id);
      });
      this._eventsWsi.next(events);
    }
  }

  private doEventsBookkeeping(currentEvent: WSIEvent, partitionId: string, countCurrent: number, countTotal: number, startTime: number): void {
    this.eventsPerId.set(currentEvent.Id, currentEvent);
    this.partitionIdPerEventId.set(currentEvent.Id, partitionId)
    if (countCurrent >= countTotal) {
      this.traceService.info(TraceModules.events,
        `EventProxyBxSubstituteService.readAndResolveEvents() done; total time used: ${performance.now() - startTime} ms`);
    }
  }

  private evaluateClosedEvents(eventsRead: EventBx[]): WSIEvent[] {
    const eventsReadMap: Map<string, EventBx> = new Map<string, EventBx>();
    eventsRead.forEach(event => eventsReadMap.set(event.eventId, event));
    const closedEvents: WSIEvent[] = [];
    this.eventsPerId.forEach((event, id) => {
      if (!eventsReadMap.has(id)) {
        closedEvents.push(event);
      }
    });
    return closedEvents;
  }

  private flattenEvents(eventsPerBuilding: EventResponseOfBuilding[]): EventBx[] {
    return eventsPerBuilding.map(eventsOfBuilding => eventsOfBuilding.eventResponse.map(event => event)).flat();
  }

  private startTimerForSubscription(delay: number, cancelCalls: boolean): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    if (cancelCalls) {
      this.readEventsReqSubscription.forEach(sub => sub?.unsubscribe());
      this.readEventsReqSubscription = [];
      this.resolveObjReqSubscription.forEach(sub => sub?.unsubscribe());
      this.resolveObjReqSubscription = [];
    }
    this.timerSubscription = timer(delay).subscribe(count => this.onTimerSubscription());
  }

  private stopTimerForSubscription(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    this.readEventsReqSubscription.forEach(sub => sub?.unsubscribe());
    this.readEventsReqSubscription = [];
    this.resolveObjReqSubscription.forEach(sub => sub?.unsubscribe());
    this.resolveObjReqSubscription = [];
  }
}
