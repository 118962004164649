import { Injectable, NgZone } from '@angular/core';
import {
  ConnectionState,
  DpIdentifier,
  Page,
  PropertyServiceBase,
  SearchOption,
  SubscriptionDeleteWsi,
  SubscriptionGmsVal,
  SubscriptionWsiVal,
  SystemBrowserServiceBase,
  SystemsProxyServiceBase,
  TraceModules,
  ValueDetails,
  ValueSubscriptionProxyServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, asyncScheduler, concatMap, map, Observable, observeOn, of, Subject, Subscription, tap, throwError, timer, zip } from 'rxjs';
import { DeviceConnectionService } from 'src/app/bx-services/device/device-connection.service';

import { PointAttributes, PointBx, PointBxValue, PointGroupType, PointValue } from '../../bx-services/point/point-proxy.model';
import { PointService } from '../../bx-services/point/point.service';
import { EntityType } from '../../bx-services/shared/base.model';
import { PropertyBxSubstituteService } from '../properties/property-bx-substitute.service';
import { PropertyMapperBxToGmsService } from '../properties/property-mapper-bx-to-gms.service';
import { bacCurrentPriorityName } from '../properties/property-mappers/bac-current-prio-mapper.service';
import { bacPrioArrayPropertyName } from '../properties/property-mappers/bac-prio-array-mapper.service';
import { bacStatusPropertyName } from '../properties/property-mappers/bac-status-flags-mapper.service';
import { commandedWithPrioPropertyName } from '../properties/property-mappers/cmd-with-prio-mapper.service';
import { deviceConnectionPropertyName } from '../properties/property-mappers/device-connection-mapper.service';
import { pointPropertyName } from '../properties/property-mappers/point-value-mapper.service';
import { aggregatedSummaryPropertyName } from '../properties/property-mappers/summary-status-mapper.service';
import { UtilityService } from '../shared/utility.service';
import { SystemBrowserBxSubstituteService } from '../system-browser/system-browser-bx-substitute.service';
import { SystemBrowserMapperBxToGmsService } from '../system-browser/system-browser-mapper-bx-to-gms.service';

const pollRateValues = 10000;
const bulkValueReadSize = 50;
const readValuesWithBulk = true;

@Injectable()
export class ValueSubscriptionBxSubstituteProxyService extends ValueSubscriptionProxyServiceBase {
  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _valueEvents: Subject<ValueDetails[]> = new Subject<ValueDetails[]>();
  private readonly subscriptionsPresentValue: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsPresentValuePerPointId: Map<string, number> = new Map<string, number>();
  private readonly subscriptionsSummaryState: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsCommandedWithPrio: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsCommandedWithPrioKeyPerPointId: Map<string, number> = new Map<string, number>();
  private readonly subscriptionsBacStatus: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsBacStatusKeyPerPointId: Map<string, number> = new Map<string, number>();
  private readonly subscriptionsBacPrioArray: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsBacPrioArrayKeyPerPointId: Map<string, number> = new Map<string, number>();
  private readonly subscriptionsBacCurrentPrio: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsBacCurrentPrioKeyPerPointId: Map<string, number> = new Map<string, number>();
  private readonly subscriptionsDeviceConnection: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
  private readonly subscriptionsDeviceConnectionKeyPerDvcId: Map<string, number> = new Map<string, number>();
  private readonly subscriptionsSubDeviceConnection: Map<number, Subscription> = new Map<number, Subscription>();
  private timerSubscription: Subscription;
  private valueSingleReqSubscription: Subscription[] = [];
  private valueBulkReqSubscription: Subscription[] = [];
  private valueDvcAndEqReqSubscription: Subscription[] = [];

  public constructor(private readonly traceService: TraceService,
    private readonly pointService: PointService,
    private readonly systemBrowserMapper: SystemBrowserMapperBxToGmsService,
    private readonly systemBrowser: SystemBrowserServiceBase,
    private readonly systemsService: SystemsProxyServiceBase,
    private readonly propertyMapper: PropertyMapperBxToGmsService,
    private readonly propertyService: PropertyServiceBase,
    private readonly deviceConnectionService: DeviceConnectionService,
    private readonly utilityService: UtilityService,
    private readonly zone: NgZone) {
    super();

    this.traceService.info(TraceModules.values, 'ValueSubscriptionBxSubstituteProxyService created.');

    asapScheduler.schedule(() => {
      // No real connection state is delivered.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);
  }

  /**
   * Subscribes the specified object/property ids.
   * If objectId is specified, the default property / point value is subscribed
   */
  public subscribeValues(objectOrPropertyIds: string[], details = false, booleansAsNumericText?: boolean,
    bitsInReverseOrder?: boolean): Observable<SubscriptionGmsVal[]> {

    if ((objectOrPropertyIds == null) || (objectOrPropertyIds.length === 0)) {
      this.traceService.error(TraceModules.values, 'ValueSubscriptionBxSubstituteProxyService.subscribeValues() called with invalid arguments!');
      return throwError(() => new Error('ValueSubscriptionBxSubstituteProxyService.subscribeValues() called with invalid arguments'));
    }
    this.traceSubscribeValues(objectOrPropertyIds);

    const requestId = this.utilityService.getSignalRCtx();
    const wsiSubsObs: Observable<{ subWsiVal: SubscriptionWsiVal; dpIdProperty: DpIdentifier }>[] = [];
    objectOrPropertyIds.forEach(objOrPropId => {
      const dpId = new DpIdentifier(objOrPropId);
      wsiSubsObs.push(this.createWsiSubscriptionWithPropertyCheck(dpId, requestId));
    });
    return zip(wsiSubsObs).pipe(
      map(wsiSubs => {
        let dataFetchRequired = false;
        const subKeys: SubscriptionGmsVal[] = [];
        wsiSubs.forEach(subVal => {
          const subKey: SubscriptionGmsVal = new SubscriptionGmsVal(subVal.subWsiVal);
          subKeys.push(subKey);
          if (subKey.errorCode === 0) {
            if (subVal.subWsiVal.PropertyName === aggregatedSummaryPropertyName) {
              // TODO: decide if client shall not subscribe for summary state!
              // Depends if the alarm will be shown as summary state
              this.subscriptionsSummaryState.set(subKey.key, subKey);
            } else if (subVal.subWsiVal.PropertyName === pointPropertyName) {
              this.subscriptionsPresentValue.set(subKey.key, subKey);
              this.subscriptionsPresentValuePerPointId.set(subVal.dpIdProperty.objectIdWoSystem, subKey.key);
              dataFetchRequired = true;
            } else if (subVal.subWsiVal.PropertyName === bacStatusPropertyName) {
              this.subscriptionsBacStatus.set(subKey.key, subKey);
              this.subscriptionsBacStatusKeyPerPointId.set(subVal.dpIdProperty.objectIdWoSystem, subKey.key);
              dataFetchRequired = true;
            } else if (subVal.subWsiVal.PropertyName === bacPrioArrayPropertyName) {
              this.subscriptionsBacPrioArray.set(subKey.key, subKey);
              this.subscriptionsBacPrioArrayKeyPerPointId.set(subVal.dpIdProperty.objectIdWoSystem, subKey.key);
              dataFetchRequired = true;
            } else if (subVal.subWsiVal.PropertyName === bacCurrentPriorityName) {
              this.subscriptionsBacCurrentPrio.set(subKey.key, subKey);
              this.subscriptionsBacCurrentPrioKeyPerPointId.set(subVal.dpIdProperty.objectIdWoSystem, subKey.key);
              dataFetchRequired = true;
            } else if (subVal.subWsiVal.PropertyName === commandedWithPrioPropertyName) {
              this.subscriptionsCommandedWithPrio.set(subKey.key, subKey);
              this.subscriptionsCommandedWithPrioKeyPerPointId.set(subVal.dpIdProperty.objectIdWoSystem, subKey.key);
              dataFetchRequired = true;
            } else if (subVal.subWsiVal.PropertyName === deviceConnectionPropertyName) {
              this.subscriptionsDeviceConnection.set(subKey.key, subKey);
              this.subscriptionsDeviceConnectionKeyPerDvcId.set(subVal.dpIdProperty.objectIdWoSystem, subKey.key);
              dataFetchRequired = true;
            } else {
              this.traceService.error(TraceModules.values,
                `ValueSubscriptionBxSubstituteProxyService.subscribeValues() called; property is unknown: ${subVal.subWsiVal.PropertyName}`);
            }
          }
        });

        this.traceSubscribedValuesCount();
        if (dataFetchRequired) {
          this.startTimerForSubscription(200, true);
        }
        return subKeys;
      }),
      observeOn(asyncScheduler),
      tap(keys => asyncScheduler.schedule(() => this.hideOptionalProperties(keys.map(key => key.originalId)), 10))
    );
  }

  /**
   * Event for the value notifications.
   */
  public valueChangeNotification(): Observable<ValueDetails[]> {
    return this._valueEvents.asObservable();
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  /**
   * Unsubscribes objectOrPropertyIds (associated with the subscription keys).
   */
  public unsubscribeValues(keys: number[]): Observable<SubscriptionDeleteWsi[]> {
    if ((keys == null) || (keys.length === 0)) {
      this.traceService.error(TraceModules.values, 'ValueSubscriptionBxSubstituteProxyService.unSubscribeValues() called with invalid arguments!');
      return throwError(() => new Error('ValueSubscriptionBxSubstituteProxyService.unsubscribeValues() called with invalid arguments'));
    }
    const index: number = keys.findIndex(item => (item === undefined));
    if (index !== -1) {
      this.traceService.error(TraceModules.values, 'Invalid keys!');
      return throwError(() => new Error('ValueSubscriptionBxSubstituteProxyService.unsubscribeValues() called with invalid arguments'));
    }
    this.traceUnsubscribeValues(keys);

    const subDeleteKeys: SubscriptionDeleteWsi[] = [];
    keys.forEach(key => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const subDeleteKey: SubscriptionDeleteWsi = {
        Key: key,
        ErrorCode: 0
      };
      /* eslint-enable @typescript-eslint/naming-convention */
      if (this.subscriptionsPresentValue.has(subDeleteKey.Key)) {
        const sub = this.subscriptionsPresentValue.get(subDeleteKey.Key);
        if (sub !== undefined) {
          const dpId = new DpIdentifier(sub.originalId);
          this.subscriptionsPresentValuePerPointId.delete(dpId.objectIdWoSystem);
        }
        this.subscriptionsPresentValue.delete(subDeleteKey.Key);
      } else if (this.subscriptionsSummaryState.has(subDeleteKey.Key)) {
        this.subscriptionsSummaryState.delete(subDeleteKey.Key);
      } else if (this.subscriptionsCommandedWithPrio.has(subDeleteKey.Key)) {
        const sub = this.subscriptionsCommandedWithPrio.get(subDeleteKey.Key);
        if (sub !== undefined) {
          const dpId = new DpIdentifier(sub.originalId);
          this.subscriptionsCommandedWithPrioKeyPerPointId.delete(dpId.objectIdWoSystem);
        }
        this.subscriptionsCommandedWithPrio.delete(subDeleteKey.Key);
      } else if (this.subscriptionsBacStatus.has(subDeleteKey.Key)) {
        const sub = this.subscriptionsBacStatus.get(subDeleteKey.Key);
        if (sub !== undefined) {
          const dpId = new DpIdentifier(sub.originalId);
          this.subscriptionsBacStatusKeyPerPointId.delete(dpId.objectIdWoSystem);
        }
        this.subscriptionsBacStatus.delete(subDeleteKey.Key);
      } else if (this.subscriptionsBacPrioArray.has(subDeleteKey.Key)) {
        const sub = this.subscriptionsBacPrioArray.get(subDeleteKey.Key);
        if (sub !== undefined) {
          const dpId = new DpIdentifier(sub.originalId);
          this.subscriptionsBacPrioArrayKeyPerPointId.delete(dpId.objectIdWoSystem);
        }
        this.subscriptionsBacPrioArray.delete(subDeleteKey.Key);
      } else if (this.subscriptionsBacCurrentPrio.has(subDeleteKey.Key)) {
        const sub = this.subscriptionsBacCurrentPrio.get(subDeleteKey.Key);
        if (sub !== undefined) {
          const dpId = new DpIdentifier(sub.originalId);
          this.subscriptionsBacCurrentPrioKeyPerPointId.delete(dpId.objectIdWoSystem);
        }
        this.subscriptionsBacCurrentPrio.delete(subDeleteKey.Key);
      } else if (this.subscriptionsDeviceConnection.has(subDeleteKey.Key)) {
        const sub = this.subscriptionsDeviceConnection.get(subDeleteKey.Key);
        if (sub !== undefined) {
          const dpId = new DpIdentifier(sub.originalId);
          this.subscriptionsDeviceConnectionKeyPerDvcId.delete(dpId.objectIdWoSystem);
        }
        this.subscriptionsDeviceConnection.delete(subDeleteKey.Key);
        if (this.subscriptionsSubDeviceConnection.has(subDeleteKey.Key)) {
          this.subscriptionsSubDeviceConnection.get(subDeleteKey.Key)?.unsubscribe();
          this.subscriptionsSubDeviceConnection.delete(subDeleteKey.Key);
        }
      } else {
        this.traceService.info(TraceModules.values, `ValueSubscriptionBxSubstituteProxyService.unSubscribeValues() called; key is unknown: ${key}`);
      }
      subDeleteKeys.push(subDeleteKey);
    });

    this.traceSubscribedValuesCount();

    return of(subDeleteKeys).pipe(observeOn(asyncScheduler));
  }

  public pollAndNotifySubscribedValues(delay: number): void {
    this.startTimerForSubscription(delay, true);
  }

  private createWsiSubscriptionWithPropertyCheck(
    dpId: DpIdentifier, requestId: string): Observable<{ subWsiVal: SubscriptionWsiVal; dpIdProperty: DpIdentifier }> {

    return (this.propertyService as PropertyBxSubstituteService).checkIfPropertyExists(dpId).pipe(
      map(result => {
        if (result) {
          return { subWsiVal: this.createWsiSubscriptionVal(dpId, requestId), dpIdProperty: dpId };
        } else {
          return { subWsiVal: this.createWsiSubscriptionVal(dpId, requestId, 1), dpIdProperty: dpId };
        }
      })
    );
  }

  private createWsiSubscriptionVal(dpId: DpIdentifier, requestId: string, errorCode: number = 0): SubscriptionWsiVal {
    let propertyName = pointPropertyName; // in case the objOrPropId is specifying only the object id: set to point property name (default property)
    if (dpId.isProperty) {
      propertyName = dpId.propertName;
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const subKeyWsi: SubscriptionWsiVal = {
      Key: this.utilityService.getSubscriptionKey(),
      PropertyId: `${dpId.objectId}${DpIdentifier.propertyNameSeparator}${propertyName}`,
      ObjectId: dpId.objectId,
      OriginalObjectOrPropertyId: dpId.objectOrPropertyId,
      PropertyName: propertyName,
      ErrorCode: errorCode,
      RequestId: requestId,
      RequestFor: 'notifyValues',
      AttributeId: `${dpId.objectId}${DpIdentifier.propertyNameSeparator}${propertyName}`
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return subKeyWsi;
  }

  private hideOptionalProperties(objectIds: string[]): void {
    const pointMap = new Map<string, string>();
    objectIds.forEach(objId => {
      const pointId = new DpIdentifier(objId).objectIdWoSystem;
      pointMap.set(pointId, pointId);
    });
    pointMap.forEach(ptId => {
      this.notifyStatusFlagsValue(ptId, undefined, true);
      this.notifyPrioArrayValue(ptId, undefined, undefined, true);
      this.notifyCurrentPrioValue(ptId, undefined, true);
    });
  }

  private notifyStatusFlagsValue(pointId: string, pointValue: PointValue, hide: boolean = false): void {
    if (this.subscriptionsBacStatusKeyPerPointId.has(pointId)) {
      const sub = this.subscriptionsBacStatus.get(this.subscriptionsBacStatusKeyPerPointId.get(pointId));
      const valueDetailsStatusFlags: ValueDetails = this.propertyMapper.createBacStatusValue(sub, pointValue, hide);
      if (valueDetailsStatusFlags) {
        this._valueEvents.next([valueDetailsStatusFlags]);
      }
    }
  }

  private notifyPrioArrayValue(pointId: string, pointValue: PointValue, pointAttributes: PointAttributes, hide: boolean = false): void {
    if (this.subscriptionsBacPrioArrayKeyPerPointId.has(pointId)) {
      const sub = this.subscriptionsBacPrioArray.get(this.subscriptionsBacPrioArrayKeyPerPointId.get(pointId));
      const valueDetailsPrioArray: ValueDetails = this.propertyMapper.createBacPrioArrayValue(sub, pointId, pointValue, pointAttributes, hide);
      if (valueDetailsPrioArray) {
        this._valueEvents.next([valueDetailsPrioArray]);
      }
    }
  }

  private notifyCurrentPrioValue(pointId: string, pointValue: PointValue, hide: boolean = false): void {
    if (this.subscriptionsBacCurrentPrioKeyPerPointId.has(pointId)) {
      const sub = this.subscriptionsBacCurrentPrio.get(this.subscriptionsBacCurrentPrioKeyPerPointId.get(pointId));
      const valueDetailsCurrentPrio: ValueDetails = this.propertyMapper.createBacCurrentPrioValue(sub, pointValue, hide);
      if (valueDetailsCurrentPrio) {
        this._valueEvents.next([valueDetailsCurrentPrio]);
      }
    }
  }

  private notifyCommandedWithPrioValue(pointId: string, pointValue: PointValue): void {
    if (this.subscriptionsCommandedWithPrioKeyPerPointId.has(pointId)) {
      const sub = this.subscriptionsCommandedWithPrio.get(this.subscriptionsCommandedWithPrioKeyPerPointId.get(pointId));
      const valueDetailsPrio: ValueDetails = this.propertyMapper.createCommandedWithPriorityValue(sub, pointValue);
      this._valueEvents.next([valueDetailsPrio]);
    }
  }

  private notifyPresentValue(pointId: string, pointValue: PointValue, pointAttributes: PointAttributes): void {
    if (this.subscriptionsPresentValuePerPointId.has(pointId)) {
      const sub = this.subscriptionsPresentValue.get(this.subscriptionsPresentValuePerPointId.get(pointId));
      const valueDetails: ValueDetails = this.propertyMapper.createPointValue(sub, pointId, pointValue, pointAttributes);
      this._valueEvents.next([valueDetails]);
    }
  }

  private notifyDeviceConnectionValue(deviceId: string, connectedVal?: boolean): void {
    if (this.subscriptionsDeviceConnectionKeyPerDvcId.has(deviceId)) {
      const sub = this.subscriptionsDeviceConnection.get(this.subscriptionsDeviceConnectionKeyPerDvcId.get(deviceId));
      const valueDetails: ValueDetails = this.propertyMapper.createDeviceConnectionValue(sub, connectedVal);
      this._valueEvents.next([valueDetails]);
    }
  }

  private onTimerSubscription(): void {
    this.traceSubscribedValuesCount();

    // TODO: the decision if bild read is still needed when having virtualization

    const countSubscriptions = this.subscriptionsPresentValue.size;
    if (countSubscriptions > 0) {
      if (countSubscriptions <= 10000) {
        if (readValuesWithBulk) {
          this.readDataWithBulkRequests(this.subscriptionsPresentValue);
        } else {
          // do single request, time is typically lower than 500ms for 1000 parallel single calls. However, memory issues can arise
          this.readDataWithSingleRequests(this.subscriptionsPresentValue);
        }
      } else {
        const requests = this.evaluateGroupIds();
        // do bulk reading with pointgroups
        const startTime = performance.now();
        const sub = zip(requests.requestsDvcAndEq).subscribe(_result => {
          this.valueDvcAndEqReqSubscription = [];
          this.traceService.info(TraceModules.values,
            `Time to read and update values with all point groups: ${performance.now() - startTime} ms;
            number of updated values: ${countSubscriptions}; number of read values: ${countSubscriptions}`);
        });
        this.valueDvcAndEqReqSubscription.push(sub);
      }
    }
    if (this.subscriptionsDeviceConnection.size > 0) {
      this.readDeviceConnectionState(this.subscriptionsDeviceConnection);
    }

    this.startTimerForSubscription(pollRateValues, false);
  }

  private readDeviceConnectionState(subsDvcConn: Map<number, SubscriptionGmsVal>): void {
    this.zone.runOutsideAngular(() => {
      subsDvcConn.forEach((subPrVal, key) => {
        const dpId = new DpIdentifier(subPrVal.originalId);
        const partitionId = dpId.systemName;
        if (!this.subscriptionsSubDeviceConnection.has(key)) {
          this.subscriptionsSubDeviceConnection.set(
            key, this.deviceConnectionService.subscribeConnectionState(partitionId, dpId.objectIdWoSystem).subscribe(connected => {
              this.notifyDeviceConnectionValue(dpId.objectIdWoSystem, connected);
            }));
        }
      });
    });
  }

  private readDataWithSingleRequests(subsPrVal: Map<number, SubscriptionGmsVal>): void {
    const countSubscriptions = subsPrVal.size;
    this.zone.runOutsideAngular(() => {
      const singleRequests: Observable<boolean>[] = [];
      subsPrVal.forEach((subPrVal, key) => {
        const dpId = new DpIdentifier(subPrVal.originalId);
        const partitionId = dpId.systemName;
        const singleReqObs = this.pointService.getPointAndValueById(partitionId, dpId.objectIdWoSystem, true).pipe(
          map(result => {
            this.notifyPresentValue(result.id, result.attributes.lastValue, result.attributes);
            this.notifyCommandedWithPrioValue(result.id, result.attributes.lastValue);
            this.notifyStatusFlagsValue(result.id, result.attributes.lastValue);
            this.notifyPrioArrayValue(result.id, result.attributes.lastValue, result.attributes);
            this.notifyCurrentPrioValue(result.id, result.attributes.lastValue);
            return true;
          })
        );
        singleRequests.push(singleReqObs);
      });

      const startTime = performance.now();
      const sub = zip(singleRequests).subscribe(_result => {
        this.valueSingleReqSubscription = [];
        this.traceService.info(TraceModules.values,
          `Time to read and update values with single requests: ${performance.now() - startTime} ms;
          number of updated values: ${countSubscriptions}; number of read values: ${countSubscriptions}`);
        this.traceObjectNamesOfReadValues(subsPrVal);
      });
      this.valueSingleReqSubscription.push(sub);
    });
  }

  private readDataWithBulkRequests(subsPrVal: Map<number, SubscriptionGmsVal>): void {
    const countSubscriptions = subsPrVal.size;
    this.zone.runOutsideAngular(() => {
      const bulkRequests: Observable<boolean>[] = [];
      const pointIdsBulked: SubscriptionGmsVal[][] = [];
      let bulkIdx = 0;
      let itemsCount = 0;
      pointIdsBulked[bulkIdx] = [];
      subsPrVal.forEach((subPrVal, key) => {
        if (itemsCount === bulkValueReadSize) {
          bulkIdx++;
          itemsCount = 0;
          pointIdsBulked[bulkIdx] = [];
        }
        pointIdsBulked[bulkIdx].push(subPrVal);
        itemsCount++;
      });

      for (const pointIds of pointIdsBulked) {
        const dpIds = pointIds.map(value => new DpIdentifier(value.originalId));
        const partitionId = dpIds[0].systemName;
        const bulkReqObs = this.pointService.getPointValuesBulk(partitionId, dpIds.map(id => id.objectIdWoSystem)).pipe(
          map(result => {
            this.notifyValues(partitionId, result);
            return true;
          })
        );
        bulkRequests.push(bulkReqObs);
      }

      const startTime = performance.now();
      const sub = zip(bulkRequests).subscribe(_result => {
        this.valueBulkReqSubscription = [];
        this.traceService.info(TraceModules.values,
          `Time to read and update values with bulk requests: ${performance.now() - startTime} ms;
          number of updated values: ${countSubscriptions}; number of read values: ${countSubscriptions}`);
        this.traceObjectNamesOfReadValues(subsPrVal);
      });
      this.valueBulkReqSubscription.push(sub);
    });
  }

  private notifyValues(partitionId: string, values: PointBxValue[]): void {
    values.forEach(ptVal => {
      this.pointService.getPointById(partitionId, ptVal.id).subscribe(ptBx => {
        this.notifyPresentValue(ptVal.id, ptVal.attributes.lastValue, ptBx.attributes);
        this.notifyCommandedWithPrioValue(ptVal.id, ptVal.attributes.lastValue);
        this.notifyStatusFlagsValue(ptVal.id, ptVal.attributes.lastValue);
        this.notifyPrioArrayValue(ptVal.id, ptVal.attributes.lastValue, ptBx.attributes);
        this.notifyCurrentPrioValue(ptVal.id, ptVal.attributes.lastValue);
      });
    });
  }

  private evaluateGroupIds(): {
    requestsDvcAndEq: Observable<boolean>[];
    requestMapSinglePoints: Map<number, SubscriptionGmsVal>;
  } {
    const requestMapDevice: Map<string, Map<string, SubscriptionGmsVal>> = new Map<string, Map<string, SubscriptionGmsVal>>();
    const requestMapEquipment: Map<string, Map<string, SubscriptionGmsVal>> = new Map<string, Map<string, SubscriptionGmsVal>>();
    const requestMapSinglePoints: Map<number, SubscriptionGmsVal> = new Map<number, SubscriptionGmsVal>();
    this.subscriptionsPresentValue.forEach((value, key) => {
      const dpId = new DpIdentifier(value.originalId);
      // a point belongs to a device or equipment; which is a point group as well
      const pointId = dpId.objectIdWoSystem;
      const parentId = this.systemBrowserMapper.getParentEntityId(pointId);
      if (parentId !== undefined) {
        const parentEntityType = this.systemBrowserMapper.getEntityType(parentId);
        if (parentEntityType === EntityType.Device) {
          if (requestMapDevice.has(parentId) === false) {
            requestMapDevice.set(parentId, new Map<string, SubscriptionGmsVal>());
          }
          requestMapDevice.get(parentId).set(pointId, value);
        } else if (parentEntityType === EntityType.Equipment) {
          if (requestMapEquipment.has(parentId) === false) {
            requestMapEquipment.set(parentId, new Map<string, SubscriptionGmsVal>());
          }
          requestMapEquipment.get(parentId).set(pointId, value);
        } else {
          this.traceService.error(TraceModules.values, `Unhandled entity type: ${parentEntityType}`);
        }
      } else {
        requestMapSinglePoints.set(key, value);
      }
    });
    const requestsDvcAndEq: Observable<boolean>[] = [];
    requestMapDevice.forEach((value, key) => {
      const partitionId = this.systemBrowserMapper.getPartitionId(key);
      requestsDvcAndEq.push(this.readDataWithGroupId(partitionId, key, EntityType.Device, value));
    });
    requestMapEquipment.forEach((value, key) => {
      const partitionId = this.systemBrowserMapper.getPartitionId(key);
      requestsDvcAndEq.push(this.readDataWithGroupId(partitionId, key, EntityType.Equipment, value));
    });
    return { requestsDvcAndEq, requestMapSinglePoints };
  }

  private readDataWithGroupId(partitionId: string, pgId: string, pgType: PointGroupType, subPerPoint: Map<string, SubscriptionGmsVal>): Observable<boolean> {
    return this.pointService.getPointValues(partitionId, pgId, pgType).pipe(
      concatMap(result => {
        const pointBx$: Observable<PointBx>[] = [];
        for (const pointValue of result) {
          const subGms = subPerPoint.get(pointValue.id);
          if (subGms !== undefined) {
            pointBx$.push(this.pointService.getPointById(partitionId, pointValue.id).pipe(
              map(pointBx => {
                const valueDetails: ValueDetails = this.propertyMapper.createPointValue(
                  subGms, pointValue.id, pointValue.attributes.lastValue, pointBx.attributes);
                this._valueEvents.next([valueDetails]);
                return pointBx;
              })
            ));
          }
        }
        return zip(pointBx$).pipe(
          map(_result => true)
        );
      })
    );
  }

  private startTimerForSubscription(delay: number, cancelCalls: boolean): void {
    this.stopTimerForSubscription(cancelCalls);
    this.timerSubscription = timer(delay).subscribe(count => this.onTimerSubscription());
  }

  private stopTimerForSubscription(cancelCalls: boolean): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    if (cancelCalls) {
      this.unsubscribeValueSubscriptions();
    }
  }

  private unsubscribeValueSubscriptions(): void {
    this.valueSingleReqSubscription.forEach(sub => sub?.unsubscribe());
    this.valueSingleReqSubscription = [];
    this.valueBulkReqSubscription.forEach(sub => sub?.unsubscribe());
    this.valueBulkReqSubscription = [];
    this.valueDvcAndEqReqSubscription.forEach(sub => sub?.unsubscribe());
    this.valueDvcAndEqReqSubscription = [];
  }

  private traceSubscribedValuesCount(): void {
    this.traceService.info(TraceModules.values, `ValueSubscriptionOmProxyService.traceSubscribedValuesCount():
    Number of subscriptions (present value): ${this.subscriptionsPresentValue.size}
    Number of summary state subscriptions: ${this.subscriptionsSummaryState.size}`);
  }

  private traceSubscribeValues(objectOrPropertyIds: string[]): void {
    this.traceService.info(TraceModules.values,
      'ValueSubscriptionBxSubstituteProxyService.subscribeValues() called; number of objectOrPropertyIds:%s', objectOrPropertyIds.length);
    if (this.traceService.isDebugEnabled(TraceModules.values)) {
      this.traceService.debug(TraceModules.values,
        'ValueSubscriptionBxSubstituteProxyService.subscribeValues(): objectOrPropertyIds to subscribe:\n%s', objectOrPropertyIds.join('\n'));
      this.traceObjectNamesOfProperties(objectOrPropertyIds);
    }
  }

  private traceUnsubscribeValues(keys: number[]): void {
    this.traceService.info(TraceModules.values, 'ValueSubscriptionBxSubstituteProxyService.unSubscribeValues() called; number of keys: %s', keys.length);
    if (this.traceService.isDebugEnabled(TraceModules.values)) {
      this.traceService.debug(TraceModules.values, 'ValueSubscriptionBxSubstituteProxyService.unSubscribeValues():\nKeys: %s', keys.toString());
    }
  }

  private traceObjectNamesOfReadValues(subs: Map<number, SubscriptionGmsVal>): void {
    const objectsIds = [];
    subs.forEach((value, key) => {
      objectsIds.push(value.originalId);

    });
    this.traceObjectNamesOfProperties(objectsIds);
  }

  private traceObjectNamesOfProperties(objectOrPropertyIds: string[]): void {
    if (this.traceService.isDebugEnabled(TraceModules.values)) {
      this.systemsService.getSystems().subscribe(systems => {
        const objectsObs = [];
        objectOrPropertyIds.forEach(objId => {
          const dpId = new DpIdentifier(objId);
          const systemInfo = systems.find(system => system.Name === dpId.systemName);
          objectsObs.push((this.systemBrowser as SystemBrowserBxSubstituteService).searchNodes(
            systemInfo.Id, dpId.objectOrPropertyId, undefined, SearchOption.objectId));
        });
        zip(objectsObs).subscribe((pages: Page[]) => {
          let trace = '';
          pages.forEach(page => trace = trace.concat(page.Nodes[0].Designation, '\n'));
          this.traceService.debug(TraceModules.values, 'ValueSubscriptionBxSubstituteProxyService: object designations to subscribe:\n%s', trace);
        });
      });
    }
  }
}
