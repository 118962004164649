import { Injectable } from '@angular/core';
import { BrowserObject, Designation, DpIdentifier, SearchOption, SystemBrowserServiceBase } from '@gms-flex/services';
import { concatMap, map, Observable, of } from 'rxjs';

import { Device } from '../../bx-services/device/device.model';
import { DeviceService } from '../../bx-services/device/device.service';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';
import { SystemBrowserBxSubstituteService } from './system-browser-bx-substitute.service';

@Injectable({
  providedIn: 'root'
})
export class SystemBrowserUtilityService {
  public constructor(
    private readonly systemBrowser: SystemBrowserServiceBase,
    private readonly deviceService: DeviceService,
    private readonly objectTypeMapper: ObjectTypeMapperService
  ) {}

  /**
   * Returns the gateway browser node of the parameter (must be a point node of the devices hierarchy) 
   */
  public getGatewayNode(pointNode: BrowserObject): Observable<BrowserObject | undefined> {
    if (!this.isPoint(pointNode)) {
      return of(undefined);
    }

    const designation = new Designation(pointNode.Designation);
    return this.systemBrowser.searchNodes(pointNode.SystemId, designation.parentNodeDesignation, pointNode.ViewId, SearchOption.designation).pipe(
      concatMap(pageParent => {
        if ((pageParent.Nodes?.length > 0) && (this.isGateway(pageParent.Nodes[0]))) {
          return of(pageParent.Nodes[0]);
        } else {
          const designationParent = new Designation(designation.parentNodeDesignation);
          return this.systemBrowser.searchNodes(pointNode.SystemId, designationParent.parentNodeDesignation, pointNode.ViewId, SearchOption.designation).pipe(
            map(pageParPar => {
              if ((pageParPar.Nodes?.length > 0) && (this.isGateway(pageParPar.Nodes[0]))) {
                return pageParPar.Nodes[0];
              } else {
                return undefined;
              }
            })
          );
        }
      })
    );
  }

  /**
   * Checks if the browserNode supports event configuration
   */
  public isEventConfigurationSupported(browserNode: BrowserObject): Observable<boolean> {
    if (!this.isPoint(browserNode)) {
      return of(false);
    }

    return this.getGateway(browserNode).pipe(
      map(gateway => {
        if (gateway) {
          return this.isEventConfigurationSupportedSync(gateway);
        } else {
          return false;
        }
      })
    );
  }

  /**
   * Checks if the browserNode is a point
   */
  public isPoint(browserNode: BrowserObject): boolean {
    return this.objectTypeMapper.checkIfPoint(browserNode.Attributes.TypeId);
  }
  
  /**
   * Checks if the browserNode is a gateway
   */
  public isGateway(browserNode: BrowserObject): boolean {
    return this.objectTypeMapper.checkIfGateway(browserNode.Attributes.TypeId, browserNode.Attributes.SubTypeId);
  }

  /**
   * Returns the gateway device of the specified point browser node.  
   * Returns undefined in case no gateway was found (e.g. for virtual points)
   */
  public getGateway(pointNode: BrowserObject): Observable<Device | undefined> {
    const dpId = new DpIdentifier(pointNode.ObjectId);
    return this.getGatewayById(dpId.systemName, dpId.objectIdWoSystem);
  }

  /**
   * Returns the gateway device of the specified point.  
   * Returns undefined in case no gateway was found (e.g. for virtual points)
   */
  public getGatewayById(partitionId: string, pointId: string): Observable<Device | undefined> {
    // we need to use the browser node of the devices view to find the gateway of the point
    return (this.systemBrowser as SystemBrowserBxSubstituteService).resolvePoint(pointId, partitionId).pipe(
      concatMap(page => {
        if (page?.Nodes.length > 0) {
          return this.getGatewayInt(page.Nodes[0]);
        } else {
          return of(undefined);
        }
      })
    );
  }

  private isEventConfigurationSupportedSync(gateway: Device): boolean {
    if (gateway) {
      return (gateway.model.includes('X200') || gateway.model.includes('X300') || gateway.model.includes('F200') ||
        gateway.model.includes('ConnectSoftware') || gateway.model.includes('server'))
    } else {
      return false;
    }
  }

  /**
   * Returns the gateway of the parameter (must be a point node of the devices hierarchy) 
   */
  private getGatewayInt(pointNode: BrowserObject): Observable<Device | undefined> {
    return this.getGatewayNode(pointNode).pipe(
      concatMap(gatewayNode => {
        if (gatewayNode) {
          const dpId = new DpIdentifier(gatewayNode.ObjectId);
          return this.deviceService.getDeviceById(dpId.systemName, dpId.objectIdWoSystem);
        } else {
          return of(undefined);
        }
      })
    );
  }
}
