import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PersistenceService {

  public get customerId(): string | undefined {
    return localStorage.getItem('selectedCustomerId') ?? undefined;
  }

  public set customerId(customerId: string) {
    localStorage.setItem('selectedCustomerId', customerId);
  }

  public get partitionIds(): string[] {
    const partitionIds = localStorage.getItem('selectedPartitions');
    const partitionIdsArray = partitionIds?.split(',');
    if (partitionIdsArray && partitionIdsArray.length > 0) {
      return partitionIdsArray;
    }
    return [];
  }

  public set partitionIds(partitionIds: string[]) {
    localStorage.setItem('selectedPartitions', partitionIds.join(','));
  }

  public get objectId(): string | undefined {
    return localStorage.getItem('selectedObjectId') ?? undefined;
  }

  public set objectId(objectId: string) {
    localStorage.setItem('selectedObjectId', objectId);
  }

  public get singleSiteActive(): boolean {
    return localStorage.getItem('singleSiteActive')?.toLowerCase() === "true";
  }

  public set singleSiteActive(singleSiteActive: boolean) {
    localStorage.setItem('singleSiteActive', singleSiteActive.toString());
  }

  public get singleSiteQParam(): string {
    return localStorage.getItem('singleSiteQParam') ?? undefined;
  }

  public set singleSiteQParam(qParam: string) {
    localStorage.setItem('singleSiteQParam', qParam);
  }

}
