import { DeviceSubtype, EntityType } from '../shared/base.model';
import { ConnectivityFeatureAttributes, DeviceBx, DeviceFeature, DeviceInfoFeatureAttributes, TunnelFeatureAttributes } from './device-proxy.model';

export class Device {

  private _name: string;
  private _description: string;
  private _tunnel: TunnelFeatureAttributes;
  private _connectivity: ConnectivityFeatureAttributes;

  constructor(private readonly device: DeviceBx, deviceFeatures: DeviceFeature[]) {
    this.initName(deviceFeatures);
    this.initDescription(deviceFeatures);
    this.initTunnel(deviceFeatures);
  }

  public get id(): string {
    return this.device.id;
  }

  public get type(): EntityType {
    return this.device.type;
  }

  public get subType(): string | undefined {
    return this.isGateway ? DeviceSubtype.Gateway : undefined;
  }

  public get name(): string {
    return this._name;
  }

  public get description(): string {
    return this._description;
  }

  public get model(): string {
    return this.device.attributes.modelName;
  }

  public get tunnel(): TunnelFeatureAttributes | undefined {
    return this._tunnel;
  }

  public get connectivity(): ConnectivityFeatureAttributes | undefined {
    return this._connectivity;
  }

  public get isGateway(): boolean {
    return !this.hasGateway;
  }

  public get hasGateway(): boolean {
    return (this.device.relationships.hasGateway !== undefined);
  }

  public get gatewayId(): string | undefined {
    return this.device.relationships.hasGateway ? this.device.relationships.hasGateway.data.id : undefined;
  }

  public get hasLocation(): boolean {
    return (this.device.relationships.hasLocation !== undefined);
  }

  public get locationId(): string | undefined {
    return this.device.relationships.hasLocation ? this.device.relationships.hasLocation.data.id : undefined;
  }

  public updateConnectivity(deviceFeatures: DeviceFeature[]): void {
    this.initConnectivity(deviceFeatures);
  }

  private initName(deviceFeatures: DeviceFeature[]): void {
    // prio 1: profile name
    if (this.device.attributes.profile.name) {
      this._name = this.replaceSeparators(this.device.attributes.profile.name);
      return;
    }
    // prio 2: device info name
    const hasDvcInfo = this.device.relationships.hasFeatures.data.find(item => item.type === 'DeviceInfo');
    if (hasDvcInfo) {
      const dvcInfo = deviceFeatures?.find(item => item.id === hasDvcInfo.id);
      const dvcName = (dvcInfo?.attributes as DeviceInfoFeatureAttributes)?.name;
      if (dvcName) {
        this._name = this.replaceSeparators(dvcName);
        return;
      }
    }

    // prio 3: serial number
    if (this.device.attributes.serialNumber) {
      this._name = this.replaceSeparators(this.device.attributes.serialNumber);
      return;
    }
    // prio 4: model
    if (this.device.attributes.modelName) {
      this._name = this.replaceSeparators(this.device.attributes.modelName);
      return;
    }
    this._name = this.replaceSeparators(this.device.id);
  }

  private initDescription(_deviceFeatures: DeviceFeature[]): void {
    // prio 1: Type - Modelname
    if (this.device.attributes.modelName) {
      this._description = `Device (${this.replaceSeparators(this.device.attributes.modelName)})`;
      return;
    }
    this._description = this.device.id;
  }

  private replaceSeparators(name: string): string {
    // TODO: implement properly with escaping!!
    return name.replaceAll('.', '\'');
  }

  private initTunnel(deviceFeatures: DeviceFeature[]): void {
    const hasTunnel = this.device.relationships.hasFeatures.data.find(item => item.type === 'Tunnel');
    if (hasTunnel) {
      const tunnelInfo = deviceFeatures?.find(item => item.id === hasTunnel.id);
      if (tunnelInfo) {
        this._tunnel = tunnelInfo.attributes as TunnelFeatureAttributes;
        return;
      }
    }
  }

  private initConnectivity(deviceFeatures: DeviceFeature[]): void {
    const hasConnectivity = this.device.relationships.hasFeatures.data.find(item => item.type === 'Connectivity');
    if (hasConnectivity) {
      const cInfo = deviceFeatures?.find(item => item.id === hasConnectivity.id);
      if (cInfo) {
        this._connectivity = cInfo.attributes as ConnectivityFeatureAttributes;
        return;
      }
    }
  }

}
