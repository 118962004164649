import { PortalModule } from '@angular/cdk/portal';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, LOCALE_ID, NgModule, NgZone, Optional } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ServiceWorkerAuth, SiAODSModule, SiAppDataService, 
  SiAppListService, SiHttpErrorInterceptor, 
  SiLoginAuthModule, SiMeasurementUnitsModule,
  SiPermissionService, 
  SiqudtUnitConversionPipe, SiSharedModule, 
  SiSubscriptionsModule, SiUOModule
} from '@building-x/common-ui-ng';
import { HfwControlsModule, HfwLoginModule, NgxBootstrapModule } from '@gms-flex/controls';
import { HfwCoreModule } from '@gms-flex/core';
import { GraphicsCommonTemplateService } from '@gms-flex/graphics-viewer-root-services';
import { GmsNavbarModule } from '@gms-flex/navigation-bar';
import { GmsServicesModule, GraphicsCommonTemplateServiceBase } from '@gms-flex/services';
import { HFW_TRANSLATION_FILE_TOKEN, HfwServicesCommonModule, MultiTranslateHttpLoader, TraceService } from '@gms-flex/services-common';
import { AboutPopoverModule, EventsModule, GmsSnapInCommonModule } from '@gms-flex/snapin-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { SimplPropertyViewerNgModule } from '@simpl/buildings-ng';
import {
  SiAccordionModule, SiContentActionBarModule, SiDropdownModule, SiEmptyStateComponent, SiEmptyStateModule, SiFilteredSearchModule,
  SiIconModule, SiLoadingSpinnerModule, SiNavbarVerticalModule, SiNumberInputModule, SiPopoverModule,
  SiPromptDialogButtonsModule, SiSearchBarModule, SiSelectModule, SiSidePanelModule, SiSliderModule,
  SiToastNotificationService,
  SiWizardModule
} from '@simpl/element-ng';
import { SiTimelineWidgetComponent } from '@simpl/element-ng/dashboard';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { environment } from 'src/environments/environment';

import { routing } from './app-routing.module';
import { ApplicationComponent, trcModuleNameApp } from './app.component';
import { BxGmsMapperModule } from './bx-gms-mapper/bx-gms-mapper.module';
import { ContextService } from './bx-gms-mapper/state/context.service';
import { BaseHttpRequest, OpenAPI, OpenAPIConfig } from './bx-services/schedule';
import { AngularHttpRequest } from './bx-services/schedule/core/AngularHttpRequest';
import { LoginComponent } from './core-ui/login/login.component';
import { GmsLocaleId, GmsLocaleIdService } from './core/services/gms-locale-id.service';
import { CustomerSelectorComponent } from './customer-selection/customer-selector.component';
import { EventSettingsComponent } from './features/event-settings/event-settings.component';
import { PointAlarmAnalogComponent } from './features/event-settings/point-alarm-analog/point-alarm-analog.component';
import { PointAlarmBinaryComponent } from './features/event-settings/point-alarm-binary/point-alarm-binary.component';
import { PointAlarmCategoryComponent } from './features/event-settings/point-alarm-category/point-alarm-category.component';
import { PointAlarmMultistateComponent } from './features/event-settings/point-alarm-multistate/point-alarm-multistate.component';
import { PointAlarmTextComponent } from './features/event-settings/point-alarm-text/point-alarm-text.component';
import { LoadingComponent } from './loading/loading.component';
import { MainComponent } from './main/main.component';
import { NotifyDialogComponent } from './notification-dialog/notify-dialog.component';
import { SytemRPComponent } from './right-panel/sytem-rp/sytem-rp.component';
import { RootServicesModule } from './root-services.module';

let appTickCounter = 0;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OpenAPIconfiguration = new InjectionToken<OpenAPIConfig>('OpenAPIConfig');

const initializeSrvWorker = (serviceWorkerAuth: ServiceWorkerAuth) => async (): Promise<void> => {
  
  const loginData = {
    // 'openIdConnectProvider': pass issue for application. Generally used for token request. This is required property.
    openIdConnectProvider: environment.auth.issuer,
    
    // 'clientId': Takes client id of application ( auth0 ). This is required property.
    clientId: environment.auth.clientId,

    // `customerIdpBaseUrl`: customer idp base url for user. This is required property.
    customerIdpBaseUrl: `${environment.bxPlatform.userMgmtApiUrl}/v2`,

    // 'logoutBaseUrl': This property can be used for logging in/out user by creating login/logout url. This is required property.
    logoutBaseUrl: environment.auth.siemensIdBaseUrl,

    isHasingEnabled: true,

    tokenExpiryAlertModalTime: 300,

    // extra configuration for login
    extras: {
      // `audience`: Audience for application , used for authentication and token request. This is required property.
      // App must use `BX_AUDIENCE` in their environment.template.ts file, as this is already added in app-infra-setting. 
      // For local, apps can use 'https://int.bx.siemens.com'
      audience: environment.auth.bxAudience
    }
  };
  const swVariables = {
    // `oidcDomains`: pass this for issuer whitelisting as this will be required, as service worker will check for whitelisted issuer before authentication
    oidcDomains: [environment.auth.issuer, environment.auth.msIssuer]
  };
  await serviceWorkerAuth.loadServiceWorker(loginData, swVariables);
}
@NgModule({ declarations: [
  ApplicationComponent,
  CustomerSelectorComponent,
  EventSettingsComponent,
  LoadingComponent,
  LoginComponent,
  MainComponent,
  NotifyDialogComponent,
  PointAlarmAnalogComponent,
  PointAlarmBinaryComponent,
  PointAlarmCategoryComponent,
  PointAlarmMultistateComponent,
  PointAlarmTextComponent,
  SytemRPComponent
],
bootstrap: [ApplicationComponent],
schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AboutPopoverModule,
  BrowserAnimationsModule,
  BrowserModule,
  EventsModule,
  FormsModule,
  GmsNavbarModule,
  GmsServicesModule,
  GmsSnapInCommonModule.forRoot(),
  HfwControlsModule,
  HfwCoreModule,
  HfwLoginModule,
  HfwServicesCommonModule,
  NgxBootstrapModule,
  SiAODSModule.forRoot({ apiUrl: `${environment.bxPlatform.aodsApiUrl}/v2` }),
  // SiUserModule,
  // SiUOModule.forRoot({
  //  apiUrl: `${environment.bxPlatform.userOrganizationsApiV1Url}`, apiUrlV2: `${environment.bxPlatform.userMgmtApiUrl}/v2` }),
  // SiUOModule.forRoot({ apiUrl: `${environment.bxPlatform.userMgmtApiUrl}/v2` }),
  // SiProfileModule.forRoot({
  //   apiUrl: `${environment.bxPlatform.userOrganizationsApiV1Url}`, apiUrlV2: `${environment.bxPlatform.userMgmtApiUrl}/v2/me`
  // }),
  NgxDatatableModule,
  PortalModule,
  ReactiveFormsModule,
  RootServicesModule,
  /* eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays */
  BxGmsMapperModule, // .forRoot(environment),
  SiMeasurementUnitsModule,
  SiSharedModule,
  routing,
  SiAccordionModule,
  SiDropdownModule,
  SiContentActionBarModule,
  SiEmptyStateModule,
  SiFilteredSearchModule,
  SiIconModule,
  SiLoadingSpinnerModule,
  SiNavbarVerticalModule,
  SiNumberInputModule,
  SiPopoverModule,
  SiPromptDialogButtonsModule,
  SiSearchBarModule,
  SiSelectModule,
  SiSidePanelModule,
  SiSliderModule,
  SiTranslateNgxTModule,
  SiLoginAuthModule,
  SiSubscriptionsModule.forRoot({
    apiUrl: `${environment.bxPlatform.subscriptionsApiUrl}/v2`
  }),
  SiUOModule.forRoot({ apiUrl: `${environment.bxPlatform.userMgmtApiUrl}/v2` }),
  SiWizardModule,
  SimplPropertyViewerNgModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (httpClient: HttpClient, 
        trace: TraceService, 
        prefixOptional: any[]): MultiTranslateHttpLoader => new MultiTranslateHttpLoader(httpClient, trace, './i18n/', 
        [...prefixOptional, '../assets/i18n/imported/', './assets/i18n/imported/no-translations/', 
          `${environment.bxCommon}/bx-common/common-ui/i18n/`, `${environment.bxCommon}/bx-common/common-ui/i18n/no-translations/`
        ] 
      ),
      deps: [HttpClient, TraceService, [new Optional(), HFW_TRANSLATION_FILE_TOKEN]]
    }
  }),
  SiTimelineWidgetComponent,
  SiEmptyStateComponent], providers: [
  AngularHttpRequest,
  ContextService,
  { provide: 'appSettingFilePath', useValue: 'config/app-settings.json' },
  { provide: 'productSettingFilePath', useValue: 'config/product-settings.json' },
  { provide: GraphicsCommonTemplateServiceBase, useClass: GraphicsCommonTemplateService, multi: false },
  CookieService,
  ServiceWorkerAuth,
  SiAppDataService,
  SiAppListService,
  SiPermissionService,
  { provide: OpenAPI, useValue: OpenAPIconfiguration },
  { provide: BaseHttpRequest, useClass: AngularHttpRequest },
  { provide: LOCALE_ID, useClass: GmsLocaleId, deps: [GmsLocaleIdService, TraceService] },
  SiqudtUnitConversionPipe,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeSrvWorker,
    multi: true,
    deps: [ServiceWorkerAuth]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SiHttpErrorInterceptor,
    multi: true
  },
  SiToastNotificationService,
  provideHttpClient(withInterceptorsFromDi())
] })

export class ApplicationModule {
  constructor(applicationRef: ApplicationRef,
    private readonly ngZone: NgZone,
    private readonly sessionService: SessionService,
    private readonly traceService: TraceService) {
    // no proper? needs to called in guards for the navigation?
    this.sessionService.initialize();
    if (traceService.isDebugEnabled(trcModuleNameApp)) {
      const originalTick: () => void = applicationRef.tick;
      applicationRef.tick = function (): any {
        const start: number = performance.now();
        /* eslint-disable-next-line prefer-rest-params*/
        const retValue: any = originalTick.apply(this, arguments);
        const end: number = performance.now();
        appTickCounter = appTickCounter + 1;
        traceService.debug(trcModuleNameApp, 'Application.tick() time: ' + (end - start));
        return retValue;
      };

      this.ngZone.runOutsideAngular(() => {
        interval(5000).subscribe(value => this.onInstrumentationTimer(value));
      });
    }
  }

  private onInstrumentationTimer(counter: number): void {
    if (this.traceService.isDebugEnabled(trcModuleNameApp)) {
      this.traceService.debug(trcModuleNameApp, 'Number of application ticks per second: ' + appTickCounter / 5);
    }
    appTickCounter = 0;
  }
}
