import { NgZone, TemplateRef } from '@angular/core';
import { BrowserObject, CnsHelperService, ExecuteCommandServiceBase, TagService } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import { BehaviorSubject, Subject } from 'rxjs';

import { ValidationDialogService } from '../../../validation-dialog/services/validation-dialog.service';
import { AlarmsContainer } from '../common/interfaces/AlarmsContainer';
import { GmsCommand } from '../processor/command/gms-command';
import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { GraphicsDatapointHelper } from '../processor/datapoint/gms-datapoint-helper';
import { GmsAutoViewport } from '../processor/gms-autoviewport';
import { GmsDepth, GmsDepths } from '../processor/gms-depth';
import { GraphicInfo } from '../processor/gms-graphic-info';
import { GmsViewport } from '../processor/gms-viewport';
import { GmsAnimationTimerService } from '../services/gms-animation-timer.service';
import { GmsBrowserObjectService } from '../services/gms-browser-object.service';
import { ElementBrushService } from '../services/gms-brush.service';
import { GmsButtonsService } from '../services/gms-buttons.service';
import { GmsCommandService } from '../services/gms-command-service';
import { DataPointService } from '../services/gms-datapoint2.service';
import { LibraryImageService } from '../services/gms-library-image-service';
import { GmsObjectSelectionService } from '../services/gms-object-selection.service';
import { PropertyImageService } from '../services/gms-property-image.service';
import { GmsSystemsService } from '../services/gms-systems.service';
import { TextGroupService } from '../services/gms-text-group-service';
import { TimerService } from '../services/timer-service';
import { GmsElementPropertyType, GraphicType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { MouseMode } from '../types/gms-graphics-mouse-mode-types';
import { SubstitutionHelper } from '../utilities/SubstitutionHelper';
import { ColorWrap, Filter } from '../utilities/color-utility';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { GmsAdorner } from './gms-adorner';
import { GmsAlarm } from './gms-alarm';
import { GmsButtons } from './gms-buttons';
import { GmsElement } from './gms-element';
import { GmsText } from './gms-text';

export class GmsGraphic extends GmsElement implements AlarmsContainer {
  public static readonly DEFAULT_ADORNER_THICKNESS = 2;
  public resourcesAdded: Subject<void> = new Subject<void>();
  public resourcesRemoved: Subject<void> = new Subject<void>();
  public selectedObjectUpdated: Subject<void> = new Subject<void>();
  public updateButtonTransform: Subject<void> = new Subject<void>();
  public initialScaleView: Subject<void> = new Subject<void>();
  public scaleView: Subject<string | undefined> = new Subject<string | undefined>();
  public scaleToFit: Subject<string | undefined> = new Subject<string | undefined>();
  public resetScrollBars: Subject<void> = new Subject<void>();
  public calculateMinMaxVisibility: Subject<void> = new Subject<void>();
  public zoomInButton: Subject<void> = new Subject<void>();
  public zoomOutButton: Subject<void> = new Subject<void>();
  public zoomChanged: Subject<{ currentZoom: number, update: boolean }> =
    new Subject<{ currentZoom: number, update: boolean }>();
  public alarmsChanged: Subject<string> = new Subject<string>();
  public toggleAlarmsVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public dynamicresourcesChanged: Subject<string> = new Subject<string>();
  public dynamicFiltersChanged: Subject<string> = new Subject<string>();
  public resetSVGDocument: Subject<string | undefined> = new Subject<string | undefined>();
  public updateCoverageArea: Subject<void> = new Subject<void>();
  public setupMenuItems: Subject<void> = new Subject<void>();
  public openAlarmPopover: Subject<any> = new Subject<any>();
  public allocateScrollbarSpaceDepth: Subject<void> = new Subject<void>();
  public resetToDefaultZoom: Subject<void> = new Subject<void>();
  public updateGraphicView: Subject<void> = new Subject<void>();
  public closePopover: Subject<void> = new Subject<void>();
  public updateHoverTooltip: Subject<void> = new Subject<void>();
  public TooltipToggle: Subject<void> = new Subject<void>();
  public setPointCenteredZoom: Subject<void> = new Subject<void>();

  // Used to break the cyclic imports with view components
  // Examples
  // symbol --> group --> symbol
  // replication --> symbol --> replication ...and so on
  public symbolTemplateRef: TemplateRef<any> = undefined;
  public groupTemplateRef: TemplateRef<any> = undefined;
  public replicationTemplateRef: TemplateRef<any> = undefined;

  public adorners: GmsAdorner[] = undefined;
  public buttonElements: GmsButtons[] = undefined;
  public alarms: GmsAlarm[] = undefined;
  public buttonsService: GmsButtonsService = undefined;
  public depths: GmsDepths;
  public scrollLeft = 0;
  public scrollTop = 0;
  public svgDocumentWidth = 0;
  public svgDocumentHeight = 0;
  public transformMatrix = `matrix(1, 0, 0, 1, 0, 0)`;
  public stateRestored = false;
  public disciplines: Set<string> = new Set<string>();
  public selectedDisciplines: Set<string> = new Set<string>();
  public disciplineFromIdMap: Map<string, string> = new Map<string, string>();
  // CA support
  private readonly CoverageAreaElements: Map<string, GmsElement>;
  // Load error
  public loadError = false;

  // Used for saving and restoring state of visible layers
  public visibleLayers: boolean[] = [];
  public get SelectedDepth(): GmsDepth {
    if (this !== undefined && this.depths !== undefined) {
      return this.depths.selectedDepth;
    }

    return undefined;
  }

  public set SelectedDepth(depth: GmsDepth) {
    if (this !== undefined && this.depths !== undefined) {
      this.depths.selectedDepth = depth;
    }
  }

  private _hoverTooltipElement: GmsElement = undefined;
  public get HoverTooltipElement(): GmsElement {
    return this._hoverTooltipElement;
  }

  public set HoverTooltipElement(element: GmsElement) {
    if (this._hoverTooltipElement !== element) {
      if (element?.Type !== GmsElementType.Graphic) {
        this._hoverTooltipElement = element;
      } else {
        this._hoverTooltipElement = undefined;
      }

      this.updateHoverTooltip?.next();
    }
  }

  public _viewport: GmsViewport;
  public get viewport(): GmsViewport {
    return this._viewport;
  }

  public set viewport(value: GmsViewport) {
    this._viewport = value;
  }

  public dynamicResources: any[] = [];
  public dynamicFilters: Map<string, Filter> = new Map<string, Filter>();
  // Data points used in a current graphic page: header's datapoints and more (possibly more from the QuestionmarkExpression, etc)
  public datapoints: Datapoint[];

  // Commands used in a current header's datapoints:
  public commands: GmsCommand[] = [];
  public sliding: Subject<MouseEvent> = new Subject<MouseEvent>();

  public isDoneLoading = false;

  // Graphic Info
  public graphicInfo: GraphicInfo;
  public translateService: TranslateService;

  private readonly NotZoomableElements: Map<string, GmsElement>;

  private _viewBox: string[] = [];
  private _initialBoundingBox: number[] = [];

  private _resources: Node[] = [];

  private _mouseMode: MouseMode = MouseMode.None;
  public get mouseMode(): MouseMode {
    return this._mouseMode;
  }
  public set mouseMode(value: MouseMode) {
    if (this._mouseMode !== value) {
      this._mouseMode = value;
    }
  }
  private _mouseDown = false;
  public get mouseDown(): boolean {
    return this._mouseDown;
  }
  public set mouseDown(value: boolean) {
    if (this._mouseDown !== value) {
      this._mouseDown = value;
    }
  }

  private _isSliding = false;
  public get IsSliding(): boolean {
    return this._isSliding;
  }
  public set IsSliding(value: boolean) {
    if (this._isSliding !== value) {
      this._isSliding = value;
      this.NotifyPropertyChanged('IsSliding');
    }
  }

  public get locale(): string {
    return this.translateService !== undefined ?
      this.translateService.getBrowserLang() : FormatHelper.enUS;
  }
  public get defaultLocale(): string {
    return this.translateService !== undefined ?
      this.translateService.defaultLang : FormatHelper.enUS;
  }

  // Currently selected BrowserObject in the SystemBrowser
  private _selectedObject: BrowserObject;
  public get SelectedObject(): BrowserObject {
    return this._selectedObject;
  }
  public set SelectedObject(value: BrowserObject) {
    if (value !== this._selectedObject) {
      this._selectedObject = value;
      this.selectedObjectUpdated.next();
    }
  }

  public get NumDepths(): number {
    if (this.depths?.depthList !== undefined) {
      return this.depths.depthList.length;
    }

    return undefined;
  }

  private _templateContext: string;
  public get TemplateContext(): string {
    return this._templateContext;
  }
  public set TemplateContext(value: string) {
    if (value !== this._templateContext) {
      this._templateContext = value;
    }
  }

  private _objectReference: string;
  public get ObjectReference(): string {
    return this._objectReference;
  }
  public set ObjectReference(value: string) {
    if (value !== this._objectReference) {
      this._objectReference = value;
    }
  }

  public get Resources(): Node[] {
    return this._resources;
  }

  public get Alarms(): GmsAlarm[] {
    return this.alarms;
  }

  private _graphicType: GraphicType = undefined;
  public get GraphicType(): GraphicType {
    return this._graphicType;
  }
  public set GraphicType(value: GraphicType) {
    if (this._graphicType !== value) {
      this._graphicType = value;
    }
  }

  private _datapointService: DataPointService = undefined;
  public get DatapointService(): DataPointService {
    return this._datapointService;
  }
  public set DatapointService(value: DataPointService) {
    if (this._datapointService !== value) {
      this._datapointService = value;
    }
  }

  private _textGroupService: TextGroupService = undefined;
  public get TextGroupService(): TextGroupService {
    return this._textGroupService;
  }
  public set TextGroupService(value: TextGroupService) {
    if (this._textGroupService !== value) {
      this._textGroupService = value;
    }
  }

  private _propertyImageService: PropertyImageService = undefined;
  public get PropertyImageService(): PropertyImageService {
    return this._propertyImageService;
  }
  public set PropertyImageService(value: PropertyImageService) {
    if (this._propertyImageService !== value) {
      this._propertyImageService = value;
    }
  }

  private _libaryImageService: LibraryImageService = undefined;
  public get LibraryImageService(): LibraryImageService {
    return this._libaryImageService;
  }
  public set LibraryImageService(value: LibraryImageService) {
    if (this._libaryImageService !== value) {
      this._libaryImageService = value;
    }
  }

  private _elementBrushService: ElementBrushService = undefined;
  public get ElementBrushService(): ElementBrushService {
    return this._elementBrushService;
  }
  public set ElementBrushService(value: ElementBrushService) {
    if (this._elementBrushService !== value) {
      this._elementBrushService = value;
    }
  }

  private _animationTimerService: GmsAnimationTimerService = undefined;
  public get AnimationTimerService(): GmsAnimationTimerService {
    return this._animationTimerService;
  }
  public set AnimationTimerService(value: GmsAnimationTimerService) {
    if (this._animationTimerService !== value) {
      this._animationTimerService = value;
    }
  }

  private _systemService: GmsSystemsService = undefined;
  public get GmsSystemsService(): GmsSystemsService {
    return this._systemService;
  }
  public set GmsSystemsService(value: GmsSystemsService) {
    if (this._systemService !== value) {
      this._systemService = value;
    }
  }

  private _gmsObjectSelectionService: GmsObjectSelectionService = undefined;
  public get GmsObjectSelectionService(): GmsObjectSelectionService {
    return this._gmsObjectSelectionService;
  }
  public set GmsObjectSelectionService(value: GmsObjectSelectionService) {
    if (this._gmsObjectSelectionService !== value) {
      this._gmsObjectSelectionService = value;
    }
  }

  private _gmsBrowserObjectService: GmsBrowserObjectService = undefined;
  public get GmsBrowserObjectService(): GmsBrowserObjectService {
    return this._gmsBrowserObjectService;
  }
  public set GmsBrowserObjectService(value: GmsBrowserObjectService) {
    if (this._gmsBrowserObjectService !== value) {
      this._gmsBrowserObjectService = value;
    }
  }

  private _cnsHelperService: CnsHelperService = undefined;
  public get CnsHelperService(): CnsHelperService {
    return this._cnsHelperService;
  }
  public set CnsHelperService(value: CnsHelperService) {
    if (this._cnsHelperService !== value) {
      this._cnsHelperService = value;
    }
  }

  private _replicationService: any = undefined;
  public get ReplicationService(): any {
    return this._replicationService;
  }
  public set ReplicationService(value: any) {
    if (this._replicationService !== value) {
      this._replicationService = value;
    }
  }

  private _validationDialogService: ValidationDialogService;
  public get ValidationDlgService(): ValidationDialogService {
    return this._validationDialogService;
  }
  public set ValidationDlgService(value: ValidationDialogService) {
    if (this._validationDialogService !== value) {
      this._validationDialogService = value;
    }
  }

  private _tagService: TagService;
  public get TagService(): TagService {
    return this._tagService;
  }
  public set TagService(value: TagService) {
    if (this._tagService !== value) {
      this._tagService = value;
    }
  }

  private _displayName: string = undefined;
  public get DisplayName(): string {
    return this._displayName;
  }

  public set DisplayName(value: string) {
    if (this._displayName !== value) {
      this._displayName = value;
    }
  }

  private _traceService: TraceService = undefined;
  public get TraceService(): TraceService {
    return this._traceService;
  }
  public set TraceService(value: TraceService) {
    if (this._traceService !== value) {
      this._traceService = value;
    }
  }

  private _timerService: TimerService = undefined;
  public get TimerService(): TimerService {
    return this._timerService;
  }
  public set TimerService(value: TimerService) {
    if (this._timerService !== value) {
      this._timerService = value;
    }
  }
  private _gmsCommandService: GmsCommandService = undefined;
  public get GmsCommandService(): GmsCommandService {
    return this._gmsCommandService;
  }
  public set GmsCommandService(value: GmsCommandService) {
    if (this._gmsCommandService !== value) {
      this._gmsCommandService = value;
    }
  }

  private _executeCommandService: ExecuteCommandServiceBase = undefined;
  public get ExecuteCommandService(): ExecuteCommandServiceBase {
    return this._executeCommandService;
  }
  public set ExecuteCommandService(value: ExecuteCommandServiceBase) {
    if (this._executeCommandService !== value) {
      this._executeCommandService = value;
    }
  }

  private _toastNotificationService: SiToastNotificationService = undefined;
  public get ToastNotificationService(): SiToastNotificationService {
    return this._toastNotificationService;
  }
  public set ToastNotificationService(value: SiToastNotificationService) {
    if (this._toastNotificationService !== value) {
      this._toastNotificationService = value;
    }
  }

  private _zone: NgZone = undefined;
  public get Zone(): NgZone {
    return this._zone;
  }
  public set Zone(value: NgZone) {
    if (this._zone !== value) {
      this._zone = value;
    }
  }

  private _currentZoomLevel = 1;
  public get CurrentZoomLevel(): number {
    return this._currentZoomLevel;
  }
  public set CurrentZoomLevel(value: number) {
    this._currentZoomLevel = value;
  }

  private _isDestroyed = false;
  public get IsDestroyed(): boolean {
    return this._isDestroyed;
  }

  // Initialized with snapin hldl config value
  private _showAlarmIndication = false;
  public get ShowAlarmIndication(): boolean {
    return this._showAlarmIndication;
  }
  public set ShowAlarmIndication(value: boolean) {
    if (value !== undefined && value !== this._showAlarmIndication) {
      this._showAlarmIndication = value;
    }
  }

  // Initialized with snapin hldl config value
  // Must be 8-64
  private _alarmIconSize = 16;
  public get AlarmIconSize(): number {
    return this._alarmIconSize;
  }
  public set AlarmIconSize(value: number) {
    if (value !== undefined && value !== this._alarmIconSize) {
      if (value < 8) {
        value = 8;
      }

      if (value > 64) {
        value = 64;
      }

      this._alarmIconSize = value;
    }
  }

  private _layersCounted = 0;
  public get LayersCounted(): number {
    return this._layersCounted;
  }

  public set LayersCounted(value: number) {
    if (this._layersCounted !== value) {
      this._layersCounted = value;
    }
  }

  private _layerCount: number = undefined;
  public get LayerCount(): number {
    return this._layerCount;
  }

  public set LayerCount(value: number) {
    if (this._layerCount !== value) {
      this._layerCount = value;
    }
  }

  private _isPermScaleToFit = false;
  public get IsPermScaleToFit(): boolean {
    return this._isPermScaleToFit;
  }

  public set IsPermScaleToFit(value: boolean) {
    if (this._isPermScaleToFit !== value) {
      this._isPermScaleToFit = value;
    }
  }
  /** Provides available graphic's coverage elements
   */
  public get HasCoverageArea(): boolean {
    return (this.CoverageAreaElements !== undefined && this.CoverageAreaElements.size > 0); // this._hasCoverageArea;
  }

  /** provides CoverageArea elements visibility status
   */
  private _coverageAreaMode = false;
  public get CoverageAreaMode(): boolean {
    return this._coverageAreaMode;
  }

  public set CoverageAreaMode(value: boolean) {
    if (this._coverageAreaMode !== value) {
      this._coverageAreaMode = value;

      this.updateCoverageArea.next();
    }
  }

  /** Toolbar toggle of alarms visibility
   */
  private _toggleAlarmsVisibility = true;
  public get ToggleAlarmsVisibility(): boolean {
    return this._toggleAlarmsVisibility;
  }

  public set ToggleAlarmsVisibility(value: boolean) {
    if (this._toggleAlarmsVisibility !== value) {
      this._toggleAlarmsVisibility = value;

      this.NotifyToggleAlarmsVisbility(this._toggleAlarmsVisibility);
    }
  }

  private _adornerStrokeThickness = GmsGraphic.DEFAULT_ADORNER_THICKNESS;
  public get AdornerStrokeThickness(): number {
    return this._adornerStrokeThickness;
  }
 
  constructor() {
    super(GmsElementType.Graphic);
    this.adorners = [];
    this.buttonElements = [];
    this.children = [];
    this.alarms = [];
    this.NotZoomableElements = new Map<string, GmsElement>();
    this.graphicInfo = new GraphicInfo();
    this.buttonsService = new GmsButtonsService();
    this.buttonsService.setButtonsService(this.buttonElements);
    // CA support
    this.CoverageAreaElements = new Map<string, GmsElement>();
    this.loadError = false;
  }

  public Initialize(): void {
    // async loading needs indication to prempt
    // Since the view component destroy early - rapid switching of snapins
    this._isDestroyed = false;
  }

  public addDisciplinePair(key: string, value: string): void {
    if (this.disciplineFromIdMap !== undefined) {
      this.disciplineFromIdMap.set(key, value);
    }
  }

  public Destroy(): void {

    if (this._animationTimerService !== undefined) {
      this._animationTimerService.cleartimers();
    }

    this.adorners.forEach(adorner => {
      adorner.Destroy();
    });

    this.adorners.length = 0;
    this.buttonElements.length = 0;
    this._viewBox = [];
    this.viewport = undefined;
    this.SelectedObject = undefined;
    this.NotZoomableElements.clear();
    this.disciplines.clear();
    this.selectedDisciplines.clear();
    this.dynamicFilters.clear();
    this.resetSVGDocument.next(undefined);
    this.CoverageAreaElements.clear();
    this.UnsetGraphicLoaded();

    this.alarms.forEach(alarm => {
      alarm.Destroy();
    });
    this.alarms.length = 0;
    this.NotifyAlarmsChanged('Alarms Cleared'); // For RelatedItems navigation refresh issue

    if (this.children !== undefined) {
      const itemsToDestroy: GmsElement[] = this.children.slice(); // Copies the array, since destroy changes the source array
      itemsToDestroy.forEach(child => {
        child.Destroy();
      });
      itemsToDestroy.length = 0;
    }
    this.children.length = 0;
    this.HoverTooltipElement = undefined;
    this._resources = [];
    this.dynamicResources = [];
    if (this.selectedObjectUpdated !== undefined) {
      this.selectedObjectUpdated.complete();
    }
    SubstitutionHelper.Clean(); // Clear the regular expression instance.
    this._isDestroyed = true;
  }

  public async ShapeChanged(): Promise<any> {
    if (this.children == null) {
      return;
    }
    for (const child of this.children) {
      child.ShapeChanged();
    }

    this.NotifyPropertyChanged();
  }

  public get ViewBox(): string {
    if (this._viewBox.length > 0) {
      const actualX = Number(this._viewBox[0]);
      const actualY = Number(this._viewBox[1]);
      const x: number = actualX > 10 ? actualX - 10 : 0;
      const y: number = actualY > 10 ? actualY - 10 : 0;
      const width: number = Number(this._viewBox[2]) + 20;
      const height: number = Number(this._viewBox[3]) + 20;

      return x + ' ' + y + ' ' + width + ' ' + height;
    }

    return '0 0' + ' ' + this.Width + ' ' + this.Height;
  }

  public resetLayerValues(): void {
    this._layersCounted = 0;
    this._layerCount = undefined;
  }

  public get InitialBoundingBox(): number[] {
    return this._initialBoundingBox;
  }

  public set InitialBoundingBox(value: number[]) {
    if (this._initialBoundingBox !== value) {
      this._initialBoundingBox = value;
    }
  }

  public AddChild(element: GmsElement): void {

    this.children.push(element);

    // ChangeDetection: const others know that a property changed
    this.NotifyPropertyChanged();
  }

  public RemoveChild(element: GmsElement): void {
    const index: number = this.children.indexOf(element, 0);
    if (index > -1) {
      this.children.splice(index, 1);

      // ChangeDetection: const others know that a property changed
      this.NotifyPropertyChanged();
    }
  }

  public AddResources(resources: Node[]): void {
    resources.forEach(node => { this._resources.push(node); });
    if (this.resourcesAdded?.observers !== undefined && this.resourcesAdded.observers.length > 0) {
      this.resourcesAdded.next();
    }
  }

  public deleteResources(): void {
    if (this.resourcesRemoved !== undefined) {
      this.resourcesRemoved.next();
    }
  }

  public AddDynamicResources(brush: any): void {
    if (brush === undefined) {
      return;
    }
    this.dynamicResources.push(brush);
    if (this.dynamicresourcesChanged !== undefined) {
      this.dynamicresourcesChanged.next('DynamicResourceAdded');
    }
  }

  public RemoveDynamicResources(brushUrlsToRemove: string[]): void {
    let removed = false;
    brushUrlsToRemove.forEach(urlToRemove => {
      const indexToRemove: number = this.dynamicResources.findIndex(brush => brush.Url === urlToRemove);
      if (indexToRemove !== -1) {
        this.dynamicResources.splice(indexToRemove, 1);
        removed = true;
      }
    });
    if (removed && this.dynamicresourcesChanged !== undefined) {
      this.dynamicresourcesChanged.next('DynamicResourcesRemoved');
    }
  }

  public AddDynamicFilter(filter: Filter): void {
    if (isNullOrUndefined(filter) || isNullOrUndefined(this?.dynamicFilters)) {
      return;
    }

    if (this.dynamicFilters.has(filter?.EvaluationFilterId)) {
      return;
    }

    this.dynamicFilters.set(filter.EvaluationFilterId, filter);
    if (!isNullOrUndefined(this.dynamicFiltersChanged)) {
      this.dynamicFiltersChanged.next('DynamicFilterAdded');
    }
  }

  public removeDynamicFilter(filter: Filter): void {
    if (isNullOrUndefined(filter)) {
      return;
    }

    if (!isNullOrUndefined(this?.dynamicFilters)) {
      this.dynamicFilters.delete(filter.EvaluationFilterId);
    }
  }

  public get dynamicFilterArr(): Filter[] {
    if (isNullOrUndefined(this?.dynamicFilters) || this?.dynamicFilters.size === 0) {
      return [];
    }

    return Array.from(this.dynamicFilters.values());
  }

  public GetAll(withReplications: boolean, withLayers: boolean = true, withSymbolInstanceChildren: boolean = true): GmsElement[] {
    if (withLayers && withSymbolInstanceChildren) {
      // handle cached versions
      // NOTE:
      // if (withReplications)
      //     return AllWithReplications ?? (AllWithReplications = super.GetAll(withReplications, withLayers, withSymbolInstanceChildren));
      // return this.All;
    }
    return super.GetAll(withReplications, withLayers, withSymbolInstanceChildren);
  }

  public async CnsDisplayLabelChanged(): Promise<any> {
    // get a list of all text elements descending from the graphic
    const elements: GmsElement[] = this.GetAll(true, true, true)
      .filter((e: GmsText) => e.Type === GmsElementType.Text);

    // update text on each text element
    elements.forEach(
      (e: GmsText) => {
        e.UpdateText();
      });
  }

  public AdjustZoomVisibility(): void {
    for (const layer of this.children) {
      this.SetVisibility(layer);

      if (!layer.Visible) {
        continue;
      }

      if (layer.Replication !== undefined && layer.Replication.children.length > 0) {
        const clones: GmsElement[] = layer.Replication.children;
        for (let i = 0; i < clones.length; i++) {
          const clone: GmsElement = clones[i];
          this.SetChildElementsVisibility(clone);
        }
      }

      this.SetChildElementsVisibility(layer);
    }
  }

  public SetChildElementsVisibility(element: GmsElement): void {
    if (element.children === undefined || element.children.length === 0) {
      return;
    }

    for (const child of element.children) {
      this.SetVisibility(child);

      if (child.Replication !== undefined && child.Replication.children.length > 0) {
        const clones: GmsElement[] = child.Replication.children;
        for (let i = 0; i < clones.length; i++) {
          const clone: GmsElement = clones[i];
          this.SetChildElementsVisibility(clone);
        }
      }

      if (child.Visible && child.MinMaxVisibility && child.hasChildren) {
        this.SetChildElementsVisibility(child);
      }
    }
  }

  public AddAlarm(alarmToAdd: GmsAlarm): void {
    if (!this.alarms.includes(alarmToAdd)) {
      this.alarms.push(alarmToAdd);
      this.NotifyAlarmsChanged('Alarm Added');
    }
  }

  public RemoveAlarm(alarmToRemove: GmsAlarm): void {
    const index: number = this.alarms.indexOf(alarmToRemove);
    if (index > -1) {
      this.alarms.splice(index, 1);
      this.NotifyAlarmsChanged('Alarm Removed');
    }
  }

  public Deserialize(node: Node): void {

    // NOTE: Deserialize Graphic Header Info
    this.graphicInfo = new GraphicInfo();

    this.graphicInfo.Deserialize(node);

    this.depths = GmsDepths.createDepths(node);

    this.viewport = undefined;
    this.viewport = GmsViewport.createViewport(node, this.DisplayName, this.depths);

    if (this.viewport !== undefined) {
      this.setSelectedDepthIndex(this.viewport.DepthIndex);
    }

    let result: string = SvgUtility.GetAttributeValue(node, 'BoundingRect');
    if (result !== undefined) {
      const boundingRect: number[] = result.split(',').map(x => Number(x));
      if (boundingRect !== undefined) {
        this._initialBoundingBox = boundingRect;
      }
    }

    result = SvgUtility.GetAttributeValue(node, 'width');
    if (result !== undefined) {
      this.Width = Number(result);
    }

    result = SvgUtility.GetAttributeValue(node, 'height');
    if (result !== undefined) {
      this.Height = Number(result);
    }
    result = SvgUtility.GetAttributeValue(node, 'AdornerStrokeThickness');
    if (result !== undefined) {
      this._adornerStrokeThickness = Number(result);
    }

    // read header datapoints
    this.datapoints = GraphicsDatapointHelper.createDatapoints(node);

    // get header datapoints' commands
    this.commands = GraphicsDatapointHelper.getCommands(this.datapoints);

    // Viewbox bounding rectangle
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.BoundingRect);
    if (result !== undefined) {
      this._viewBox = result.split(',');
    }

    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.GraphicType);
    if (result !== undefined) {
      this._graphicType = GraphicType[result];
    }

    result = SvgUtility.GetAttributeValue(node, 'AutoFit');
    // Resets the zoom level for all graphics
    this.resetToDefaultZoom.next();

    if ((result === undefined && this.viewport === undefined) || this._graphicType === GraphicType.GraphicTemplate) {
      this.IsPermScaleToFit = true;
    } else {
      this.IsPermScaleToFit = false;
    }

    if (this.viewport === undefined && this.IsPermScaleToFit === false) {
      for (const datapoint of this.datapoints) {
        const currentDesignation: string = this.SelectedObject.Designation + ';';

        if (datapoint?.Designation === currentDesignation
                    && datapoint?.GraphicViewport?.length === 4) {
          const [x, y, width, height] = datapoint.GraphicViewport;
          this.viewport = new GmsAutoViewport(x, y, width, height, datapoint.DepthIndex);
          break;
        }
      }

      if (this.viewport !== undefined && this.viewport.DepthIndex !== undefined) {
        this.setSelectedDepthIndex(this.viewport.DepthIndex);
      }
    }

    if (this.initialScaleView?.observers !== undefined && this.initialScaleView.observers.length > 0) {
      this.initialScaleView.next();
    }

    if (this._graphicType === GraphicType.GraphicTemplate && this.SelectedObject !== undefined) {
      this._objectReference = this.TemplateContext !== undefined || this.TemplateContext.trim() !== '' ?
        this.TemplateContext + ';' : this.SelectedObject.Designation + ';';
    }

    super.Deserialize(node);

    if (this.Background === undefined) {
      result = SvgUtility.GetAttributeValue(node, 'BackColor');
      // NOTE: Modify this code after 7.0 or SVG Conversion Current Version: (1.0.0.29)
      if (result !== undefined) {
        const bg: ColorWrap = new ColorWrap(result);
        if (bg.Current !== null) {
          if (bg.Current.HasResolvedBrush) {
            this.dynamicResources.push(bg.Current.Brush);
          }
          this.Background = bg.Current.ColorString;
          this.BackgroundOpacity = bg.Current.Opacity;
        }
      }
    }
  }

  public refreshElementSelection(currElement: any): void {
    currElement?.updateElementSelection();

    const children: any = currElement?.children;
    if (children !== undefined) {
      for (const child of currElement.children) {
        this.refreshElementSelection(child);
      }
    }

    if (currElement !== undefined && currElement.Replication !== undefined
            && currElement.Replication.children.length > 0) {
      const clones: any = currElement.Replication.children;
      for (const child of clones) {
        this.refreshElementSelection(child);
      }
    }
  }

  public updateLayers(): void {
    this.NotifyFilterMenuChanged();
    this.UpdateChildrenVisible();
  }

  public setSelectedDepthByName(name: string): void {
    if (this.depths !== undefined
            && this.depths.depthList !== undefined) {
      for (const depth of this.depths.depthList) {
        if (depth.Name === name) {
          this.SelectedDepth = depth;
        }
      }
    }
  }

  public resetDepth(): void {
    if (this.viewport?.DepthIndex !== undefined && this.viewport.IsAutoViewport === false) {
      this.setSelectedDepthIndex(this.viewport.DepthIndex);
    } else if (this.SelectedDepth !== undefined
            && this.depths !== undefined
            && this.SelectedObject !== undefined
            && this.datapoints !== undefined) {
      for (const datapoint of this.datapoints) {
        const currDesignation: string = this.SelectedObject.Designation + ';';
        const currObjectId: string = this.SelectedObject.ObjectId;
        const match: boolean = currDesignation === datapoint.Designation || currObjectId === datapoint.ObjectId;
        if (match) {
          this.setSelectedDepthIndex(datapoint.DepthIndex);
          if (datapoint?.GraphicViewport?.length === 4 && this.IsPermScaleToFit === false) {
            const [x, y, width, height] = datapoint.GraphicViewport;
            this.viewport = new GmsAutoViewport(x, y, width, height, datapoint.DepthIndex);
            this.scaleView.next(undefined);
          }

          break;
        }
      }
    }

    this.updateLayers();
  }

  public resetDisciplines(): void {
    if (this !== undefined && this.selectedDisciplines !== undefined) {
      for (const discipline of this.disciplines) {
        this.selectedDisciplines.add(discipline);
      }

      this.updateLayers();
    }
  }

  public refreshGraphicSelection(): void {
    this.refreshElementSelection(this);
  }

  private _isTooltipEnabled = true;
  public get IsTooltipEnabled(): boolean {
    return this._isTooltipEnabled;
  }

  public set IsTooltipEnabled(value: boolean) {
    if (this._isTooltipEnabled !== value) {
      this._isTooltipEnabled = value;
    }
  }

  public toggleTooltipEnabled(): void {
    this._isTooltipEnabled = !this._isTooltipEnabled;
  }

  private _isLoadingBeforeDeserialization = false;
  public get IsLoadingBeforeDeserialization(): boolean {
    return this._isLoadingBeforeDeserialization;
  }

  public SetIsLoadingBeforeDeserialization(): void {
    this._isLoadingBeforeDeserialization = true;
    this.updateGraphicView?.next();
  }

  public UnsetIsLoadingBeforeDeserialization(): void {
    this._isLoadingBeforeDeserialization = false;
    this.updateGraphicView?.next();
  }

  public SetGraphicLoaded(success: boolean = true): void {
    this.loadError = !success;
    this.isDoneLoading = true;
    this._isLoadingBeforeDeserialization = false;
  }

  public UnsetGraphicLoaded(): void {
    this.isDoneLoading = false;
  }

  public UpdateNotZoomableElements(): void {
    this.NotZoomableElements.forEach(e => e.UpdateZoomableTransform());
  }

  public AddNotZoomableElement(element: GmsElement): void {
    if (this.NotZoomableElements.has(element.Id) === false) {
      this.NotZoomableElements.set(element.Id, element);
    }
  }

  public RemoveNotZoomableElement(element: GmsElement): void {
    if (this.NotZoomableElements.has(element.Id)) {
      this.NotZoomableElements.delete(element.Id);
    }
  }

  public AddCoverageAreaElement(element: GmsElement): void {
    if (this.CoverageAreaElements.has(element.Id) === false) {
      this.CoverageAreaElements.set(element.Id, element);
    }
  }

  public UpdateCoverageAreas(): void {
    this.CoverageAreaElements.forEach(e => {
      e.UpdateCA();
    });
  }

  // NOTE: The GmsLayer type is not explicitly used to preserve OOP design.
  public NotifyFilterMenuChanged(): void {
    for (const layer of this.children) {
      const currentLayer: any = layer;
      if (currentLayer.Type === GmsElementType.Layer) {
        // NOTE: Check whether this line is necessary
        currentLayer.Visible = true;
        currentLayer.UpdateLayerVisibility();
      }
    }

    // this.updateIsSelectableForAllElements();
  }

  // NOTE: The GmsLayer type is not explicitly used to preserve OOP design.
  public NotifyLayersChanged(): void {
    for (const layer of this.children) {
      const currentLayer: any = layer;
      if (currentLayer.Type === GmsElementType.Layer) {
        currentLayer.UpdateLayerVisibility();
      }
    }
  }

  public setSelectedDepthIndex(index: number): void {
    if (this.depths !== undefined
            && this.depths.depthList !== undefined
            && index >= 0
            && index < this.depths.depthList.length) {
      const targetDepth: GmsDepth = this.depths.depthList[index];
      this.SelectedDepth = targetDepth;
    }
  }

  public getDisplaySizeScaleFactor(): number {
    const scale: number = (this.SelectedDepth?.DisplaySize !== undefined
            && this.SelectedDepth?.DisplaySize !== 0
            && this.Width !== undefined
            && this.Width !== 0)
      ? this.Width / this.SelectedDepth.DisplaySize : 1;
    return scale;
  }

  private SetVisibility(element: GmsElement): void {
    if (element.MinVisibility === undefined && element.MaxVisibility === undefined) {
      return;
    }

    const currentVisibilityLevel: number = this.Width / this.CurrentZoomLevel;

    if (element.MinVisibility === undefined) {
      element.MinMaxVisibility = currentVisibilityLevel < element.MaxVisibility;
    } else if (element.MaxVisibility === undefined) {
      element.MinMaxVisibility = currentVisibilityLevel > element.MinVisibility;
    } else {
      element.MinMaxVisibility = currentVisibilityLevel > element.MinVisibility && currentVisibilityLevel < element.MaxVisibility;
    }
  }

  private NotifyAlarmsChanged(propertyName: string = ''): void {
    if (this.alarmsChanged.observers !== null && this.alarmsChanged.observers.length > 0) {
      this.alarmsChanged.next(propertyName);
    }
  }

  private NotifyToggleAlarmsVisbility(visible: boolean): void {
    if (this.toggleAlarmsVisibility.observers !== null && this.toggleAlarmsVisibility.observers.length > 0) {
      this.toggleAlarmsVisibility.next(visible);
    }
  }
}
