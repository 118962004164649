import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BrowserObject, Designation, SiteSelectionServiceBase } from '@gms-flex/services';
import { Platform } from '@angular/cdk/platform';
import { ObjectManagerConfig, SelectedItemsChangedArgs } from '../../object-manager/object-manager.types';
import { SelectionPriority, SelectionRequest, ViewFilter } from '../../object-manager-core/view-model/types';
import { ModalDialogResult } from '../data.model';
import { ObjectManagerSettingsServiceBase } from '../object-manager-settings.service.base';
import { ReplaySubject, Subscription } from "rxjs";
import { AggregateViewId } from "../../object-manager-core";
import { FullQParamId, IHfwMessage } from "@gms-flex/core";

@Component({
  selector: 'gms-om-service-template',
  templateUrl: './service-template.component.html',
  styleUrl: './service-template.component.scss'
})
export class ServiceTemplateComponent implements OnInit, OnDestroy {

  public singleSelection: boolean;
  public title: string;
  public showFilter = true;
  public enableSingleSite = false;
  public roots: string[];
  public selectionReceived: BrowserObject[];
  public receivedDesignation: string;
  public views: ViewFilter;
  public selectedNode: string;
  public selectableTypes: string[];
  public creatableTypes: string[];
  public buttonDisabled = true;
  public newItemBtnTxt = '';
  public defaultSaveObjectDesc = '';
  public defaultSaveObjectName = '';
  public saveCallback: any;
  public isMobileDevice: boolean;
  public updateSelectionInd: ReplaySubject<SelectionRequest>;

  @Output() public readonly dialogBtnResult: EventEmitter<ModalDialogResult> = new EventEmitter<ModalDialogResult>();
  @Output() public readonly selectionChanged: EventEmitter<BrowserObject[]> = new EventEmitter<BrowserObject[]>();
  @Output() public readonly saveBOComplete: EventEmitter<BrowserObject> = new EventEmitter<BrowserObject>();

  private readonly currentFrame: string = "system-manager";
  private readonly subscriptions: Subscription[] = [];
  private initialQParam: string;

  public get objectManagerConfig(): ObjectManagerConfig {
    return {
      viewConfig: {
        customRoots: this.roots,
        viewFilter: this.views,
        selectableTypes: this.selectableTypes,
        creatableTypes: this.creatableTypes
      },
      initialSelection: this.selectedNode,
      newItemBtnTxt: this.newItemBtnTxt,
      dialogCmdBtns: true,
      defaultSaveObjectName: this.defaultSaveObjectName,
      defaultSaveObjectDesc: this.defaultSaveObjectDesc
    };
  }

  constructor(
    private readonly platform: Platform,
    private readonly objectManagerSettingsService: ObjectManagerSettingsServiceBase,
    private readonly siteSelectionService: SiteSelectionServiceBase,
    private readonly messageBroker: IHfwMessage
  ) {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }

    this.updateSelectionInd = new ReplaySubject<SelectionRequest>(3);
  }

  public ngOnInit(): void {
    this.onSelection(undefined);
    this.showFilter = this.objectManagerSettingsService.showFilterButton();

    this.subscriptions.push(this.siteSelectionService.selectedSite.subscribe(selectedSite => {
      this.enableSingleSite = selectedSite.singleSiteActive
    }));

    if (this.enableSingleSite) {
      this.singleSiteUpdateSelection();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
  }

  public onViewChanged(event: any): void {
    if (!this.enableSingleSite) {
      return;
    }
    const aggregateViewId = event as AggregateViewId;
    const newViewQParam = this.updateViewNameFromQueryParam(this.initialQParam, aggregateViewId.description.replace(" ", ""));
    this.updateSelectionInd.next({
      selection: newViewQParam,
      priority: SelectionPriority.QueryParam,
      sendMessage: false
    });
  }

  public onSelection(sel: SelectedItemsChangedArgs): void {
    if (!sel || !sel.objects || sel.objects.length === 0) {
      return;
    }
    this.selectionChanged.emit(sel.objects);
    this.buttonDisabled = false;
  }

  public onCancel(): void {
    this.dialogBtnResult.emit(ModalDialogResult.Cancelled);
  }

  // send up the button that was used to close the modal
  public onDialogClosed(event: boolean): void {
    this.dialogBtnResult.emit(event ? ModalDialogResult.Ok : ModalDialogResult.Cancelled);
  }

  public onSavedBO(event: BrowserObject): void {
    this.saveBOComplete.emit(event);
  }

  private singleSiteUpdateSelection(): void {
    const fullQParamId = new FullQParamId(this.currentFrame, 'SystemQParamService', 'primary');
    this.subscriptions.push(this.messageBroker.getQueryParam(fullQParamId)
      .subscribe(qParam => {
        this.initialQParam = qParam;
        const dParts = new Designation(this.initialQParam).designationParts;
        const selection = dParts[0] + "." + dParts[1] + ":" + dParts[2] + "." + dParts[3]
        this.updateSelectionInd.next({
          selection: selection,
          priority: SelectionPriority.QueryParam,
          sendMessage: false
        });
      }));
  }

  private updateViewNameFromQueryParam(qParam: string, viewName: string): string {
    const designationParts = new Designation(qParam).designationParts;

    return `${designationParts[0]}.${viewName}:${designationParts[2]}.${designationParts[3]}`;
  }
}
