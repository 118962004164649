export const environment = {
  production: true,
  product: 'cbms',
  environmentName: '',
  app: {
    name: 'cBMS',
    version: ''
  },
  bxCommon: 'https://bx-common.apps.horizondev.cloud',
  profileUrl: 'https://uss.login-qa.siemens.com/userprofile',
  auth: {
    clientId: 'lYvSUmJAwrypADV8H4vlW9wLTR3mri3S',
    issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
    audience: 'https://horizonint.cloud',
    bxAudience: 'https://int.bx.siemens.com',
    siemensIdBaseUrl: 'https://login-qa.siemens.com',
    tenant: 'main-tenant-oidc',
    msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  },
  bxPlatform: {
    accountManagerAppUrl: 'https://account.apps.horizondev.cloud',
    subscriptionsApiUrl: 'https://product-int.subscription.horizonint.cloud/api',
    userMgmtApiUrl: 'https://prodint.um.user.horizonint.cloud/api',
    aodsApiUrl: 'https://product-int.aods.horizonint.cloud/api',
    alarmVerticalApiUrl: 'https://product-int.alarm.horizonint.cloud/api',
    deviceVerticalApiUrl: 'https://product-int-dm.device.horizonint.cloud/api',
    locationVerticalApiUrl: 'https://product-int.locations.horizonint.cloud',
    pointVerticalApiUrl: 'https://product-int.point.horizonint.cloud/api',
    userOrganizationsApiV1Url: 'https://prodint.user.horizonint.cloud/api/v1',
    webAppVerticalApiUrl: 'https://product-int.webapp.horizonint.cloud',
    scheduleServiceApiUrl: 'https://product-int.nb.schedule-service.horizonint.cloud'
  },
  whiteListedApis: [
    'https://uss.login-qa.siemens.com/userprofile',
    'https://product-int.subscription.horizonint.cloud/api',
    'https://prodint.um.user.horizonint.cloud/api',
    'https://product-int.aods.horizonint.cloud/api',
    'https://product-int.alarm.horizonint.cloud/api',
    'https://product-int-dm.device.horizonint.cloud/api',
    'https://product-int.locations.horizonint.cloud',
    'https://product-int.point.horizonint.cloud/api',
    'https://prodint.user.horizonint.cloud/api/v1',
    'https://product-int.webapp.horizonint.cloud',
    'https://product-int.nb.schedule-service.horizonint.cloud'
  ],
  mfaStartDate: '2024-04-23',
  mfaEndDate: '2024-10-13',
  mfaDocLink: 'https://account.apps.horizondev.cloud/assets/documents/SiemensID_MFA_Enforcement.pdf',
  mapTilerApiKey: 'AxZ8CL74rBGUVlQFoYZZ'
};
