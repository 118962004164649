<div
  #scrollContainer
  class="search-container d-flex align-items-center overflow-auto py-1 w-100 ps-2 pe-2"
>
  @for (criterion of internalSearchCriteria.internalCriteria; track $index) {
    <div
      cdkMonitorSubtreeFocus
      class="criteria pill-group"
      [class.disabled]="disabled"
      [attr.aria-disabled]="disabled"
      [class.invalid-criterion]="!validateCriterionLabel(criterion)"
      (cdkFocusChange)="criterionFocusChange(criterion, $event)"
    >
      <div
        class="pill pill-interactive criterion-label si-title-2"
        (click)="editCriterion(criterion)"
      >
        {{ criterion.label ?? criterion.config.label | translate }}
      </div>
      @if (criterion.config.operators?.length) {
        <div class="pill pill-interactive px-0 criterion-operator-section">
          <!-- criterion operator input -->
          @if (activeCriterion === criterion) {
            <input
              #operatorInput
              type="text"
              class="focus-inside operator-input"
              [siTypeahead]="criterion.config!.operators!"
              [typeaheadProcess]="false"
              [typeaheadAutoSelectIndex]="getSelectedOperatorIndex(criterion)"
              [typeaheadMinLength]="0"
              [typeaheadOptionsLimit]="0"
              [readOnly]="false"
              [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="optionsInScrollableView"
              [attr.aria-label]="searchLabel | translate"
              [attr.size]="getLongestOperatorLength(criterion)"
              [ngModel]="criterion.operator"
              (ngModelChange)="onCriterionOperatorInputChange(criterion, $event)"
              (keydown.backspace)="operatorBackspace($event, criterion)"
              (keydown.enter)="operatorEnter()"
              (typeaheadOnSelect)="operatorEnter()"
              (blur)="criteriaOperatorBlur(criterion, $event)"
            />
          } @else {
            <!-- criterion operator field -->
            <div
              class="criterion-operator-text px-2 focus-inside"
              [tabindex]="!disabled ? 0 : -1"
              (keydown.enter)="editCriterion(criterion, 'operator')"
              (click)="editCriterion(criterion, 'operator')"
            >
              {{ criterion.operator }}
            </div>
          }
        </div>
      }
      <div class="pill pill-interactive px-0 criterion-value-section">
        <!-- criterion value field -->
        @if (activeCriterion !== criterion) {
          <div
            class="criterion-value-text focus-inside px-4"
            [class.invalid-criterion]="!validateCriterionValue(criterion)"
            [tabindex]="disabled ? -1 : 0"
            (keydown.enter)="editCriterion(criterion, 'value')"
            (click)="editCriterion(criterion, 'value')"
          >
            @if (criterion.dateValue) {
              @if (!isValidDate(criterion.dateValue)) {
                <!-- DatePipe throws an error if the date is invalid, so we have to do it on our own. -->
                <!-- We may should have a better solution here. Ideally we would keep the broken string so that a user can fix it later. -->
                <!-- eslint-disable-next-line @angular-eslint/template/no-any -->
                {{ $any(criterion.dateValue).toString() }}
              } @else if (criterion.config.validationType === 'date-time') {
                {{ criterion.dateValue | date: getDateTimeFormat(criterion) }}
              } @else if (criterion.config.validationType === 'date') {
                {{ criterion.dateValue | date: shortDateFormat }}
              }
            } @else if (!!criterion.value && hasMultiSelections(criterion)) {
              @if (!!criterion.value && itemCountText) {
                {{ itemCountText | translate: { itemCount: criterion.value.length } }}
              }
              @if (!!criterion.value && !itemCountText) {
                {{ criterion.value.length }} {{ items | translate }}
              }
            } @else if (criterion.optionValue?.[0]) {
              {{ criterion.optionValue![0].label ?? criterion.optionValue![0].value | translate }}
            } @else {
              {{ criterion.value }}
            }
          </div>
        }
        <!-- criterion value input -->
        @if (
          activeCriterion === criterion &&
          criterion.config.validationType !== 'date' &&
          criterion.config.validationType !== 'date-time'
        ) {
          <input
            #valueInput
            typeaheadOptionField="translatedLabel"
            class="px-4 focus-inside"
            [type]="criterion.config.inputType"
            [step]="criterion.config.step"
            [ngModel]="criterion.valueLabel"
            [siTypeahead]="criterion.options ?? []"
            [typeaheadProcess]="criterion.config.multiSelect || !onlySelectValue"
            [typeaheadMultiSelect]="criterion.config.multiSelect"
            [typeaheadMinLength]="0"
            [typeaheadOptionsLimit]="maxCriteriaOptions"
            [typeaheadAutoSelectIndex]="
              criterion.config.multiSelect || criterion.value.length ? 0 : -1
            "
            [readOnly]="
              readonly ||
              onlySelectValue ||
              (criterion.config &&
                criterion.config.onlySelectValue &&
                !criterion.config.multiSelect)
            "
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="optionsInScrollableView"
            [attr.aria-label]="searchLabel | translate"
            (keydown)="filterKeyInputEvents($event, criterion)"
            (keydown.backspace)="criteriaValueBackspace($event, criterion)"
            (keydown.enter)="criteriaValueEnter(criterion)"
            (ngModelChange)="onCriterionValueInputChange(criterion, $event)"
            (typeaheadOnSelect)="typeaheadOnSelectValue(criterion, $event)"
            (typeaheadOnFullMatch)="typeaheadOnFullMatch(criterion, $event)"
          />
        }
        @if (
          activeCriterion === criterion &&
          (criterion.config.validationType === 'date-time' ||
            criterion.config.validationType === 'date')
        ) {
          <input
            #valueInput
            type="text"
            siDatepicker
            class="px-4 focus-inside"
            [attr.aria-label]="searchLabel | translate"
            [siDatepickerConfig]="getDatepickerConfig(criterion)"
            [ngModel]="criterion.dateValue!"
            (keydown.backspace)="criteriaValueBackspace($event, criterion)"
            (keydown.enter)="criteriaValueEnter(criterion)"
            (siDatepickerDisabledTime)="disableTime(criterion, $event)"
            (ngModelChange)="selectDate(criterion, $event)"
            (siDatepickerClose)="datepickerClose(criterion)"
          />
        }
        @if (!readonly) {
          <button
            type="button"
            class="btn btn-circle btn-xs btn-ghost element-cancel focus-inside"
            [attr.aria-label]="clearButtonLabel | translate"
            [disabled]="disabled"
            (click)="clearCriterion(criterion)"
            (mousedown)="clearCriterionMouseDown(criterion, $event)"
          >
          </button>
        }
      </div>
    </div>
  }
  <!-- criterion input -->
  <input
    #freeTextInputElement
    type="text"
    class="value-input ps-2 me-2 flex-grow-1"
    typeaheadOptionField="translatedLabel"
    [siTypeahead]="dataSource"
    [typeaheadMinLength]="readonly ? 1 : 0"
    [typeaheadOptionsLimit]="typeaheadOptionsLimit"
    [typeaheadScrollable]="true"
    [typeaheadOptionsInScrollableView]="optionsInScrollableView"
    [typeaheadAutoSelectIndex]="selectedCriteriaIndex ?? (internalSearchCriteria.value ? 0 : -1)"
    [readonly]="readonly"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [attr.aria-label]="searchLabel | translate"
    [value]="internalSearchCriteria.value"
    (focus)="freeTextFocus()"
    (input)="freeTextInput($event)"
    (keydown.backspace)="freeTextBackspace($event)"
    (keydown.enter)="submit()"
    (typeaheadOnSelect)="typeaheadOnSelectCriterion($event.option)"
  />
</div>
<!-- clear all searchCriteria -->
@if (
  (internalSearchCriteria.internalCriteria.length || internalSearchCriteria.value.length) &&
  !readonly
) {
  <div class="clear-button-container">
    <button
      type="button"
      class="btn btn-circle btn-xs btn-ghost element-cancel mx-2"
      [disabled]="disabled"
      [attr.aria-label]="clearButtonLabel | translate"
      (click)="deleteAllCriteria($event)"
    ></button>
  </div>
}

@if (!disabled) {
  <button
    type="button"
    class="search-button btn btn-circle btn-xs btn-secondary element-search focus-inside"
    [disabled]="disabled"
    [attr.aria-label]="submitButtonLabel | translate"
    (click)="submit()"
  ></button>
}
