import { AfterViewInit, Component } from '@angular/core';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { SiCollapsiblePanelComponent } from '@simpl/element-ng';
import { SiAccordionComponent } from '@simpl/element-ng/accordion';

@Component({
  selector: 'si-formly-accordion',
  templateUrl: './si-formly-accordion.component.html',
  standalone: true,
  imports: [SiCollapsiblePanelComponent, FormlyModule, SiAccordionComponent]
})
export class SiFormlyAccordionComponent extends FieldType implements AfterViewInit {
  protected panelToggle(toggle: boolean, fieldGroup: FormlyFieldConfig): void {
    if (this.field.fieldGroup) {
      const openPanelField = this.field.fieldGroup?.find(f => f.props?.opened);
      if (openPanelField?.props) {
        openPanelField.props.opened = false;
      }
    }
    if (fieldGroup.props) {
      fieldGroup.props.opened = toggle;
    }
  }

  ngAfterViewInit(): void {
    if (this.props?.expandFirstPanel !== false) {
      if (this.field.fieldGroup?.[0].props) {
        this.field.fieldGroup[0].props.opened = true;
      }
    }
  }
}
