import { Observable } from 'rxjs';

/**
 * interface containing information about the current site selection, if we are in a singleSite context and, eventually,
 * the selected site details
 */
export interface SelectedSite {
  /**
   * this property if set, will be in the form of System:ObjectId where system is the PartitionId and ObjectId will be the site/campus Id
   */
  objectId: string | undefined;
  /**
   * singleSiteActive if set to true means that we are running in a single site context
   */
  singleSiteActive: boolean | undefined;
  /**
   * the selected site name, it may be undefined if there is no selected site
   */
  siteName: string | undefined;
  /**
   * the selected site type i.e Building or Campus (a campus may contain multiple buildings)
   */
  objectType: string | undefined;
  /**
   * if the select site is a Building it will contain only the bulding Id, if the selected site is a campus it'll contain an array of building Id's
   */
  buildingsIds: string[] | undefined;
}

/**
 * Base class for the site selection service.
 * Provides the functionality to set selected site.
 *
 * @export
 * @abstract
 * @class SiteSelectionServiceBase
 */

export abstract class SiteSelectionServiceBase {
  /**
   * Set Selected Site
   *
   * @abstract
   * @param {string | undefined} objectId ObjectId of the selected site in the form of System:ObjectId
   * @param {boolean | undefined} singleSiteActive Active side mode information
   * @returns void
   *
   * @memberOf SiteSelectionServiceBase
   */
  public abstract setSite(objectId: string | undefined, singleSiteActive: boolean | undefined): void;

  /**
   * Set Current Site mode, single or multi site
   *
   * @abstract
   * @param {boolean | undefined} singleSiteActive this flag indicate if we are working in singleSite (true) or multiSite mode (false)
   * @returns void
   *
   * @memberOf SiteSelectionServiceBase
   */
  public abstract setSiteMode(singleSiteActive: boolean): void;

  /**
   * Observable of the selected site.
   * When subscribed, an object with the last selected site and info for active site mode is returned.
   *
   * @abstract
   * @type {Observable<{ objectId: string | undefined, singleSiteActive: boolean | undefined }>}
   */
  public abstract get selectedSite(): Observable<SelectedSite>;
}
