import { CdkMonitorFocus, FocusOrigin } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackgroundColorVariant, isRTL } from '@simpl/element-ng/common';
import { SiTypeaheadDirective, TypeaheadMatch, TypeaheadOption } from '@simpl/element-ng/typeahead';
import {
  SiTranslateModule,
  SiTranslateService,
  TranslatableString
} from '@simpl/element-translate-ng/translate';
import { BehaviorSubject, Observable, of, Subject, switchMap } from 'rxjs';
import { debounceTime, first, map, takeUntil, tap } from 'rxjs/operators';

import { getNamedFormat, isValid } from '../datepicker/date-time-helper';
import { SiDatepickerOverlayDirective } from '../datepicker/si-datepicker-overlay.directive';
import { SiDatepickerDirective } from '../datepicker/si-datepicker.directive';
import { DatepickerInputConfig, getDatepickerFormat } from '../datepicker/si-datepicker.model';
import {
  differenceByName,
  InternalCriterionDefinition,
  InternalCriterionValue,
  InternalSearchCriteria,
  selectOptions,
  toInternalCriteria,
  toOptionCriteria,
  TypeaheadOptionCriterion
} from './si-filtered-search-helper';
import {
  Criterion,
  CriterionDefinition,
  CriterionValue,
  DisplayedCriteriaEventArgs,
  OptionCriterion,
  OptionType,
  SearchCriteria
} from './si-filtered-search.model';

@Component({
  selector: 'si-filtered-search',
  templateUrl: './si-filtered-search.component.html',
  styleUrl: './si-filtered-search.component.scss',
  standalone: true,
  imports: [
    DatePipe,
    FormsModule,
    SiDatepickerDirective,
    SiTypeaheadDirective,
    SiTranslateModule,
    CdkMonitorFocus
  ]
})
export class SiFilteredSearchComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly criterionRegex = /(.+?):(.*)$/;

  /**
   * Output callback event that provides an object describing the
   * selected criteria and additional filter text.
   */
  @Output() readonly doSearch: EventEmitter<SearchCriteria> = new EventEmitter();

  /**
   * If this is set to `true`, the search triggers for each input (implicit search).
   * By default, the search is triggered when the user submits by pressing the
   * search button or by pressing enter.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) doSearchOnInputChange = false;
  /**
   * In addition to lazy loaded value, you can also lazy load the criteria itself
   */
  @Input() lazyCriterionProvider?: (
    typed: string,
    searchCriteria?: SearchCriteria
  ) => Observable<Criterion[] | CriterionDefinition[]>;
  /**
   * In many cases, your application defines the criteria, but the values need
   * to be loaded from a server. In this case you can provide a function that
   * returns the possible criterion options as an Observable.
   */
  @Input() lazyValueProvider?: (
    criterionName: string,
    typed: string | string[]
  ) => Observable<OptionType[]>;
  /**
   * Disable any interactivity.
   *
   * @defaultValue false
   */
  @HostBinding('class.disabled')
  @Input({ transform: booleanAttribute })
  disabled = false;
  /**
   * Do not allow changes. Search can still be triggered.
   *
   * @deprecated Use {@link disabled} instead.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) readonly = false;
  /**
   * Limit criteria to the predefined ones.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) strictCriterion = false;
  /**
   * Limit criterion options to the predefined ones. `[strictValue]`
   * enforces `[strictCriterion]` to true automatically.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) strictValue = false;
  /**
   * Limit criterion options to the predefined ones and prevent typing. `[onlySelectValue]`
   * enforces `[strictValue]` and `[strictCriterion]` to true automatically.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) onlySelectValue = false;
  /**
   * Custom debounce time for lazy loading of criteria data.
   *
   * @defaultValue 500
   */
  @Input() lazyLoadingDebounceTime = 500;

  /**
   * Custom debounce time (in mills) to delay the search emission.
   * (Default is 0 as in most cases a users manually triggers a search.
   * Recommended to increase a bit when using doSearchOnInputChange=true)
   *
   * @defaultValue 0
   */
  @Input() searchDebounceTime = 0;
  /**
   * The placeholder for input field.
   *
   * @defaultValue ''
   */
  @Input() placeholder = '';
  /**
   * @deprecated This property is unused and will be removed without a replacement.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) showIcon = false;
  /**
   * @deprecated This property is unused and will be removed without a replacement.
   * To provide translation for the new search button, use the {@link submitButtonLabel} input.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILTERED_SEARCH.SUBMIT:Apply search criteria`
   * ```
   */
  @Input() submitText = $localize`:@@SI_FILTERED_SEARCH.SUBMIT:Apply search criteria`;
  /**
   *  @deprecated Setting this property will make it harder for user to submit a search.
   *  Instead of using this property to preselect to most relevant option, sort the options by relevance.
   */
  @Input() selectedCriteriaIndex?: number;
  /**
   * Defines the number of criteria, criteria values and operators visible at once.
   *
   * @defaultValue 10
   */
  @Input() optionsInScrollableView = 10;
  /**
   * The current selected search criteria and entered search text.
   *
   * @defaultValue
   * ```
   * { criteria: [], value: '' }
   * ```
   */
  @Input() searchCriteria?: SearchCriteria = { criteria: [], value: '' };
  /**
   * Emits changes of the {@link searchCriteria} and enables a bi-directionally binding.
   */
  @Output() readonly searchCriteriaChange = new EventEmitter<SearchCriteria>();

  /**
   * Predefine criteria options.
   *
   * @defaultValue []
   */
  @Input() criteria: Criterion[] | CriterionDefinition[] = [];

  /**
   * Opt-in to search for each criterion only once.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) exclusiveCriteria = false;

  /**
   * Limit the number of possible criteria. The default is undefined so that any number of criteria can be used.
   * For example, setting the value to 1 let you only select one criterion that you need to remove before being
   * able to set another one.
   */
  @Input() maxCriteria: number | undefined = undefined;

  /**
   * Defines the maximum options within one criterion. The default is 20 and 0 means unlimited.
   *
   * @defaultValue 20
   */
  @Input() maxCriteriaOptions = 20;

  /**
   * Search input aria label, Needed by a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILTERED_SEARCH.SEARCH:Search`
   * ```
   */
  @Input() searchLabel = $localize`:@@SI_FILTERED_SEARCH.SEARCH:Search`;
  /**
   * Clear button aria label. Needed for a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILTERED_SEARCH.CLEAR:Clear`
   * ```
   */
  @Input() clearButtonLabel = $localize`:@@SI_FILTERED_SEARCH.CLEAR:Clear`;
  /**
   * The accessible label of the search button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILTERED_SEARCH.SUBMIT_BUTTON:Submit search`
   * ```
   */
  @Input() submitButtonLabel = $localize`:@@SI_FILTERED_SEARCH.SUBMIT_BUTTON:Submit search`;
  /**
   * Items count text appended to the count in case of multi-selection of values.
   * Translation key, `{{itemCount}}` in the translation will be replaced with the actual value.
   *
   * @defaultValue ''
   */
  @Input() itemCountText: TranslatableString = '';
  /**
   * Color variant to determine component background
   *
   * @defaultValue 'base-1'
   */
  @Input() colorVariant: BackgroundColorVariant = 'base-1';
  /**
   * Text or translate key for multi selection pills text.
   *
   * @deprecated Use the new input {@link itemCountText} instead.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILTERED_SEARCH.ITEMS:items`
   * ```
   */
  @Input() items = $localize`:@@SI_FILTERED_SEARCH.ITEMS:items`;
  /**
   * Disables the free text search to only use the criterion for filtering.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableFreeTextSearch = false;
  /**
   * Limit on the number of criteria/criteria value to be displayed by the typeahead
   *
   * @defaultValue 20
   */
  @Input() typeaheadOptionsLimit = 20;
  /**
   * @deprecated This property is unused and will be removed without a replacement.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILTERED_SEARCH.NO_MATCHING_CRITERIA:No matching criteria`
   * ```
   */
  @Input()
  noMatchingCriteriaText =
    $localize`:@@SI_FILTERED_SEARCH.NO_MATCHING_CRITERIA:No matching criteria`;

  /**
   * By default, the Filtered Search will treat `:` as a special character
   * to submit the current input in the freetext and immediately create a criterion.
   * Use this input to disable this behavior.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableSelectionByColonAndSemicolon = false;

  /**
   * The interceptor is called when the list of criteria is shown as soon as the user starts typing in the input field.
   * The interceptor's {@link DisplayedCriteriaEventArgs.allow} method can be used to filter the list of displayed criteria.
   *
   * **Note:** The interceptor is called as long as the {@link searchCriteria} does not exceed {@link maxCriteria}.
   * Further, the interceptor is not called when using the {@link lazyCriterionProvider}.
   *
   * @example
   * ```
   * <si-filtered-search
   *   [criteria]="[{ name: 'foo', label: 'Foo' }, { name: 'bar', label: 'Bar' }]"
   *   (interceptDisplayedCriteria)="$event.allow(
   *       $event.searchCriteria.criteria.some(s => s.name === 'foo')
   *         ? $event.criteria.filter(c => c !== 'foo')
   *         : $event.criteria
   *     )">
   * </si-filtered-search>
   * ```
   */
  @Output() readonly interceptDisplayedCriteria = new EventEmitter<DisplayedCriteriaEventArgs>();

  @HostBinding('class.dark-background')
  protected get darkBackground(): boolean {
    return this.colorVariant === 'base-0';
  }

  @ViewChild('operatorInput') private operatorInput?: ElementRef;
  @ViewChild('valueInput') private valueInput?: ElementRef<HTMLInputElement>;
  @ViewChild('freeTextInputElement', { static: true })
  private freeTextInputElement!: ElementRef<HTMLInputElement>;
  @ViewChild('scrollContainer', { read: ElementRef, static: true })
  private scrollContainer!: ElementRef;
  @ViewChild(SiDatepickerOverlayDirective)
  private datepickerOverlay?: SiDatepickerOverlayDirective;

  protected dataSource: Observable<InternalCriterionDefinition[]>;
  protected activeCriterion?: InternalCriterionValue;

  /** Internal representation of the input search criteria */
  protected internalSearchCriteria: InternalSearchCriteria = {
    internalCriteria: [],
    value: ''
  };
  /** Internal criteria model */
  protected internalCriterionDefinitions: InternalCriterionDefinition[] = [];
  protected shortDateFormat: string;
  protected isValidDate = isValid;

  private integerInvalidChar: string[] = [',', '.'];
  /** Used to trigger a renewed search */
  private typeaheadInputChange = new BehaviorSubject<string>('');
  /** Used to debounce the Search emissions */
  private searchEmitQueue = new Subject<SearchCriteria | undefined>();
  private destroySubscriptions = new Subject<boolean>();
  private elementRef = inject(ElementRef);
  private locale = inject(LOCALE_ID).toString();
  private cdRef = inject(ChangeDetectorRef);
  private translateService = inject(SiTranslateService);
  /**
   * The cache is used to control when the interceptDisplayedCriteria event needs to be called.
   * Every time a criteria gain the focus we have to reset the cache to call the interceptor.
   */
  private allowedCriteriaCache?: string[];
  /**
   * We use this when a criterion input (operator or value) is changed to edit mode.
   * When doing so, the previously focused element usually the label of that criterion is removed and the focus is transferred to the input.
   * Unlike every other browser, Chrome will first fire a focusout, secondly run a browser render cycle and then the focusin event.
   * This leads to the cdkFocusObserver firing a blur in between event which we need to skip.
   * This is what pendingFocus is used for.
   */
  private pendingFocus = false;

  constructor() {
    this.shortDateFormat = getNamedFormat(this.locale, 'shortDate');
    if (!this.shortDateFormat.includes('yyyy')) {
      this.shortDateFormat = this.shortDateFormat.replace('yy', 'yyyy');
    }

    this.dataSource = this.typeaheadInputChange.pipe(
      switchMap(value => {
        if (this.lazyCriterionProvider) {
          return this.lazyCriterionProvider(value, this.searchCriteria).pipe(
            debounceTime(this.lazyLoadingDebounceTime),
            map(result => this.getCriteriaToDisplayFromSubscription(result))
          );
        } else {
          return of(this.getFilteredTypeaheadCriteria(value));
        }
      }),
      switchMap(criteria => {
        return this.translateService.translateAsync(criteria.map(c => c.label)).pipe(
          map(translations => {
            criteria.forEach(c => (c.translatedLabel = translations[c.label] ?? c.label ?? c.name));
            return criteria;
          })
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.criteria) {
      this.initCriteria();
    }
    if (changes.searchCriteria || changes.criteria) {
      this.initSearchCriteria(!!changes.criteria);
      // Update typeahead since the criteria input can change while the free text input is focused.
      // This is necessary since the criteria are set as a result of an API call response.
      this.typeaheadInputChange.next(this.freeTextInputElement?.nativeElement.value ?? '');
    }
  }

  ngOnInit(): void {
    if (this.onlySelectValue) {
      this.strictValue = true;
    }
    if (this.strictValue) {
      this.strictCriterion = true;
    }
    if (this.strictCriterion && this.internalCriterionDefinitions.length === 0) {
      throw new Error('strict criterion mode activated without predefined criteria!');
    }

    this.searchEmitQueue
      .pipe(debounceTime(this.searchDebounceTime), takeUntil(this.destroySubscriptions))
      .subscribe(searchCriteria => this.doSearch.emit(searchCriteria));

    if (this.elementRef.nativeElement.attributes.disabled) {
      this.disabled = true;
    }
  }

  ngOnDestroy(): void {
    this.destroySubscriptions.next(true);
    this.destroySubscriptions.unsubscribe();
  }

  private initCriteria(): void {
    this.criteria = this.criteria ?? [];
    this.internalCriterionDefinitions = this.criteria.map(c => toInternalCriteria(c));
  }

  private initSearchCriteria(force: boolean): void {
    this.searchCriteria = this.searchCriteria ?? { criteria: [], value: '' };
    if (this.internalSearchCriteria.searchCriteria === this.searchCriteria && !force) {
      // we already converted the searchCriteria. No need to do it again.
      // This happens if we have two-way binding and emitted a change before.
      return;
    }
    const activeIndex = this.internalSearchCriteria.internalCriteria.indexOf(this.activeCriterion!);
    this.internalSearchCriteria = {
      searchCriteria: this.searchCriteria,
      internalCriteria:
        this.searchCriteria.criteria.map(c =>
          this.toInternalCriterionValue(
            c,
            this.internalCriterionDefinitions.find(ic => ic.name === c.name) ?? {
              name: c.name,
              label: c.label ?? c.name,
              inputType: 'text',
              step: 'any',
              translatedLabel: c.label ?? c.name
            }
          )
        ) ?? [],
      value: this.searchCriteria?.value ?? ''
    };
    this.activeCriterion = this.internalSearchCriteria.internalCriteria[activeIndex];
    this.translateActiveCriterionValue();
    this.allowedCriteriaCache = undefined;
  }

  /**
   * Deletes all currently selected criteria and effectively resets the filtered search.
   */
  deleteAllCriteria(event?: MouseEvent): void {
    if (this.isReadOnly()) {
      return;
    }
    event?.stopPropagation();

    // Reset search criteria
    this.internalSearchCriteria = {
      searchCriteria: this.searchCriteria,
      internalCriteria: [],
      value: ''
    };
    this.emitChangeEvent();
    this.allowedCriteriaCache = undefined;
    this.typeaheadInputChange.next(this.internalSearchCriteria.value);
    this.submit();
  }

  protected deleteCriterion(criterion: InternalCriterionValue): void {
    if (this.isReadOnly()) {
      return;
    }

    this.internalSearchCriteria.internalCriteria.splice(
      this.internalSearchCriteria.internalCriteria.indexOf(criterion),
      1
    );
    this.emitChangeEvent();
    this.allowedCriteriaCache = undefined;
    this.typeaheadInputChange.next(this.internalSearchCriteria.value);
  }

  protected editCriterion(
    target: InternalCriterionValue | 'next' | 'previous',
    field?: 'value' | 'operator'
  ): void {
    if (this.isReadOnly()) {
      return;
    }
    let criterion: InternalCriterionValue | undefined;
    const criteria = this.internalSearchCriteria.internalCriteria;
    const activeIndex = criteria.indexOf(this.activeCriterion!);
    if (target === 'next') {
      criterion = criteria[activeIndex + 1];
    } else if (target === 'previous') {
      if (this.activeCriterion) {
        criterion = criteria[activeIndex - 1];
      } else {
        criterion = criteria[criteria.length - 1];
      }
    } else {
      criterion = target;
    }

    if (!criterion) {
      this.freeTextInputElement.nativeElement.focus();
      return;
    }

    const config = criterion.config;
    this.activeCriterion = criterion;
    this.translateActiveCriterionValue();
    if (
      field === 'operator' ||
      (!field && (config?.operators?.length ?? 0) > 0 && target !== 'previous')
    ) {
      this.focusOperatorInput();
    } else {
      this.focusValueInput();
    }
  }

  private translateActiveCriterionValue(): void {
    if (this.activeCriterion?.optionValue?.length && !this.activeCriterion.config.multiSelect) {
      const [option] = this.activeCriterion.optionValue;
      this.activeCriterion.valueLabel = option.label
        ? this.translateService.translateSync(option.label)
        : option.value;
    }
  }

  protected getSelectedOperatorIndex(criterion: InternalCriterionValue): number {
    const config = criterion.config;
    const operator = criterion.operator;
    if (!operator || !config?.operators) {
      return -1;
    }

    return config.operators.findIndex(op => op.includes(operator));
  }

  protected getLongestOperatorLength(criterion: InternalCriterionValue): number {
    const config = criterion.config;
    if (!config?.operators) {
      return 0;
    }
    return Math.max(...config.operators.map(a => a.length));
  }

  protected submit(): void {
    if (!this.doSearchOnInputChange) {
      this.doSearch.emit(this.searchCriteria);
    }
  }

  protected typeaheadOnSelectCriterion(event: TypeaheadOption): void {
    const criterion = event as InternalCriterionDefinition;

    this.internalSearchCriteria.value = '';
    this.addCriterion(criterion.multiSelect ? [] : '', criterion);

    // The user selected a criterion so we remove the free text search value and add the criterion.
    this.allowedCriteriaCache = undefined;
    this.typeaheadInputChange.next('');
  }

  protected typeaheadOnFullMatch(criterion: InternalCriterionValue, match: TypeaheadMatch): void {
    if (criterion.config.multiSelect) {
      return;
    }
    const option = match.option as TypeaheadOptionCriterion;
    criterion.optionValue = [option];
    // Usually, we already emitted a change in onCriterionValueInputChange using the text entered by the user.
    // In case of a fullMatch, we should check if the value is different from label.
    // If it is different, we must emit another event using the value instead of the label.
    // TODO: prevent the emit of the label matching the option. This is currently not possible due to the order events.
    if (option.value !== option.translatedLabel) {
      this.emitChangeEvent();
    }
  }

  protected typeaheadOnSelectValue(criterion: InternalCriterionValue, match: TypeaheadMatch): void {
    const existingCriteria = criterion.config;
    if (!existingCriteria?.multiSelect) {
      this.typeaheadOnFullMatch(criterion, match);
      this.editCriterion('next');
    } else {
      // In multi-select scenarios the internal model value is always of type array
      const option = match.option as OptionCriterion;
      if (match.itemSelected) {
        (criterion.value as string[]).push(option.value);
        criterion.optionValue!.push(option);
      } else {
        if (typeof criterion.value !== 'string') {
          criterion.value = criterion.value?.filter(elem => elem !== option.value);
        }
        criterion.optionValue = criterion.optionValue!.filter(
          anyOption => anyOption.value !== option.value
        );
      }
      criterion.selectionChange!.next(criterion.value as string[]);
      // Since in case of multiselect the model will not change, we need to explicitly call the output event
      this.emitChangeEvent();
    }
  }

  protected validateCriterionValue(criterion: InternalCriterionValue): boolean {
    const config = criterion.config;
    const validMinMax = this.validateDateMinMaxCriteria(criterion);
    if (!config || (!this.strictValue && !config.strictValue && !config.onlySelectValue)) {
      return validMinMax;
    }

    // TODO: this never worked with lazy options. We should fix that.
    // TODO: checking if options are empty is also questionable. Should be changed v47.
    return config.options?.length && criterion.optionValue?.length
      ? validMinMax
      : !config.options?.length && !!criterion.value;
  }

  protected validateCriterionLabel(criterion: InternalCriterionValue): boolean {
    if (!this.strictCriterion) {
      return true;
    }
    return this.internalCriterionDefinitions.includes(criterion.config);
  }

  protected operatorEnter(): void {
    this.focusValueInput();
  }

  /**
   * Converts the internally used data model to the external model.
   * In case options for Criterion is Option[] map to the value from the label.
   * @param searchCriteria - searchCriteria to be converted.
   */
  private convertToExternalModel(searchCriteria: InternalSearchCriteria): SearchCriteria {
    const correctedCriteria: SearchCriteria = {
      value: searchCriteria.value,
      criteria: searchCriteria.internalCriteria.map(criterion => {
        return {
          value: this.getValue(criterion),
          name: criterion.config.name,
          ...(criterion.operator ? { operator: criterion.operator } : {}),
          ...(criterion.dateValue ? { dateValue: criterion.dateValue } : {}),
          label: criterion.config.translatedLabel ?? criterion.config.label
        };
      })
    };
    if (this.disableFreeTextSearch) {
      correctedCriteria.value = '';
    }
    return correctedCriteria;
  }

  private getValue(sc: InternalCriterionValue): string | string[] | undefined {
    if (sc.config.validationType === 'date') {
      return this.getISODateString(sc.dateValue, 'date');
    } else if (sc.config.validationType === 'date-time') {
      if (sc.disabledTime) {
        return this.getISODateString(sc.dateValue, 'date');
      } else {
        return this.getISODateString(sc.dateValue, 'date-time');
      }
    }

    if (sc.optionValue) {
      if (sc.config.multiSelect) {
        // TODO: v47 remove option.label
        return sc.optionValue.map(option => option.value ?? option.label);
      } else if (sc.optionValue.length > 0) {
        // TODO: v47 remove sc.optionValue[0].label
        return sc.optionValue[0].value ?? sc.optionValue[0].label;
      }
    }

    return sc.value;
  }

  private getISODateString(date: Date | undefined, format: 'date' | 'date-time'): string {
    if (!isValid(date)) {
      return '';
    }

    switch (format) {
      case 'date':
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      case 'date-time':
        return date.toISOString();
    }
  }

  private addCriterion(value: string | string[], config: InternalCriterionDefinition): void {
    const internalCriterion = this.toInternalCriterionValue({ value, name: config.name }, config);
    this.internalSearchCriteria.internalCriteria.push(internalCriterion);
    this.editCriterion(internalCriterion);
    this.emitChangeEvent();
  }

  private focusOperatorInput(): void {
    this.pendingFocus = true;
    setTimeout(() => {
      this.pendingFocus = false;
      return this.operatorInput?.nativeElement.focus();
    });
  }

  private focusValueInput(): void {
    this.pendingFocus = true;
    setTimeout(() => {
      this.pendingFocus = false;
      return this.valueInput?.nativeElement.focus();
    });
  }

  protected hasMultiSelections(criterion: InternalCriterionValue): boolean {
    return !!(
      criterion.config?.multiSelect &&
      Array.isArray(criterion.value) &&
      criterion.value.length > 1
    );
  }

  /**
   * Get criteria list to be shown in typeahead.
   * @param token - input field value.
   * @returns list of criteria to be shown in typeahead.
   */
  private getFilteredTypeaheadCriteria(token: string): InternalCriterionDefinition[] {
    if (
      this.maxCriteria === undefined ||
      this.internalSearchCriteria.internalCriteria.length < this.maxCriteria
    ) {
      const allowedCriteria = !this.exclusiveCriteria
        ? this.internalCriterionDefinitions
        : differenceByName(
            this.internalCriterionDefinitions,
            this.internalSearchCriteria.internalCriteria
          );

      if (allowedCriteria.length > 0 && !this.allowedCriteriaCache) {
        // Call interceptor to allow applications to customize the list of available criteria
        const available = allowedCriteria.map(c => c.name);
        // Ensure that all entries are allowed in case the consumer doesn't use the allow callback
        this.allowedCriteriaCache = available;
        this.interceptDisplayedCriteria.emit({
          criteria: available,
          searchCriteria: this.convertToExternalModel(this.internalSearchCriteria),
          allow: criteriaNamesToDisplay => {
            if (criteriaNamesToDisplay) {
              this.allowedCriteriaCache = criteriaNamesToDisplay;
            }
          }
        });
      }

      return allowedCriteria.filter(c => this.allowedCriteriaCache?.includes(c.name));
    } else {
      return [];
    }
  }

  private getCriteriaToDisplayFromSubscription(
    result: CriterionDefinition[]
  ): InternalCriterionDefinition[] {
    this.criteria = result;
    this.internalCriterionDefinitions = this.criteria.map(c => toInternalCriteria(c));
    // It's necessary to update the criteria configuration since consuming applications may change the options.
    // A common case are criteria which depend on each other like Country, Site and Building. If the user change
    // the site the building options might become invalid.
    this.internalSearchCriteria.internalCriteria.forEach(criterionValue => {
      const newConfig = this.internalCriterionDefinitions.find(
        ic => ic.name === criterionValue.config.name
      );
      if (newConfig && criterionValue.config !== newConfig) {
        // We have to keep the same reference. Otherwise, CD gets confused.
        Object.assign(
          criterionValue,
          this.toInternalCriterionValue(
            { ...criterionValue, name: newConfig.name, label: newConfig.label },
            newConfig
          )
        );
      }
    });
    if (
      this.maxCriteria === undefined ||
      this.internalSearchCriteria.internalCriteria.length < this.maxCriteria
    ) {
      return !this.exclusiveCriteria
        ? this.internalCriterionDefinitions
        : differenceByName(
            this.internalCriterionDefinitions,
            this.internalSearchCriteria.internalCriteria
          );
    }

    return [];
  }

  protected getDateTimeFormat(searchCriterion: InternalCriterionValue): string {
    // The information if the time is currently disabled is only present in the
    // current search criterion instance and not in the generic configuration.
    // So we need to merge the initial config with the current instance config.
    return getDatepickerFormat(this.locale, {
      ...searchCriterion.config?.datepickerConfig,
      disabledTime: searchCriterion.disabledTime
    });
  }

  protected disableTime(criterion: InternalCriterionValue, disabledTime: boolean): void {
    criterion.disabledTime = disabledTime;
    this.emitChangeEvent();
  }

  protected getDatepickerConfig(searchCriterion: InternalCriterionValue): DatepickerInputConfig {
    return {
      ...searchCriterion.config?.datepickerConfig,
      disabledTime: searchCriterion.disabledTime
    };
  }

  protected filterKeyInputEvents(event: KeyboardEvent, criterion: InternalCriterionValue): void {
    const config = criterion.config;
    if (
      config &&
      config.validationType === 'integer' &&
      this.integerInvalidChar.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  protected selectDate(searchCriterion: InternalCriterionValue, date?: Date): void {
    // In case the user type an illegal date into the date input,
    // our directive emits a new undefined value and keeps
    if (!date && searchCriterion.dateValue) {
      date = new Date(searchCriterion.dateValue);
    }
    searchCriterion.dateValue = date;
    this.emitChangeEvent();
  }

  protected validateDateMinMaxCriteria(criterion: InternalCriterionValue): boolean {
    const dateConfig = this.getDatepickerConfig(criterion);
    const minDate = dateConfig?.minDate ?? false;
    const maxDate = dateConfig?.maxDate ?? false;
    const dateValue = criterion?.dateValue ?? false;
    return (
      (!minDate || (minDate && dateValue && dateValue >= minDate)) &&
      (!maxDate || (maxDate && dateValue && dateValue <= maxDate))
    );
  }

  protected criteriaValueEnter(criterion: InternalCriterionValue): void {
    if (!criterion.config?.multiSelect && (criterion.value || criterion.dateValue)) {
      this.editCriterion('next');
    }
  }

  protected criteriaValueBackspace(event: Event, criterion: InternalCriterionValue): void {
    if (!(event.target as HTMLInputElement).value) {
      if (criterion.operator) {
        this.focusOperatorInput();
      } else {
        this.editCriterion('next');
        this.deleteCriterion(criterion);
      }
    }
  }

  protected onCriterionValueInputChange(
    criterion: InternalCriterionValue,
    newValue: string | string[]
  ): void {
    const config = criterion.config;
    if (!config?.multiSelect && typeof newValue === 'string') {
      const match = newValue.match(/(.+?);(.*)$/);
      if (!this.disableSelectionByColonAndSemicolon && match) {
        criterion.value = match[1];
        this.internalSearchCriteria.value = match[2];
        this.freeTextInputElement.nativeElement.focus();
      } else {
        criterion.value = newValue;
      }
      criterion.optionValue = undefined;
      criterion.value = this.getValue(criterion) as string;
      criterion.valueLabel = newValue;
      criterion.inputChange?.next(criterion.value);
      this.emitChangeEvent();
    }
  }

  protected operatorBackspace(event: Event, criterion: InternalCriterionValue): void {
    if (!(event.target as HTMLInputElement).value) {
      this.deleteCriterion(criterion);
      this.editCriterion('previous');
    }
  }

  protected onCriterionOperatorInputChange(
    criterion: InternalCriterionValue,
    newOperator: string
  ): void {
    criterion.operator = newOperator;
    this.emitChangeEvent();
  }

  protected freeTextFocus(): void {
    this.activeCriterion = undefined;
    // Ensure that the free text input is fully visible in the scroll container
    const scrollDirection = isRTL() ? -1 : 1;
    const position = scrollDirection * this.scrollContainer.nativeElement.scrollWidth;
    this.scrollContainer.nativeElement.scrollLeft = position;
    this.typeaheadInputChange.next(this.freeTextInputElement.nativeElement.value);
  }

  protected freeTextBackspace(event: Event): void {
    if (!(event.target as HTMLInputElement).value) {
      // edit last criterion if a user presses backspace in empty search input.
      this.editCriterion('previous');
    }
  }

  protected freeTextInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    const match = value.match(SiFilteredSearchComponent.criterionRegex);
    if (!this.disableSelectionByColonAndSemicolon && !this.onlySelectValue && match) {
      const criterionName = match[1];
      if (this.internalSearchCriteria.value === '') {
        // The value was empty before, so we must make angular detect a change here.
        // Otherwise, the entire value which was pasted will remain in the input.
        // This happens if the user pasts something like: 'key:value'
        this.internalSearchCriteria.value = value;
        this.cdRef.detectChanges();
      }
      this.internalSearchCriteria.value = '';
      this.allowedCriteriaCache = undefined;

      const nameLowerCase = criterionName.toLocaleLowerCase();
      const criterion = this.internalCriterionDefinitions.find(
        ic => ic.translatedLabel.toLocaleLowerCase() === nameLowerCase
      ) ?? {
        name: criterionName,
        label: criterionName,
        inputType: 'text',
        step: 'any',
        translatedLabel: criterionName
      };

      this.typeaheadInputChange.next('');
      this.addCriterion(match[2], criterion);
    } else {
      this.internalSearchCriteria.value = value;

      if (!this.disableFreeTextSearch) {
        this.emitChangeEvent();
      }

      this.typeaheadInputChange.next(value);
    }
  }

  private isReadOnly = (): boolean => this.readonly || this.disabled;

  /** Prevents blurring the active criterion */
  protected clearCriterionMouseDown(criterion: InternalCriterionValue, event: MouseEvent): void {
    if (this.activeCriterion === criterion) {
      event.preventDefault();
    }
  }

  protected clearCriterion(criterion: InternalCriterionValue): void {
    if (criterion !== this.activeCriterion || !criterion.value.length) {
      this.deleteCriterion(criterion);
      this.submit();
      return;
    }

    if (
      criterion.config?.validationType === 'date' ||
      criterion.config?.validationType === 'date-time'
    ) {
      criterion.dateValue = undefined;
      criterion.value = '';
    } else {
      if (criterion.config?.multiSelect) {
        this.valueInput!.nativeElement.value = '';
        criterion.value = [];
        criterion.selectionChange!.next(criterion.value);
      } else {
        criterion.value = '';
        criterion.valueLabel = '';
      }
      criterion.optionValue = [];
    }
    this.emitChangeEvent();
  }

  private updateAndEmitSearchCriteria(internalSearchCriteria: InternalSearchCriteria): void {
    this.searchCriteria = this.convertToExternalModel(internalSearchCriteria);
    this.internalSearchCriteria.searchCriteria = this.searchCriteria;
    this.searchCriteriaChange.next(this.searchCriteria);
  }

  protected criterionFocusChange(
    criterion: InternalCriterionValue,
    focusOrigin: FocusOrigin
  ): void {
    criterion.focusState = focusOrigin;
    if (
      !this.pendingFocus &&
      this.activeCriterion === criterion &&
      !focusOrigin &&
      !this.datepickerOverlay?.isShown()
    ) {
      this.activeCriterion = undefined;
    }
  }

  protected criteriaOperatorBlur(criterion: InternalCriterionValue, event: Event): void {
    const newValue = (event.target as HTMLInputElement).value;
    const config = criterion.config;
    if (config?.operators && !config.operators.includes(newValue)) {
      criterion.operator = config.operators.includes('=') ? '=' : config.operators[0];
      this.emitChangeEvent();
    }
  }

  private toInternalCriterionValue(
    crit: CriterionValue,
    critConfig: InternalCriterionDefinition
  ): InternalCriterionValue {
    const defaultValue = critConfig?.multiSelect ? [] : '';
    const result: InternalCriterionValue = {
      ...crit,
      value: crit.value ?? defaultValue,
      config: critConfig,
      label: critConfig.label === crit.label ? undefined : crit.label
    };

    // Fix input, in case the user provided the value as string for the multi-select use case.
    if (critConfig?.multiSelect && typeof result.value === 'string') {
      result.value = result.value !== '' ? [result.value] : [];
    }

    // Resolve value label from options to set the correct value in the input field.
    if (typeof result.value === 'string') {
      const matchingOption = critConfig?.options?.find(o =>
        typeof o !== 'string' ? o.value === result.value : false
      ) as OptionCriterion | null;
      result.valueLabel = matchingOption?.label ?? result.value;
    }

    if (critConfig?.validationType === 'date' || critConfig?.validationType === 'date-time') {
      result.dateValue = crit.value ? new Date(crit.value.toString()) : new Date();
      result.value ||= this.getISODateString(
        result.dateValue,
        result.config.validationType === 'date' || result.disabledTime ? 'date' : 'date-time'
      );
    } else {
      const internalOptions = this.buildOptionsForCriterion(result);

      result.options = internalOptions?.pipe(
        switchMap(options => {
          const keys: string[] = options.map(option => option.label!).filter(label => !!label);
          return this.translateService.translateAsync(keys).pipe(
            map(translations =>
              options.map(option => ({
                ...option,
                translatedLabel: translations[option.label!] ?? option.label ?? option.value
              }))
            )
          );
        })
      );

      // update selection
      if (critConfig.multiSelect && result.options) {
        result.selectionChange = new BehaviorSubject<string[]>(result.value as string[]);
        result.options = result.options.pipe(
          switchMap(options =>
            result.selectionChange!.pipe(
              tap(value => selectOptions(options, value)),
              map(() => options)
            )
          )
        );
      }

      if (!result.value.length) {
        result.optionValue = critConfig.multiSelect ? [] : undefined;
      } else {
        // resolve options for initial values
        result.options!.pipe(first()).subscribe(options => {
          if (critConfig.multiSelect) {
            result.optionValue = options.filter(
              option =>
                result.value.includes(option.value) ||
                // TODO: remove this. I don't know why, but it seems like previously FS accepted labels as well
                result.value.includes(option.translatedLabel)
            );
          } else {
            result.optionValue = options.filter(
              option =>
                option.value === result.value ||
                // TODO: remove this. I don't know why, but it seems like previously FS accepted labels as well
                option.translatedLabel === result.value
            );
          }
        });
      }
    }

    return result;
  }

  private buildOptionsForCriterion(
    result: InternalCriterionValue
  ): Observable<OptionCriterion[]> | undefined {
    if (this.lazyValueProvider) {
      result.inputChange = new BehaviorSubject(
        result.config?.multiSelect ? '' : ((result.value ?? '') as string)
      );
      return result.inputChange.pipe(
        debounceTime(this.searchDebounceTime),
        takeUntil(this.destroySubscriptions),
        switchMap(value => {
          return this.lazyValueProvider!(
            result.config.name,
            // TODO: fix lazy loading for multi-select. Seems to be not needed, but it should work.
            result.config?.multiSelect ? '' : (value ?? '')
          ).pipe(
            map(options => toOptionCriteria(options)),
            tap(
              options => ((result.config ?? ({} as InternalCriterionDefinition)).options = options)
            )
          );
        })
      );
    } else if (result.config) {
      return of(toOptionCriteria(result.config.options));
    }

    return undefined;
  }

  datepickerClose(criterion: InternalCriterionValue): void {
    if (criterion === this.activeCriterion && !criterion.focusState) {
      // close by outside click
      this.activeCriterion = undefined;
    }
  }

  private emitChangeEvent(): void {
    this.updateAndEmitSearchCriteria(this.internalSearchCriteria);
    if (this.doSearchOnInputChange) {
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }
}
