import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@gms-flex/core';
import { GmsEasyNavigationBarServiceBase } from '@gms-flex/services';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { TreeItem } from '@simpl/element-ng';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { ObjectManagerConfig, ObjectManagerViewConfig } from '../../object-manager';
import { ObjectManagerCoreServiceBase, SelectionRequest } from '../../object-manager-core';
import { ObjectManagerViewModelIfc } from '../../object-manager-core/view-model/object-manager-vm';
import { ObjectViewIfc } from '../../object-manager-core/view-model/object-view';
import { AggregateViewDelegate } from "../../object-manager-core/view-model/object-view-aggregate";
import { TreeItemlLifeCycleState } from '../../object-manager-core/view-model/tree-item-data';
import { ActiveMenuItemService } from '../services/active-menu-item.service';
import { GmsEasyNavigationResizeService } from '../services/gms-easy-navigation-resize.service';
import { SelectionChangedService } from '../services/selection-changed.service';

@Component({
  selector: 'gms-easy-navigation-bar-common',
  template: `
    <div #navigationBarCommonContainer class="expand-placeholder status-bar-wrapper rounded-2 elevation-1"
         style="position: relative;">
      <gms-easy-navigation-bar-top-menu *ngIf="gmsEasyNavigationBarService?.SelectedViewActivated" />
    </div>
  `,
  styleUrl: './gms-easy-navigation-bar-common.component.scss'
})

export class GmsEasyNavigationBarCommonComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public clientId: string;
  @Input() public hldlEasyNavigationBarViewName: string;
  @Input() public selectionChanged: Subject<TreeItem>;
  @Input() public config: ObjectManagerConfig;
  @ViewChild('navigationBarCommonContainer', { static: false }) public navigationBarCommonContainer: ElementRef;

  public selectedViewActivated = false;
  public objectDescription = '';

  constructor(public objectManagerCoreService: ObjectManagerCoreServiceBase,
    private readonly cdRef: ChangeDetectorRef,
    private readonly ds: DomSanitizer,
    private readonly selectionContextService: SelectionChangedService,
    private readonly selectedTreeItemService: ActiveMenuItemService,
    private readonly gmsEasyNavigationResizeService: GmsEasyNavigationResizeService,
    protected readonly gmsEasyNavigationBarService: GmsEasyNavigationBarServiceBase,
    private readonly stateService: StateService,
    public ngZone: NgZone) {
  }

  private postActiveViewFlag: boolean;
  private selectionRequestInd: Observable<SelectionRequest>;
  private selectionSubscription: Subscription;
  private reattachInd: Observable<void>;
  private reattachSubscription: Subscription;
  private destroyInd: Subject<void>;
  private readonly eventListId = "event-list";
  private selectedFrameId = "";
  private selectedFrameSub: Subscription;

  public ngOnInit(): void {
    let id = this.clientId;
    if (isNullOrUndefined(id)) {
      id = '_EasyNavigationBar';
    }

    this.gmsEasyNavigationBarService.HldlEasyNavigationBarViewName = this.hldlEasyNavigationBarViewName;
    this.gmsEasyNavigationBarService.snapinVm = this.objectManagerCoreService.registerViewModel(id, this.ngZone);
    if (!this.gmsEasyNavigationBarService.snapinVm.isInitialized) {
      // Configure view-model prior to first use
      this.gmsEasyNavigationBarService.snapinVm.injectItemTemplateTranslator(this);
      const vconfig: ObjectManagerViewConfig = this.config ? this.config.viewConfig : undefined;
      const initsel: string = this.config ? this.config.initialSelection : undefined;
      this.gmsEasyNavigationBarService.snapinVm.setViewConfig(vconfig, initsel);
    }

    this.gmsEasyNavigationBarService.snapinVm.enableSelectedViewReporting(this.postActiveViewFlag);

    // Re-set the selection request indicator to establish a subscription now that the VM is created
    this.setSelectionRequestIndicator(this.selectionRequestInd);
    this.setReattachIndicator(this.reattachInd);

    this.objectDescription = '';
    if (this.config?.defaultSaveObjectDesc) {
      this.objectDescription = this.config.defaultSaveObjectDesc;
    }

    // Activate the view-model
    this.gmsEasyNavigationBarService.snapinVm.activate(this.ds).subscribe();

    this.selectionContextService.onSelectionChanged.subscribe((treeItem: TreeItem): void => {
      this.selectionChanged.next(treeItem);
      this.selectedTreeItemService.clearActiveMenuItem();
    });

    this.selectedFrameSub = this.stateService.currentState.activeWorkAreaId.subscribe(selectedFrameId => {
      this.selectedFrameId = selectedFrameId;
      if (selectedFrameId === this.eventListId) {
        this.selectedViewActivated = false;
      }
    });
  }

  public onEasyNavigationBarNameUpdate(view: string): void {
    this.hldlEasyNavigationBarViewName = view;
  }

  public ngAfterViewInit(): void {
    this.gmsEasyNavigationResizeService.initialize(this.navigationBarCommonContainer);
  }

  // NOTE: The consumer of this component should bind a ReplaySubject to this input property to ensure
  //   selection requests generated prior to the binding being updated are received and processed by
  //   this component.
  @Input() public set updateSelection(ind: Observable<SelectionRequest>) {
    this.setSelectionRequestIndicator(ind);
  }

  @Input() public set reattachIndication(ind: Observable<void>) {
    this.setReattachIndicator(ind);
  }

  public ngOnDestroy(): void {
    this.gmsEasyNavigationBarService.snapinVm.deactivate();
    this.updateSelection = undefined;
    this.reattachIndication = undefined;
    this.destroyInd?.next();
    this.destroyInd?.complete();
    this.destroyInd = undefined;
    this.selectedFrameSub.unsubscribe();
  }

  // NOTE: Necessary because the view is passed as an argument
  public getItemTemplateName(state: TreeItemlLifeCycleState): string {
    return 'Template';
  }

  @Input() public set postActiveView(flag: boolean) {
    this.postActiveViewFlag = flag;
    if (this.gmsEasyNavigationBarService.snapinVm) {
      this.gmsEasyNavigationBarService.snapinVm.enableSelectedViewReporting(this.postActiveViewFlag);
    }
  }

  private setSelectionRequestIndicator(ind: Observable<SelectionRequest>): void {
    if (this.selectionRequestInd === ind && this.selectionSubscription) {
      // This indicator is already set AND has been subscribed to
      return; // no change
    }
    // Unsubscribe from previously held selection-request indicator, if necessary, before assigning new ind
    if (this.selectionSubscription && !this.selectionSubscription.closed) {
      this.selectionSubscription.unsubscribe();
    }
    this.selectionRequestInd = ind;
    this.selectionSubscription = undefined;
    // Subscribe to indicator.
    // NOTE: if the VM has not yet been created (pre-initialization), skip subscribing; this method
    //  will be called explicity during initialization and after the VM has been created to re-set
    //  the indicator and establish the subscription.
    if (this.selectionRequestInd && this.gmsEasyNavigationBarService.snapinVm) {
      this.selectionSubscription = this.selectionRequestInd
        .subscribe(
          sel => {
            if (sel) {
              this.gmsEasyNavigationBarService.snapinVm.setSelectedItem(sel);
            }
          });
    }
  }

  private setReattachIndicator(ind: Observable<void>): void {
    if (this.reattachInd === ind && this.reattachSubscription) {
      // This indicator is already set AND has been subscribed to
      return; // no change
    }
    // Unsubscribe from previously held indicator, if necessary, before assigning new ind
    if (this.reattachSubscription && !this.reattachSubscription.closed) {
      this.reattachSubscription.unsubscribe();
    }
    this.reattachInd = ind;
    this.reattachSubscription = undefined;
    // Subscribe to indicator.
    // NOTE: if the VM has not yet been created (pre-initialization), skip subscribing; this method
    //  will be called explicity during initialization and after the VM has been created to re-set
    //  the indicator and establish the subscription.
    if (this.reattachInd && this.gmsEasyNavigationBarService.snapinVm) {
      this.reattachSubscription = this.reattachInd
        .subscribe(
          () => {
            this.gmsEasyNavigationBarService.snapinVm.views.forEach(v => v.processReattach());
            this.gmsEasyNavigationBarService.snapinVm.filter.processReattach();
          });
    }
  }
}
