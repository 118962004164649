import { Injectable } from '@angular/core';
import { DpIdentifier, SelectedSite, SiteSelectionServiceBase, TraceModules } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, first, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { LocationService } from '../../bx-services/location/location.service';

/**
 * Implementation for site selection handling.
 *
 * @export
 * @class SiteSelectionService
 */
@Injectable({
  providedIn: 'root'
})
export class SiteSelectionBxSubstituteService extends SiteSelectionServiceBase {

  private readonly _selectedSite: BehaviorSubject<SelectedSite>
    = new BehaviorSubject<SelectedSite>({
      objectId: undefined,
      singleSiteActive: undefined,
      siteName: undefined,
      objectType: undefined,
      buildingsIds: undefined
    });

  public constructor(
    private readonly locationService: LocationService,
    private readonly traceService: TraceService) {
    super();
  }

  /**
   * this method should be used to set the current selectedSite and change the current mode: singleSite or multiSite
   * @param objectId ObjectId of the selected site in the form of System:ObjectId where system is the PartitionId
   * @param singleSiteActive this flag indicate if we are working in singleSite or multiSite mode
   */
  public setSite(objectId: string | undefined, singleSiteActive: boolean | undefined): void {
    if (objectId == undefined) {
      localStorage.setItem('selectedObjectId', "");
      localStorage.setItem('singleSiteActive', singleSiteActive ? "true" : "false");
      this._selectedSite.next({
        objectId: objectId,
        singleSiteActive: singleSiteActive,
        siteName: undefined,
        objectType: undefined,
        buildingsIds: undefined
      });

      return;
    }

    const siteDpIdentifier = new DpIdentifier(objectId);

    if (isNullOrUndefined(siteDpIdentifier) ||
    !siteDpIdentifier.isSystemNameSet ||
    isNullOrUndefined(siteDpIdentifier.objectIdWoSystem)) {
      this.traceService.error(TraceModules.systems, 'setSite malformed objectId');
      return
    }

    localStorage.setItem('selectedObjectId', objectId);
    localStorage.setItem('singleSiteActive', singleSiteActive ? "true" : "false");

    const partitionId = siteDpIdentifier.systemName;
    const siteId = siteDpIdentifier.objectIdWoSystem;

    const typeAndBuildings = this.locationService.isLocationACampus(partitionId, siteId).pipe(switchMap(isACampus => {
      if (isACampus) {
        return this.locationService.getBuildingsIdsOfACampus(partitionId, siteId).pipe(map(buildingsIds => {
          return {
            isACampus: isACampus,
            buildingsIds: buildingsIds
          }
        }));
      } else {
        return of([siteId]).pipe(map(buildingsIds => {
          return {
            isACampus: isACampus,
            buildingsIds: buildingsIds
          }
        }));
      }
    }));

    forkJoin(
      [this.locationService.getSiteName(partitionId, siteId), typeAndBuildings])
      .pipe(first()).subscribe(([siteName, objectInformations]) => {
        this._selectedSite.next({
          objectId: objectId,
          singleSiteActive: singleSiteActive,
          siteName: siteName,
          objectType: objectInformations.isACampus ? 'campus' : 'building',
          buildingsIds: objectInformations.buildingsIds
        });
      });
  }

  /**
   * this method should be used to set the current mode: single site or multi site, without changing the selected site
   * @param singleSiteActive this flag indicate if we are working in singleSite (true) or multiSite mode (false)
   */
  public setSiteMode(singleSiteActive: boolean): void {
    localStorage.setItem('singleSiteActive', singleSiteActive ? "true" : "false");

    const newSelectedSite = { ...this._selectedSite.getValue(), singleSiteActive: singleSiteActive };

    this._selectedSite.next(newSelectedSite);
  }

  /**
   * the returned value if set, will be an object, the objectId will be in the form of System:ObjectId where system is the PartitionId
   * example: fec4ddd3-fe27-4477-b8ba-e12d3c971abb:4451b372-c5df-480d-be89-04aed687ed97
   */
  public get selectedSite(): Observable<SelectedSite> {
    return this._selectedSite.asObservable();
  }
}
