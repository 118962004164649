/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */

/* eslint-disable */
import { Injectable } from '@angular/core';
import { catchError, map, type Observable } from 'rxjs';
import { calendarData } from './models/calendarBACnetDescription';
import type { calendarBACnetDescriptionUpdatable } from './models/calendarBACnetDescriptionUpdatable';
import { type calendarCollection } from './models/calendarCollectionElement';
import type { calendarPatchRequest } from './models/calendarPatchRequest';
import type { calendarPostRequest } from './models/calendarPostRequest';
import type { collectionElementId } from './models/collectionElementId';
import type { elementStatus } from './models/elementStatus';
import type { entityId } from './models/entityId';
import type { equipmentCalendarsUpdated } from './models/equipmentCalendarsUpdated';
import type { equipmentSchedulesUpdated } from './models/equipmentSchedulesUpdated';
import type { ontology } from './models/ontology';
import type { partitionId } from './models/partitionId';
import { type scheduleData } from './models/scheduleBACnetDescription';
import type { scheduleBACnetDescriptionUpdatable } from './models/scheduleBACnetDescriptionUpdatable';
import { type scheduleCollection } from './models/scheduleCollectionElement';
import type { schedulePatchRequest } from './models/schedulePatchRequest';
import type { schedulePostRequest } from './models/schedulePostRequest';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpUtilityService } from '../shared/http-utility.service';

const scheduleUrl = `${environment.bxPlatform.scheduleServiceApiUrl}`;

@Injectable({
    providedIn: 'root'
})
export class ScheduleProxyService {
    constructor(
        public readonly httpRequest: BaseHttpRequest,
        private readonly httpUtilityService: HttpUtilityService,
        private readonly httpClient: HttpClient
    ) { }

    /**
     * Get list of schedules
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param status Status of the schedule (e.g. Active/Inactive) to filter the schedules.
     * @returns scheduleCollectionElement Schedule list retrieved successfully.
     *
     * For cases where status is `active` only active schedules are retrived and for status `inactive` only inactive schedules are retrived. If status is not provided all schedules are retrived.
     *
     * @throws ApiError
     */

    public getSchedules(partitionId: string, entityId: entityId, status?: elementStatus): Observable<scheduleCollection> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/entities/${entityId}/schedule-assignments`;

        let params: HttpParams = new HttpParams();
        if (status) {
            params = params.set('status', status);
        }

        return this.httpClient.get<scheduleCollection>(url, { headers, params, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getSchedules()')));
    }

    // public getSchedules(
    //     partitionId: partitionId,
    //     entityId: entityId,
    //     status?: elementStatus,
    // ): Observable<Array<scheduleCollectionElement>> {
    //     return this.httpRequest.request({
    //         method: 'GET',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/entities/{entityId}/schedules',
    //         path: {
    //             'partitionId': partitionId,
    //             'entityId': entityId,
    //         },
    //         query: {
    //             'status': status,
    //         },
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }

    /**
     * Assign schedule to an entity (i.e., an equipment)
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param requestBody
     * @param status Status of the schedule (e.g. Active/Inactive) to filter the schedules.
     * @returns equipmentSchedulesUpdated Associated domain event of the desire to assign the schedule to the equipment has been raised.
     *
     * It does not mean that the schedule has been assigned to the schedule. The desire will be handled by the Integration layer asynchonously,
     * which updates the internal database.
     *
     * @throws ApiError
     */
    public postSchedules(
        partitionId: string,
        scheduleId: string,
        requestBody: schedulePostRequest,
        status?: elementStatus,): Observable<equipmentSchedulesUpdated> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/schedules/${scheduleId}`;

        let params: HttpParams = new HttpParams();
        if (status) {
            params = params.set('status', status);
        }

        return this.httpClient.post(url, requestBody, { headers, params, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'postSchedules()')));
    }

    // public postSchedules(
    //     partitionId: partitionId,
    //     scheduleId: string,
    //     requestBody: schedulePostRequest,
    //     status?: elementStatus,
    // ): Observable<equipmentSchedulesUpdated> {
    //     return this.httpRequest.request({
    //         method: 'POST',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/schedules/{scheduleId}',
    //         path: {
    //             'partitionId': partitionId,
    //             'scheduleId': scheduleId
    //         },
    //         query: {
    //             'status': status,
    //         },
    //         body: requestBody,
    //         mediaType: 'application/json',
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }

    /**
     * Get single schedule
     * Get schedule by schedule collection element id belonging to a specific partition and entity (Device/Equipment).
     *
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param scheduleCollectionElementId Schedule Collection Id
     * @param ontology Type of the schedule (e.g. BACnet) to get schedule details.
     * When ontology is not given only the basic schedule information such as name, description and status will be returned.
     *
     * @returns any Schedule retrieved successfully.
     *
     * For cases where ontology is specified schedule details are fetched else only base schedule is fetched.
     *
     * @throws ApiError
     */

    public getSchedule(
        partitionId: string,
        scheduleId: string): Observable<scheduleData> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/schedules/${scheduleId}`;

        return this.httpClient.get<scheduleData>(url, { headers, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getSchedule()')));
    }

    // public getSchedule(
    //     partitionId: partitionId,
    //     scheduleId: collectionElementId
    // ): Observable<scheduleBACnetDescription> {
    //     return this.httpRequest.request({
    //         method: 'GET',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/schedules/{scheduleId}',
    //         path: {
    //             'partitionId': partitionId,
    //             'scheduleId': scheduleId,
    //         },
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }

    /**
     * Update schedule
     * Update schedule status.
     *
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param scheduleCollectionElementId Schedule Collection Id
     * @param requestBody
     * @param ontology Type of the schedule (e.g. BACnet) to get schedule details.
     * When ontology is not given only the basic schedule information such as name, description and status will be returned.
     *
     * @returns void
     * @throws ApiError
     */

    public updateSchedule(
        partitionId: string,
        scheduleId: string,
        requestBody: scheduleBACnetDescriptionUpdatable
    ): Observable<void> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/schedules/${scheduleId}`;

        return this.httpClient.patch(url, requestBody, { headers, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'updateSchedule()')));
    }
    // public updateSchedule(
    //     partitionId: partitionId,
    //     scheduleId: string,
    //     requestBody: (scheduleBACnetDescriptionUpdatable)
    // ): Observable<void> {
    //     return this.httpRequest.request({
    //         method: 'PATCH',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/schedules/{scheduleId}',
    //         path: {
    //             'partitionId': partitionId,
    //             'scheduleId': scheduleId,
    //         },
    //         body: requestBody,
    //         mediaType: 'application/json',
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             401: `Unauthorized user, the JWT Bearer Token is either missing or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }

    /**
     * Unassign a schedule from an entity (i.e., Equipment)
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param scheduleCollectionElementId Schedule Collection Id
     * @returns equipmentSchedulesUpdated Associated domain event of the desire to unassign the schedule to the equipment has been raised.
     * @throws ApiError
     */
    public deleteApiV1PartitionsEntitiesSchedules(
        partitionId: partitionId,
        entityId: entityId,
        scheduleCollectionElementId: collectionElementId,
    ): Observable<equipmentSchedulesUpdated> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: scheduleUrl + '/api/v1/partitions/{partitionId}/entities/{entityId}/schedules/{scheduleCollectionElementId}',
            path: {
                'partitionId': partitionId,
                'entityId': entityId,
                'scheduleCollectionElementId': scheduleCollectionElementId,
            },
            errors: {
                400: `One or more of the required parameters are missing and/or invalid`,
                403: `Forbidden, the user is not granted access to the requested resource`,
                500: `Internal Error`,
            },
        });
    }

    /**
     * 
     * Get list of Calendars
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param status Status of the calendar (e.g. Active/Inactive) to filter the calendars.
     * @returns calendarCollectionElement Calendar list retrieved successfully.
     *
     * For cases where status is `active` only active calendars are retrived and for status `inactive` only inactive calendars are retrived. If status is not provided all calendars are retrived.
     *
     * @throws ApiError
     */

    public getCalendars(partitionId: string, entityId: entityId, status?: elementStatus): Observable<calendarCollection> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/entities/${entityId}/calendar-assignments`;

        let params: HttpParams = new HttpParams();
        if (status) {
            params = params.set('status', status);
        }

        return this.httpClient.get<calendarCollection>(url, { headers, params, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getCalendars()')));
    }
    // public getCalendars(
    //     partitionId: partitionId,
    //     entityId: entityId,
    //     status?: elementStatus,
    // ): Observable<Array<calendarCollectionElement>> {
    //     return this.httpRequest.request({
    //         method: 'GET',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/entities/{entityId}/calendars',
    //         path: {
    //             'partitionId': partitionId,
    //             'entityId': entityId,
    //         },
    //         query: {
    //             'status': status,
    //         },
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }

    /**
     * Get single calendar
     * Get calendar by calendar collection element id belonging to a specific partition and entity (Device/Equipment).
     *
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param calendarCollectionElementId Calendar Collection Id
     * @param ontology Type of the calendar (e.g. BACnet) to get calendar details.
     * When ontology is not given only the basic calendar information such as name, description and status will be returned.
     *
     * @returns any Calendar retrieved successfully.
     *
     * For cases where ontology is specified calendar details are fetched else only base calendar is fetched.
     *
     * @throws ApiError
     */

    public getCalendar(
        partitionId: string,
        calendarId: string): Observable<calendarData> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/calendars/${calendarId}`;

        return this.httpClient.get<calendarData>(url, { headers, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getCalendar()')));
    }
    // public getCalendar(
    //     partitionId: partitionId,
    //     calendarId: string
    // ): Observable<(calendarBACnetDescription)> {
    //     return this.httpRequest.request({
    //         method: 'GET',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/calendars/{calendarId}',
    //         path: {
    //             'partitionId': partitionId,
    //             'calendarId': calendarId
    //         },
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }
  
    /**
     * Update Calendar
     * Update calendar status.
     *
     * @param partitionId Partition Id
     * @param entityId Entity Id (e.g. Equipment Id or Device Id)
     * @param calendarCollectionElementId Calendar Collection Id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */

    public updateCalendar(
        partitionId: string,
        calendarId: string,
        requestBody: calendarBACnetDescriptionUpdatable
    ): Observable<void> {
        let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();

        let url = `${scheduleUrl}/api/v1/partitions/${partitionId}/calendars/${calendarId}`;

        return this.httpClient.patch(url, requestBody, { headers, observe: 'response' }).pipe(
            map(response => response.body),
            catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'updateCalendar()')));
    }
    // public updateCalendar(
    //     partitionId: partitionId,
    //     calendarId: string,
    //     requestBody: (calendarBACnetDescriptionUpdatable),
    // ): Observable<void> {
    //     return this.httpRequest.request({
    //         method: 'PATCH',
    //         url: scheduleUrl + '/api/v1/partitions/{partitionId}/calendars/{calendarId}',
    //         path: {
    //             'partitionId': partitionId,
    //             'calendarId': calendarId,
    //         },
    //         body: requestBody,
    //         mediaType: 'application/json',
    //         errors: {
    //             400: `One or more of the required parameters are missing and/or invalid`,
    //             401: `Unauthorized user, the JWT Bearer Token is either missing or invalid`,
    //             403: `Forbidden, the user is not granted access to the requested resource`,
    //             500: `Internal Error`,
    //         },
    //     });
    // }

    /**
   * Assign calendar to an entity (i.e., an equipment)
   * @param partitionId Partition Id
   * @param entityId Entity Id (e.g. Equipment Id or Device Id)
   * @param requestBody
   * @param status Status of the calendar (e.g. Active/Inactive) to filter the calendars.
   * @returns equipmentCalendarsUpdated Associated domain event of the desire to assign the calendar to the equipment has been raised.
   *
   * It does not mean that the calendar has been assigned to the calendar. The desire will be handled by the Integration layer asynchonously,
   * which updates the internal database.
   *
   * @throws ApiError
   */
    public postCalendars(
        partitionId: partitionId,
        entityId: entityId,
        requestBody: calendarPostRequest,
        status?: elementStatus,
    ): Observable<equipmentCalendarsUpdated> {
        return this.httpRequest.request({
            method: 'POST',
            url: scheduleUrl + '/api/v1/partitions/{partitionId}/entities/{entityId}/calendars',
            path: {
                'partitionId': partitionId,
                'entityId': entityId,
            },
            query: {
                'status': status,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `One or more of the required parameters are missing and/or invalid`,
                403: `Forbidden, the user is not granted access to the requested resource`,
                500: `Internal Error`,
            },
        });
    }

    /**
   * Unassign a calendar from an entity (i.e., Equipment)
   * @param partitionId Partition Id
   * @param entityId Entity Id (e.g. Equipment Id or Device Id)
   * @param calendarCollectionElementId Calendar Collection Id
   * @returns equipmentCalendarsUpdated Associated domain event of the desire to unassign the calendar to the equipment has been raised.
   * @throws ApiError
   */
    public deleteApiV1PartitionsEntitiesCalendars(
        partitionId: partitionId,
        entityId: entityId,
        calendarCollectionElementId: collectionElementId,
    ): Observable<equipmentCalendarsUpdated> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: scheduleUrl + '/api/v1/partitions/{partitionId}/entities/{entityId}/calendars/{calendarCollectionElementId}',
            path: {
                'partitionId': partitionId,
                'entityId': entityId,
                'calendarCollectionElementId': calendarCollectionElementId,
            },
            errors: {
                400: `One or more of the required parameters are missing and/or invalid`,
                403: `Forbidden, the user is not granted access to the requested resource`,
                500: `Internal Error`,
            },
        });
    }
}
