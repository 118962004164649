import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingState, Permission, SiAppDataService, SiCustomerListComponent } from '@building-x/common-ui-ng';
import { IHfwMessage } from '@gms-flex/core';
import { ModeData } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import { Subscription } from 'rxjs';

import { Partition } from '../bx-services/subscription/partition-proxy.model';
import { Customer } from '../bx-services/user/user-self-proxy.model';
import { cbmsAppPermission, CustomerSelectorStateService } from './customer-selector-state.service';

@Component({
  selector: 'gms-customer-selector',
  templateUrl: './customer-selector.component.html'
})

export class CustomerSelectorComponent implements OnInit, OnDestroy {

  public allowFilter = true;
  public showPartitionSubscriptionName = true;
  @ViewChild('customerList') public custList!: SiCustomerListComponent;

  protected appPermission = cbmsAppPermission;

  private toastTitle;
  private toastMessage;
  private translateSubscription: Subscription;
  private messageBrokerSubscription: Subscription;
  constructor(
    private readonly csStateService: CustomerSelectorStateService,
    private readonly siAppDataService: SiAppDataService,
    private readonly toastNotificationService: SiToastNotificationService,
    @Inject(IHfwMessage) private readonly messageBroker: IHfwMessage,
    @Inject(TranslateService) private readonly translateService: TranslateService) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      // extremly ugly!
      // however, the customer list maintains this variable internally as well. without this I cannot get rid of the bloody spinner!
      this.custList.isAllPartitionsLoaded = true;
      // this.siAppDataService.isAllPartitionsLoaded$.next(LoadingState.Complete);
      this.siAppDataService.isAllCustomersLoaded$.next(LoadingState.Complete);
    }, 100);
    this.translateSubscription = this.translateService
      .get([
        "BASIC_TEXTS.WARNING",
        "MESSAGES.AUTO_CLOSE_INVESTIGATIVE"]).subscribe(values => {
        this.toastTitle = values["BASIC_TEXTS.WARNING"];
        this.toastMessage = values["MESSAGES.AUTO_CLOSE_INVESTIGATIVE"];
      })

    // Reload data if the customer selection changed, but the users exits without applying changes on partitions
    if (this.csStateService.unconfirmedCustomer && this.csStateService.selectedCustomer && 
      this.csStateService.unconfirmedCustomer.id != this.csStateService.selectedCustomer.id) {
      this.csStateService.reloadData(this.csStateService.selectedCustomer.id);
    }
  }

  public ngOnDestroy(): void {
    this.translateSubscription?.unsubscribe();
    this.messageBrokerSubscription?.unsubscribe();
  }

  public get selectedCustomer(): Customer | undefined {
    return this.csStateService.selectedCustomer;
  }

  public get selectedPartitions(): Partition[] {
    return this.csStateService.selectedCustomerPartitions;
  }

  public get isAllPartitionsLoaded(): boolean {
    return this.csStateService.isAllPartitionsLoaded;
  }

  public get allCustomers(): Customer[] {
    return this.csStateService.allCustomers;
  }

  public get customerPartitions(): Partition[] {
    return this.csStateService.customerPartitions;
  }

  public get allPermissions(): Permission | null {
    return this.csStateService.allPermissions;
  }

  public onCustomerChange(event): void {
    this.csStateService.onCustomerChange(event);
    setTimeout(() => {
      // extremly ugly
      this.custList.changedPartitionSelectInput = {};
      this.custList.isAllPartitionsLoaded = true;
      // this.siAppDataService.isAllPartitionsLoaded$.next(LoadingState.Complete);
    }, 100);
  }
  
  public onSelectedCustomerPartitions(event): void {
    this.csStateService.onSelectedCustomerPartitions(event);
    this.messageBroker.getCurrentMode().subscribe(mode => mode && this.handleInvestigativeTreatment(mode)).unsubscribe();
  }

  private handleInvestigativeTreatment(mode: ModeData): void {
    if (mode.id === 'investigative') {
      this.messageBrokerSubscription = this.messageBroker.changeMode({ id: 'default', relatedValue: null }, '').subscribe(() => {
        window.location.reload();
      });
      this.toastNotificationService.queueToastNotification('warning', this.toastTitle, this.toastMessage);
    } else {
      window.location.reload();
    }
  }
}
