import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, EMPTY, expand, map, Observable, reduce, tap, throwError, zip } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import {
  PointBx,
  PointBxValue,
  PointBxValueV2,
  PointBxValueV2BulkResponse,
  PointGroupType,
  PointResponse,
  PointSourceType,
  PointValue,
  PointValueResponse,
  PointValuesBulkResponse } from './point-proxy.model';
import { PointBxWithValue } from './point.model';

const pointUrl = `${environment.bxPlatform.pointVerticalApiUrl}/v3/partitions`;
const pointUrlV2 = `${environment.bxPlatform.pointVerticalApiUrl}/v2/organizations`;

@Injectable({
  providedIn: 'root'
})
export class PointProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesPoints, 'PointProxyService created.');
  }

  public getPoints(partitionId: string, pgId: string, pgType?: PointGroupType): Observable<PointBx[]> {
    this.traceService.debug(TraceModules.bxServicesPoints, `PointProxyService.getPoints() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    let url = `${pointUrl}/${partitionId}/point-groups/${pgId}/points`;
    if (pgType) {
      url = `${pointUrl}/${partitionId}/point-groups/${pgType}-${pgId}/points`;
    }

    return this.httpClient.get<PointResponse>(url, { headers, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          let params: HttpParams = new HttpParams();
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<PointResponse>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => response.body.data ?? []),
      reduce((accumulator, current) => [...accumulator, ...current]),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPoints()')));
  }

  public getPointsByIdsBulkUnlimited(partitionId: string, pointIds: string[], includeLastValue: boolean = false): Observable<PointBxValueV2BulkResponse> {
    let startIdx = 0;
    const requests: Observable<PointBxValueV2BulkResponse>[] = [];
    while (startIdx < pointIds.length) {
      requests.push(this.getPointsByIdsBulk50(partitionId, pointIds.slice(startIdx, startIdx + 50), includeLastValue));
      startIdx += 50;
    }
    return zip(requests).pipe(map(responses => {
      return responses.reduce(((acc, curr) => {
        if (curr.points) {
          acc.points.push(...curr.points);
        }
        if (curr.errors) {
          acc.errors.push(...curr.errors);
        }
        return acc;
      }), { points: [], errors: [] })
    }));
  }

  /**
   * Reads point definitions (optional with last value) for max. 50 point Ids!
   */
  public getPointsByIdsBulk50(partitionId: string, pointIds: string[], includeLastValue: boolean = false): Observable<PointBxValueV2BulkResponse> {
    if (this.traceService.isDebugEnabled(TraceModules.bxServicesPoints)) {
      this.traceService.debug(TraceModules.bxServicesPoints,
        `PointProxyService.getPointsByIdsBulk50() called: partitionId: ${partitionId}, pointIds: ${pointIds.join(', ')}`);
    }
    if (pointIds.length > 50) {
      return throwError(() => new Error(`PointProxyService.getPointsByIdsBulk50(): Invalid arguments!`));
    }

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrlV2}/${partitionId}/points`;
    let params: HttpParams = new HttpParams();
    params = params.set('pointIDs', pointIds.join(','));
    if (includeLastValue) {
      params = params.set('includeLastValue', true);
    }

    return this.httpClient.get<PointBxValueV2BulkResponse>(url, { headers, params, observe: 'response' }).pipe(
      map(response => response.body),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPointsByIdsBulk50()')));
  }

  public getPointAndValueById(partitionId: string, pointId: string): Observable<PointBxValueV2> {
    if (this.traceService.isDebugEnabled(TraceModules.bxServicesPoints)) {
      this.traceService.debug(TraceModules.bxServicesPoints,
        `PointProxyService.getPointAndValueById() called: partitionId: ${partitionId}, pointId: ${pointId}`);
    }

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrlV2}/${partitionId}/points/${pointId}`;

    return this.httpClient.get<PointBxValueV2>(url, { headers, observe: 'response' }).pipe(
      map(response => response.body),
      map(pValue => {
        if (pValue.lastValue?.attributes?.bac_status) {
          this.convertBacStatusValue(pValue.lastValue);
        }
        if (pValue.lastValue?.attributes?.bac_current_prio) {
          this.convertBacCurrentPrioValue(pValue.lastValue);
        }
        return pValue;
      }),
      tap(pVal => {
        if (!pVal.isActive) {
          this.traceService.warn(TraceModules.bxServicesPoints, `PointProxyService.getPointAndValueById():
          Inactive point fetched, partitionId: ${partitionId}, pointId: ${pointId}, name: ${pVal.name}`)
        }
      }),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPointById()')));
  }

  public getPointValuesBulk(partitionId: string, pointIds: string[]): Observable<PointValuesBulkResponse> {
    this.traceService.debug(TraceModules.bxServicesPoints,
      `PointProxyService.getPointValuesBulk() called: partitionId: ${partitionId}, pointIds: ${pointIds.join(', ')}`);

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrlV2}/${partitionId}/points/lastValues`;
    let params: HttpParams = new HttpParams();
    params = params.set('pointIDs', pointIds.join(','));

    return this.httpClient.get<PointValuesBulkResponse>(url, { headers, params, observe: 'response' }).pipe(
      map(response => response.body),
      tap(pValues => {
        pValues.lastValues?.forEach(lastVal => {
          if (lastVal?.attributes?.bac_status) {
            this.convertBacStatusValue(lastVal);
          }
          if (lastVal?.attributes?.bac_current_prio) {
            this.convertBacCurrentPrioValue(lastVal);
          }
        });
      }),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPointValuesBulk()')));
  }

  public getPointValues(partitionId: string, pgId: string, pgType?: PointGroupType): Observable<PointBxValue[]> {
    this.traceService.debug(TraceModules.bxServicesPoints,
      `PointProxyService.getPointValues() called: partitionId: ${partitionId}, type: ${pgType}, pgId: ${pgId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    let url = `${pointUrl}/${partitionId}/point-groups/${pgId}/points/lastValues`;
    if (pgType) {
      url = `${pointUrl}/${partitionId}/point-groups/${pgType}-${pgId}/points/lastValues`;
    }

    return this.httpClient.get<PointValueResponse>(url, { headers, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          let params: HttpParams = new HttpParams();
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<PointValueResponse>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => response.body.data ?? []),
      reduce((accumulator, current) => [...accumulator, ...current]),
      map(pValues => {
        pValues.forEach(pValue => {
          if (pValue.attributes.lastValue?.attributes?.bac_status) {
            this.convertBacStatusValue(pValue.attributes.lastValue);
          }
          if (pValue.attributes.lastValue?.attributes?.bac_current_prio) {
            this.convertBacCurrentPrioValue(pValue.attributes.lastValue);
          }
        });
        return pValues;
      }),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPointValues()'))
    );
  }

  public updatePointValue(partitionId: string, pointId: string, pointSourceType: PointSourceType, value: string | null): Observable<boolean> {
    this.traceService.debug(TraceModules.bxServicesPoints, `PointProxyService.updatePointValue() called: partitionId: ${partitionId}, value: ${value}`);
    if (pointSourceType === PointSourceType.PointCIS) {
      return this.updatePointValueCIS(partitionId, pointId, value);
    } else if (pointSourceType === PointSourceType.PointNB) {
      return this.updatePointValueNB(partitionId, pointId, value);
    } else {
      return throwError(() => new Error(`PointProxyService.updatePointValue(): Invalid arguments, pointSourceType=${pointSourceType}`));
    }
  }

  private convertBacStatusValue(ptVal: PointValue): void {
    let flags: number[] = [];
    try {
      if (typeof ptVal.attributes.bac_status === 'string') {
        flags = JSON.parse(ptVal.attributes.bac_status as unknown as string);
      } else {
        flags = ptVal.attributes.bac_status;
      }
    } catch {
      this.traceService.warn(TraceModules.bxServicesPoints, `bac_status flags could not be parsed: ${ptVal.attributes.bac_status.toString()}`)
    }
    Object.defineProperty(ptVal.attributes, "bac_status", { value: flags });
  }

  private convertBacCurrentPrioValue(ptVal: PointValue): void {
    let currentPrio: number;
    try {
      currentPrio = JSON.parse(ptVal.attributes.bac_current_prio as unknown as string);
    } catch {
      this.traceService.warn(TraceModules.bxServicesPoints, `bac_current_prio could not be parsed: ${ptVal.attributes.bac_current_prio.toString()}`)
    }
    Object.defineProperty(ptVal.attributes, "bac_current_prio", { value: currentPrio });
  }

  private updatePointValueCIS(partitionId: string, pointId: string, value: string): Observable<boolean> {
    this.traceService.debug(TraceModules.bxServicesPoints, `PointProxyService.updatePointValue() called: partitionId: ${partitionId}, value: ${value}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrlV2}/${partitionId}/points/${pointId}`;
    const bodyObj = [{
      'op': 'replace',
      'path': '/value',
      value
    }];
    const body = JSON.stringify(bodyObj);

    return this.httpClient.patch(url, body, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'updatePointValue()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'updatePointValue()')));
  }

  private updatePointValueNB(partitionId: string, pointId: string, value: string): Observable<boolean> {
    this.traceService.debug(TraceModules.bxServicesPoints, `PointProxyService.updatePointValue() called: partitionId: ${partitionId}, value: ${value}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrl}/${partitionId}/points/${pointId}/covs`;
    const date = new Date(Date.now());
    const bodyObj = {
      'data': [
        {
          'type': 'COV',
          'attributes': {
            'createdAt': date.toISOString(),
            value,
            // "covAttributes": {
            //   "temperature": 25
            // },
            // "targetValue": "string",
            'qualityOfValue': 0
          }
        }
      ]
    };
    const body = JSON.stringify(bodyObj);

    return this.httpClient.post(url, body, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'updatePointValue()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'updatePointValue()')));
  }

}
