import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, EMPTY, expand, map, Observable, reduce } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { EquipmentsResponse, EquipmentsResponsePaged, SingleEquipmentResponse } from './equipment-proxy.model';

const equipmentUrl = `${environment.bxPlatform.deviceVerticalApiUrl}/v2/partitions`

@Injectable({
  providedIn: 'root'
})
export class EquipmentProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesEquipments, 'EquipmentProxyService created.');
  }

  public getEquipments(partitionId: string): Observable<EquipmentsResponse> {
    this.traceService.debug(TraceModules.bxServicesEquipments, `EquipmentProxyService.getEquipment() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${equipmentUrl}/${partitionId}/equipment`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', 100); // 100 is maximum

    return this.httpClient.get<EquipmentsResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<EquipmentsResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body?.data ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data] })),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEquipment()')));
  }

  public getEquipmentOfLocation(partitionId: string, locationId: string, equipmentName?: string): Observable<EquipmentsResponse> {
    this.traceService.debug(TraceModules.bxServicesEquipments,
      `EquipmentProxyService.getEquipmentOfLocation() called: partitionId: ${partitionId}, locationId: ${locationId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${equipmentUrl}/${partitionId}/equipment`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', 100); // 100 is maximum
    params = params.set('filter[hasLocation.id]', locationId);
    if (equipmentName !== undefined) {
      params = params.set('filter[name]', equipmentName);
    }

    return this.httpClient.get<EquipmentsResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<EquipmentsResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body?.data ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data] })),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getDevices()')));
  }

  public getEquipmentById(partitionId: string, equipmentId: string): Observable<SingleEquipmentResponse> {
    this.traceService.debug(TraceModules.bxServicesEquipments,
      `EquipmentProxyService.getEquipmentById() called: partitionId: ${partitionId}, equipmentId: ${equipmentId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${equipmentUrl}/${partitionId}/equipment/${equipmentId}`;

    return this.httpClient.get<SingleEquipmentResponse>(url, { headers, observe: 'response' }).pipe(
      map(response => response.body),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEquipmentById()')));
  }

  public getEquipmentByEquipmentTypeId(partitionId: string, equipmentTypeId: string): Observable<EquipmentsResponse> {
    this.traceService.debug(TraceModules.bxServicesEquipments,
      `EquipmentProxyService.getEquipmentByEquipmentTypeId() called: partitionId: ${partitionId}, equipmentTypeId: ${equipmentTypeId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${equipmentUrl}/${partitionId}/equipment`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', 100);
    params = params.set('filter[hasEquipmentType.id]', equipmentTypeId);

    return this.httpClient.get<EquipmentsResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<EquipmentsResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body?.data ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data] })),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEquipmentByEquipmentTypeId()')));
  }
}
