import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SelectedSite, SiteSelectionServiceBase } from './site-selection.service.base';
/**
 * Implementation for site selection handling.
 *
 * @export
 * @class SiteSelectionService
 */
@Injectable({
  providedIn: 'root'
})
export class SiteSelectionService extends SiteSelectionServiceBase {

  private readonly _selectedSite: BehaviorSubject<SelectedSite>
    = new BehaviorSubject<SelectedSite>({
      objectId: undefined,
      singleSiteActive: false,
      siteName: undefined,
      objectType: undefined,
      buildingsIds: undefined
    });

  public constructor() {
    super();
  }

  /**
   * this method is not implemented for flex client, it should only be used in a cbms context
   * @param objectId ObjectId of the selected site in the form of System:ObjectId where system is the PartitionId
   * @param singleSiteActive this flag indicate if we are working in singleSite or multiSite mode
   */
  public setSite(objectId: string | undefined, singleSiteActive: boolean | undefined): void {
    throw new Error('Method not implemented.');
  }

  /**
   * this method is not implemented for flex client, it should only be used in a cbms context
   * @param singleSiteActive this flag indicate if we are working in singleSite (true) or multiSite mode (false)
   */
  public setSiteMode(singleSiteActive: boolean): void {
    throw new Error('Method not implemented.');
  }

  /**
   * this method is not implemented for flex client, it should only be used in a cbms context,
   * it will return always the same observable, with only singleSiteActive set to false
   */
  public get selectedSite(): Observable<SelectedSite> {
    return this._selectedSite.asObservable();
  }
}
