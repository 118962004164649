<div
  class="status-item rounded-2 focus-inside d-flex align-items-center"
  [class.text-muted]="!value"
  [class.blink]="blink && value"
  [attr.tabindex]="clickable ? '0' : ''"
  [attr.aria-disabled]="!value"
>
  <div
    #bg
    role="none"
    class="bg focus-sub-inside"
    [class.custom-color]="color"
    [class.contrast-fix]="contrastFix"
    [ngClass]="background"
    [class.d-none]="!value"
    [style.background-color]="blink && color ? color : null"
  ></div>
  @if (!statusIcon) {
    <div
      class="color-bar me-3 mt-1 mb-1"
      [style.background-color]="value && color ? color : null"
    ></div>
  }
  @if (statusIcon) {
    <si-icon
      class="indicator me-4"
      alt=""
      [icon]="statusIcon.icon"
      [color]="value ? statusIcon.color : 'indicator-disabled'"
      [stackedIcon]="statusIcon.stacked"
      [stackedColor]="value ? statusIcon.stackedColor : 'text-inverse'"
    />
  }
  <div class="overflow-hidden">
    @if (value !== undefined) {
      <div class="item-value si-title-2 text-truncate">{{ value.toString() | translate }}</div>
    }
    @if (!valueOnly) {
      <div class="item-title text-truncate">{{ heading | translate }}</div>
    }
  </div>
</div>
