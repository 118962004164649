import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, concatMap, map, Observable, of, tap, zip } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { LocationService } from '../location/location.service';
import { EntityType, FilterType } from '../shared/base.model';
import { useCache } from '../shared/http-utility.service';
import { EquipmentsResponse } from './equipment-proxy.model';
import { EquipmentProxyService } from './equipment-proxy.service';
import { EquipmentTypeService } from './equipment-type.service';
import { Equipment } from './equipment.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private readonly equipmentPerPartition: Map<string, Equipment[]> = new Map<string, Equipment[]>();
  private readonly equipmentPerPartitionAndLocation: Map<string, Map<string, Equipment[]>> = new Map<string, Map<string, Equipment[]>>();
  private readonly equipmentPerPartitionAndParentAndName: Map<string, Map<string, Map<string, Equipment[]>>> =
    new Map<string, Map<string, Map<string, Equipment[]>>>();
  private readonly equipmentPerPartitionAndId: Map<string, Map<string, Equipment>> = new Map<string, Map<string, Equipment>>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly equipmentProxy: EquipmentProxyService,
    private readonly locationService: LocationService) {
    this.traceService.info(TraceModules.bxServicesEquipments, 'EquipmentService created.');
  }

  public getEquipments(partitionId: string): Observable<Equipment[]> {
    if ((useCache) && (this.equipmentPerPartition.has(partitionId))) {
      const equipments = this.equipmentPerPartition.get(partitionId);
      this.traceService.debug(TraceModules.bxServicesEquipments, `EquipmentService.getEquipment() returned: no of equipments: ${equipments.length} from cache
        partitionId=${partitionId}`);
      return of(equipments);
    } else {
      return this.equipmentProxy.getEquipments(partitionId).pipe(
        map(result => this.mapEquipment(result)),
        tap(result => {
          this.equipmentPerPartition.set(partitionId, result);
          this.traceService.debug(TraceModules.bxServicesEquipments, `EquipmentService.getEquipment() returned: no of equipments: ${result.length} from backend
            partitionId=${partitionId}`);
        }),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesEquipments, `EquipmentService: Equipment not found for partitionId: ${partitionId}, error=${error}`);
          this.equipmentPerPartition.set(partitionId, []);
          return of([]);
        })
      );
    }
  }

  /**
   * Return equipments per partition and location. An optional filter (equipment name or Id) can be set.
   * Filtering is done on client-side (on-purpose as all children are anyway needed in most of the cases)
   * The query reads the location and uses the HasAssets data to get the device Id's and fetch the devices.
   */
  public getEquipmentsOfLocation(partitionId: string, locationId: string, filter?: string, filterType?: FilterType): Observable<Equipment[]> {
    if ((useCache) && (this.equipmentPerPartitionAndLocation.has(partitionId) && (this.equipmentPerPartitionAndLocation.get(partitionId).has(locationId)))) {
      const equipments = this.equipmentPerPartitionAndLocation.get(partitionId).get(locationId);
      const filtered = this.filterEquipments(equipments, filter, filterType);
      this.traceService.debug(TraceModules.bxServicesEquipments,
        `EquipmentService.getEquipmentsOfLocation() returned: no of equipments: ${filtered.length} from cache
        partitionId=${partitionId}, locationId=${locationId}, filter=${filter}`);
      return of(filtered);
    } else {
      // Filtering by location does not speed up significantly, as all equipments are read from DB and filtered (still server side) by code.
      // Thus we use the hasAssets data to get the device id's in this case!
      return this.locationService.getLocationHasAssetsIds(partitionId, locationId, EntityType.Equipment).pipe(
        concatMap(equipmentIds => {
          if (filter && filterType === 'Id') {
            equipmentIds = equipmentIds.filter(eqId => eqId === filter);
          }
          const equipmentObs = equipmentIds.map(eqId => this.getEquipmentById(partitionId, eqId));
          if (equipmentObs.length === 0) {
            this.traceService.debug(TraceModules.bxServicesEquipments, `EquipmentService.getEquipmentsOfLocation() returned: no of equipments: 0 from backend
              partitionId=${partitionId}, locationId=${locationId}, filter=${filter}`);
            return of([]);
          } else {
            return zip(equipmentObs).pipe(
              map(equipments => this.filterEquipments(equipments, filter, filterType)),
              tap(devices => {
                this.traceService.debug(TraceModules.bxServicesEquipments,
                  `EquipmentService.getEquipmentsOfLocation() returned: no of equipments: ${devices.length} from backend
                  partitionId=${partitionId}, locationId=${locationId}, filter=${filter}`);
              })
            );
          }
        })
      );
    }
  }

  /**
   * Return equipments per partition and location and an optional equipment name.
   * The query uses the location id as filter on server side.
   * Consider using the method getEquipmentsOfLocation() with first prio as this call might last longer due to paging.
   */
  public getEquipmentsOfLocationServerFiltersLocation(partitionId: string, locationId: string, equipmentName?: string): Observable<Equipment[]> {
    if ((useCache) && (this.equipmentPerPartitionAndLocation.has(partitionId) && (this.equipmentPerPartitionAndLocation.get(partitionId).has(locationId)))) {
      const equipments = this.equipmentPerPartitionAndLocation.get(partitionId).get(locationId);
      const filtered = this.filterEquipments(equipments, equipmentName, 'Name');
      this.traceService.debug(TraceModules.bxServicesEquipments,
        `EquipmentService.getEquipmentsOfLocation() returned: no of equipments: ${filtered.length} from cache
        partitionId=${partitionId}, locationId=${locationId}, equipmentName=${equipmentName}`);
      return of(filtered);
    } else {
      if (equipmentName === undefined) {
        return this.equipmentProxy.getEquipmentOfLocation(partitionId, locationId).pipe(
          map(result => this.mapEquipment(result)),
          tap(result => {
            if (this.equipmentPerPartitionAndLocation.has(partitionId) === false) {
              this.equipmentPerPartitionAndLocation.set(partitionId, new Map<string, Equipment[]>());
            }
            this.equipmentPerPartitionAndLocation.get(partitionId).set(locationId, result);
            this.traceService.debug(TraceModules.bxServicesEquipments,
              `EquipmentService.getEquipmentsOfLocationServerFiltersLocation() returned: no of equipments: ${result.length} from backend
              partitionId=${partitionId}, locationId=${locationId}, equipmentName=${equipmentName}`);
          }),
          catchError(error => {
            this.traceService.warn(TraceModules.bxServicesEquipments, `EquipmentService: Equipment not found for partitionId: ${partitionId}, error=${error}`);
            if (this.equipmentPerPartitionAndLocation.has(partitionId) === false) {
              this.equipmentPerPartitionAndLocation.set(partitionId, new Map<string, Equipment[]>());
            }
            this.equipmentPerPartitionAndLocation.get(partitionId).set(locationId, []);
            return of([]);
          })
        );
      } else {
        // TODO: evaluate if reading one equipment by name per location is significant faster than reading all equipments of the same locaation.
        // If there are only 20 equipments below the location, there is no difference!!!
        // consider reading always all the children. Code gets more simple.
        return this.getEquipmentOfLocationByName(partitionId, locationId, equipmentName);
      }
    }
  }

  public getEquipmentById(partitionId: string, equipmentId: string): Observable<Equipment | undefined> {
    if ((useCache) && (this.equipmentPerPartitionAndId.has(partitionId) && (this.equipmentPerPartitionAndId.get(partitionId).has(equipmentId)))) {
      const equipment = this.equipmentPerPartitionAndId.get(partitionId).get(equipmentId);
      this.traceService.debug(TraceModules.bxServicesEquipments, `EquipmentService.getEquipmentById() returned equipment: ${equipment?.name} from cache
        partitionId=${partitionId}, equipmentId=${equipmentId}`);
      return of(equipment);
    } else {
      return this.equipmentProxy.getEquipmentById(partitionId, equipmentId).pipe(
        map(eqResponse => new Equipment(eqResponse.data)),
        tap(result => {
          if (this.equipmentPerPartitionAndId.has(partitionId) === false) {
            this.equipmentPerPartitionAndId.set(partitionId, new Map<string, Equipment>());
          }
          this.equipmentPerPartitionAndId.get(partitionId).set(equipmentId, result);
          this.traceService.debug(TraceModules.bxServicesEquipments, `EquipmentService.getEquipmentById() returned equipment: ${result?.name} from backend
            partitionId=${partitionId}, equipmentId=${equipmentId}`);
        }),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesEquipments, `EquipmentService: Equipment not found: ${equipmentId}, error=${error}`);
          if (this.equipmentPerPartitionAndId.has(partitionId) === false) {
            this.equipmentPerPartitionAndId.set(partitionId, new Map<string, Equipment>());
          }
          this.equipmentPerPartitionAndId.get(partitionId).set(equipmentId, undefined);
          return of(undefined);
        })
      );
    }
  }

  public getEquipmentByEquipmentTypeId(partitionId: string, equipmentTypeId: string): Observable<Equipment[]> {
    return this.equipmentProxy.getEquipmentByEquipmentTypeId(partitionId, equipmentTypeId).pipe(
      map(result => this.mapEquipment(result)),
      catchError(error => {
        this.traceService.warn(TraceModules.bxServicesEquipments, `EquipmentService: Error: ${equipmentTypeId}, error=${error}`);
        return of([]);
      })
    );
  }

  private getEquipmentOfLocationByName(partitionId: string, locationId: string, childName: string): Observable<Equipment[]> {
    if ((useCache) && (this.equipmentPerPartitionAndParentAndName.has(partitionId)
      && (this.equipmentPerPartitionAndParentAndName.get(partitionId).has(locationId))
      && (this.equipmentPerPartitionAndParentAndName.get(partitionId).get(locationId).has(childName)))) {
      return of(this.equipmentPerPartitionAndParentAndName.get(partitionId).get(locationId).get(childName));
    } else {
      return this.equipmentProxy.getEquipmentOfLocation(partitionId, locationId, childName).pipe(
        map(result => this.mapEquipment(result)),
        tap(result => {
          if (this.equipmentPerPartitionAndParentAndName.has(partitionId) === false) {
            this.equipmentPerPartitionAndParentAndName.set(partitionId, new Map<string, Map<string, Equipment[]>>());
          }
          if (this.equipmentPerPartitionAndParentAndName.get(partitionId).has(locationId) === false) {
            this.equipmentPerPartitionAndParentAndName.get(partitionId).set(locationId, new Map<string, Equipment[]>());
          }
          this.equipmentPerPartitionAndParentAndName.get(partitionId).get(locationId).set(childName, result);
        })
      );
    }
  }

  private filterEquipments(equipments: Equipment[], filter?: string, filterType?: FilterType): Equipment[] {
    if (filter) {
      if (filterType === 'Name') {
        const found = this.findEquipment(equipments, filter);
        return found ? [found] : [];
      } else {
        const found = this.findEquipmentById(equipments, filter);
        return found ? [found] : [];
      }
    } else {
      return equipments;
    }
  }

  private findEquipment(equipments: Equipment[], name: string): Equipment | undefined {
    return equipments.find(equip => equip.name === name);
  }

  private findEquipmentById(equipments: Equipment[], id: string): Equipment | undefined {
    return equipments.find(eq => eq?.id === id);
  }

  private mapEquipment(equipmentRes: EquipmentsResponse): Equipment[] {
    const devices: Equipment[] = [];
    equipmentRes.data.forEach(item => {
      const dvc = new Equipment(item);
      devices.push(dvc);
    });
    return devices;
  }

}
