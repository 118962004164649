import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GmsEasyNavigationBarServiceBase } from '@gms-flex/services';
import { isNullOrUndefined, SettingsServiceBase, TraceService } from "@gms-flex/services-common";

import { ObjectManagerCoreService } from "../../object-manager-core/object-manager-core.service";
import { ObjectManagerViewModelIfc } from "../../object-manager-core/view-model/object-manager-vm";
import { ObjectViewIfc } from "../../object-manager-core/view-model/object-view";
import { AggregateViewDelegate } from "../../object-manager-core/view-model/object-view-aggregate";
import { TraceModules } from "../../shared/trace-modules";

@Injectable({ providedIn: 'root' })
export class GmsEasyNavigationBarService extends GmsEasyNavigationBarServiceBase {
  private _vm: ObjectManagerViewModelIfc;
  public get snapinVm(): ObjectManagerViewModelIfc {
    return this._vm;
  }

  public set snapinVm(newVm: ObjectManagerViewModelIfc) {
    this._vm = newVm;
  }

  public override get EasyNavigationBarViewName(): string {
    return this._easyNavigationBarViewName;
  }

  constructor(private readonly settingsService: SettingsServiceBase,
    private readonly ds: DomSanitizer,
    private readonly traceService: TraceService) {
    super();
    this.getEasyNavigationSettings();
  }

  public override set EasyNavigationBarViewName(value: string) {
    this._easyNavigationBarViewName = value;
  }

  public override set HldlEasyNavigationBarViewName(value: string) {
    this._easyNavigationBarViewName = value;
  }

  protected override onGetEasyNavigationBarSettings(settings?: any): void {
    const hasSettings: boolean = !isNullOrUndefined(settings) && settings !== '';
    if (hasSettings) {
      const easyNavigationBarSettings = settings;
      this._easyNavigationBarViewName = easyNavigationBarSettings;
      this.onEasyNavigationBarUpdate.next(this._easyNavigationBarViewName);
    } else {
      this._easyNavigationBarViewName = this._hldlEasyNavigationBarViewName;
      this.putEasyNavigationSettings();
    }

    this.updateSelectedView();
  }

  protected override onGetEasyNavigationBarSettingsError(error: any): void {
    this.traceService.info(TraceModules.objectManagerCore, 'Error while reading easy-navigation-settings: %s', error.toString());
  }

  public override getEasyNavigationSettings(): void {
    this.settingsService.getSettings(this.EasyNavigationBarViewSettingId).subscribe({
      next: val => this.onGetEasyNavigationBarSettings(val),
      error: err => this.onGetEasyNavigationBarSettingsError(err)
    });
  }

  public override putEasyNavigationSettings(): void {
    if (isNullOrUndefined(this._selectedView)) {
      return;
    }

    const selectedViewName: string = this._selectedView.description;
    if (selectedViewName === this._hldlEasyNavigationBarViewName) {
      return;
    }

    this.settingsService.putSettings(this.EasyNavigationBarViewSettingId, selectedViewName).subscribe({
      next: val => {
        this.traceService.info(TraceModules.objectManagerCore, 'EasyNavigationBar:putEasyNavigationSettings(): ', selectedViewName);
      },
      error: err => {
        this.traceService.error(TraceModules.objectManagerCore, 'EasyNavigationBar:putEasyNavigationSettings(): ', err);
      }
    });
  }

  private _selectedView: ObjectViewIfc;
  public get SelectedView(): ObjectViewIfc {
    return this._selectedView;
  }

  private _selectedViewActivated = false;
  public get SelectedViewActivated(): boolean {
    return this._selectedViewActivated;
  }

  protected set SelectedViewActivated(value: boolean) {
    this._selectedViewActivated = value;
  }

  public override saveAndUpdateSelectedView(view: string): void {
    this._easyNavigationBarViewName = view;
    this.updateSelectedView();
  }

  public override updateSelectedView(): void {
    const views: readonly ObjectViewIfc[] = this?._vm?.views || [];
    const viewLength: number = views?.length || 0;
    for (let i = 0; i < viewLength; ++i) {
      const view = views[i];
      const aggregateViewDelegate: AggregateViewDelegate = view as AggregateViewDelegate;
      const viewName = aggregateViewDelegate?.viewRef?.cnsViews?.[0]?.Name;

      if (viewName === this._easyNavigationBarViewName) {
        this._selectedView = view;
        this._selectedView?.activate(this.ds).subscribe(() => {
          this.SelectedViewActivated = true;
          this.putEasyNavigationSettings();
          this.onEasyNavigationBarUpdate.next(this._easyNavigationBarViewName);
        });
        break;
      } else if (i === viewLength - 1) {
        this.SelectedViewActivated = false;
      }
    }
  }

  public get EasyNavigationBarViewSettingId(): string {
    return "easy-navigation-bar-view-settings";
  }

  public get Views(): string[] {
    return this._vm.views.map(view => view.description);
  }
}
