import { Injectable } from '@angular/core';
import { FullQParamId, FullSnapInId, MessageParameters, QParam } from '@gms-flex/core';
import { BrowserObject, GmsMessageData } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, Observable } from 'rxjs';

import { TraceModules } from '../shared/trace-modules';
import { SystemBrowserViewModel } from '../view-model/snapin-vm';
import { SystemBrowserViewModelBase } from '../view-model/snapin-vm.base';

@Injectable()
export class SystemBrowserSnapInService {

  private readonly _trModule: string = TraceModules.sysBrowser;
  private readonly vmMap: Map<string, SystemBrowserViewModel>;
  private readonly _selectedNodeMessage: BehaviorSubject<MessageParameters> = new BehaviorSubject<MessageParameters>({
    types: undefined,
    messageBody: undefined,
    qParam: undefined
  });

  /**
   * Generate a unique key based on SNI id.
   */
  private static getVmKeyValue(sniId: FullSnapInId): string {
    return sniId.fullId();
  }

  /**
   * Constructor.
   */
  constructor(private readonly traceService: TraceService) {
    this.vmMap = new Map<string, SystemBrowserViewModel>();
  }

  /**
   * Get existing or create new snap-in view-model.
   */
  public registerViewModel(sniId: FullSnapInId): SystemBrowserViewModelBase {
    if (!sniId) {
      throw new Error('sniId argument undefined');
    }
    const key: string = SystemBrowserSnapInService.getVmKeyValue(sniId);

    let vm: SystemBrowserViewModel = this.vmMap.get(key);
    if (!vm) {
      this.traceService.info(this._trModule, `Create new view-model: sniKey=[${key}]`);
      vm = new SystemBrowserViewModel(sniId, this.traceService);
      this.vmMap.set(key, vm);
    }
    // vm.activate();       Let the SNI do this!
    return vm;
  }

  /**
   * Remove and dispose of snap-in view-model.
   */
  public unregisterViewModel(sniId: FullSnapInId): void {
    if (!sniId) {
      return;
    }
    const key: string = SystemBrowserSnapInService.getVmKeyValue(sniId);

    const vm: SystemBrowserViewModel = this.vmMap.get(key);
    if (vm) {
      this.traceService.info(this._trModule, `Destroy view-model: sniKey=[${key}]`);
      vm.dispose();
      this.vmMap.delete(key);
    }
  }

  /**
   * Update selected node message parameters cache accordingly to the system browser's current selected node.
   */
  public updateSelectedNodeMessage(node: BrowserObject): void {
    const body: GmsMessageData = new GmsMessageData([node]);
    const messageTypes: string[] = [node.Attributes.ManagedTypeName];

    const fullQParamId = new FullQParamId('system-manager', 'SystemQParamService', 'primary');
    const newQParam: QParam = { name: fullQParamId.fullId(), value: node.Designation };
    this._selectedNodeMessage.next({ types: messageTypes, messageBody: body, qParam: newQParam });
  }

  /**
   * Get selected node message parameters from cache.
   */
  public get selectedNodeMessage(): Observable<MessageParameters> {
    return this._selectedNodeMessage.asObservable();
  }
}
