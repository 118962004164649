import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { SystemsServiceBase } from "@gms-flex/services";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from 'lodash';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';

import { TrendExportMapperBaseService } from "./trend-export-mapper-base-service";
@Injectable()
export class TrendPdfExportService extends TrendExportMapperBaseService {

  constructor(protected readonly translateService: TranslateService, protected readonly systemsService: SystemsServiceBase) {
    super(translateService, systemsService);
  }

  public exportFile(): void {
    const loadPdfMake = async (): Promise<void> => {
      const pdfMakeModule = await import('pdfmake/build/pdfmake.min.js');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts.js');
  
      (window as any).pdfMake.vfs = pdfFontsModule.pdfMake.vfs;
  
      const filename = `${this.fileName}.${this.fileType}`;
  
      const documentDefinition: TDocumentDefinitions = {
        header: this.getPdfHeader(),
        footer: (currentPage, pageCount) => this.getPdfFooter(currentPage, pageCount),
        content: this.getTrendDataContent(),
        defaultStyle: {
          fontSize: 11
        }
      };
  
      pdfMakeModule.createPdf(documentDefinition).download(filename);
    };
  
    loadPdfMake().catch(error => {
      // console.error("Error generating PDF:", error);
    });
  }

  protected getTrendDataContent(): Content[] {
    const content: Content[] = [];
    // intial common toFrom header table
    content.push(this.createTable(this.dataToExport.sheetHeaders, this.dataToExport.sheetValues));
    
    let tableCounter = 0;
    this.dataToExport.tableValues.forEach((tableValue, index) => {
      // set width of columns as per condition
      const dataWidths: string[] = this.calculateDynamicColumnWidths(tableValue.dataValues);
      if (tableValue.pointValues) {
        content.push(this.createTable(this.dataToExport.pointHeaders, tableValue.pointValues));
        tableCounter++;
      }
      // main point data table handling
      content.push(this.createDataTableContent(tableValue, dataWidths))
      tableCounter++;
      // Insert page break after two tables, for handling of new datapoint
      if (tableCounter % 2 === 0 && index !== this.dataToExport.tableValues.length - 1) {
        content.push({ text: '', pageBreak: 'after' });
      }
    });
    return content.map(contentObj => cloneDeep(contentObj))
  }

  protected getPdfHeader(): Content {
    return {
      columns: [
        { image: this.logoUrl, width: 90, alignment: 'left', margin: [30, 8], fontSize: 12 },
        { text: this.trendReportTitle, alignment: 'center', fontSize: 14, bold: true, margin: [150, 20, 0, 10], width: '*' },
        { text: `${this.runAtText} ${this.formatDateToBrowserLocale(new Date().toISOString())}`, alignment: 'right', margin: [10, 10, 30, 0], fontSize: 10 }
      ]
    }
  }
  
  protected getPdfFooter(currentPage: number, pageCount: number): Content {
    return {
      text: this.pageText + ' ' + currentPage + ' ' + this.ofText + ' ' + pageCount,
      alignment: 'center',
      margin: [0, 20, 0, 10]
    };
  }

  protected calculateDynamicColumnWidths(data: string [][]): string[] {
    const columnWidths: string[] = [];
    const numColumns = data[0].length;
    for (let colIndex = 0; colIndex < numColumns; colIndex++) {
      let maxLength = 0;
    
      data.forEach(row => {
        const cell = row[colIndex];
        const cellLength = this.checkStringType(cell) === 'Number' 
          ? cell.toString().length
          : cell ? cell.length : 0;
    
        if (cellLength > maxLength) {
          maxLength = cellLength;
        }
      });
      if (maxLength < 5) {
        columnWidths.push('10%');
      } else if (maxLength < 10) {
        columnWidths.push('20%');
      } else {
        columnWidths.push('*');
      }
    }
    return columnWidths;
  }

  private createDataTableContent(tableValue, dataWidths: string[]): any {
    return {
      table: {
        headerRows: 1,
        dontBreakRows: true,
        widths: dataWidths,
        body: [
          tableValue.dataHeaders.map(item => {
            if (item == this.minLabel || item == this.maxLabel || item == this.avgLabel || item == this.valueLabel) {
              return { text: item, alignment: 'right' };
            }
            return { text: item, alignment: 'left' }; 
          }),
          ...tableValue.dataValues.map(item => {
            return item.map(cell => this.createCell(cell, tableValue.resolution));
          })
        ]
      },
      layout: this.createTableRowLayout('fillcolor'),
      margin: [0, 10, 0, 0]
    };
  }

  private createTable(headers: string[], body: any[]): any {
    return {
      table: {
        headerRows: 1,
        dontBreakRows: true,
        widths: new Array(headers.length).fill("*"),
        body: [headers, body]
      },
      layout: this.createTableRowLayout('hBorder'),
      margin: [0, 20, 0, 0]
    };
  }

  private createCell(cell: any, resolution: number): any {
    const cellType = this.checkStringType(cell);
  
    const cellContent = cellType === 'Number' 
      ? { text: this.formatNumber(parseFloat(cell), resolution), alignment: 'right' }
      : cellType === 'Date' 
        ? { text: this.formatDateToBrowserLocale(cell), alignment: 'left' }
        : { text: cell, alignment: 'right' };
  
    return cellContent;
  }

  private createTableRowLayout(layoutType: string): any {
    if (layoutType == "hBorder") {
      return { defaultBorder: true,
        hLineColor: () => '#808080',
        vLineColor: () => '#808080'
      }
    }
    return { 
      fillColor: (i: number): string | null => {
        return (i % 2 !== 0) ? '#dad9d9' : null;
      }, 
      defaultBorder: false
    };
  }

  private formatNumber(value: number, decimalPlaces: number = 0): string {
    // format a number with thousands separators (commas). ex: 1,234,567.89
    const numberFormatter = new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    });
    const number = numberFormatter.format(value);
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
