import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { asapScheduler, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsAdornerType } from '../types/gms-adorner-types';
import { Utility } from '../utilities/utility';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-adorner]',
  template: `<svg:rect #container
                    [attr.visibility]="CalculateVisible()"
                    [attr.x]="x"
                    [attr.y]="y"
                    [attr.width]="width"
                    [attr.height]="height"
                    [attr.stroke]="adorner.Stroke"
                    [attr.stroke-width]="GetAdornerStrokeWidth()"
                    [attr.stroke-dasharray]="adorner.StrokeDashArray"
                    stroke-opacity= "1"
                    vector-effect="non-scaling-stroke"
                    [attr.fill-opacity]="0"
                    [attr.rx]="3"
                    [attr.ry]="3">
                    >
               </svg:rect>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsAdornerComponent extends GmsElementComponent implements OnInit, OnDestroy {
  public static readonly ADORNER_OFFSET: number = 5;
  public static readonly ADORNER_OUTLINE_WIDTH: number = 2;
  public static readonly ADORNER_MAX_WIDTH: number = 30;
  public static readonly ADORNER_MIN_WIDTH: number = 2;

  public height: number;
  public width: number;
  public x = 0;
  public y = 0;

  public outlineWidth: number = GmsAdornerComponent.ADORNER_OUTLINE_WIDTH;
  public selected: boolean;
  public offset: number = GmsAdornerComponent.ADORNER_OFFSET;
  public strokeWidth = 2;

  @Input() public adorner: GmsAdorner = null;

  @ViewChild('container', { static: true }) private readonly adornerContainerRef: ElementRef;
  private selectionSubscription: Subscription;

  private svgroot: SVGSVGElement;
  private adornerContainer: SVGGElement;
  private sourceElement: SVGElement;
  private locatableElement: SVGGraphicsElement;
  private svgZoomTransFormGroup: SVGGElement;
  private referenceSourceElementTransformMatrix: SVGMatrix;
  private _zoomChangedSubscription: Subscription = undefined;
  
  public ngOnInit(): void {
    this.sourceElement = document.getElementById(this.adorner.SourceElement.Id) as any as SVGElement;
    this.selected = this.adorner.AdornerType === GmsAdornerType.Selection;

    this.adornerContainer = this.adornerContainerRef?.nativeElement as SVGGElement;
    // this.adorner.StrokeWidth = GmsAdornerComponent.ADORNER_STROKE_WIDTH;

    this.locatableElement = this.sourceElement as any as SVGGraphicsElement;
    // let strokeWidth: number = +this.adorner.SourceElement.StrokeWidth;

    // the top-level svg element
    this.svgroot = this.locatableElement?.viewportElement as SVGSVGElement;

    // the graphic component container for child elements, affected by zooming and panning
    this.svgZoomTransFormGroup = this.svgroot?.getElementById('ContentGroup') as SVGGElement;

    this.UpdateAdorner();

    this.selectionSubscription = this.adorner.SourceElement.propertyChanged.pipe(debounceTime(10))
      .subscribe(value => this.UpdateAdorner());
    // Subscribe to listen to zoom changes
    const graphic: GmsGraphic = this.adorner.SourceElement.Graphic as GmsGraphic;
    this._zoomChangedSubscription = graphic.zoomChanged.pipe(debounceTime(10))
      .subscribe(() => this.Update());
  }

  public ngOnDestroy(): void {
    if (this._zoomChangedSubscription !== undefined) {
      this._zoomChangedSubscription.unsubscribe();
      this._zoomChangedSubscription = undefined;
    }
    this.selectionSubscription?.unsubscribe();
    this.sourceElement = undefined;
    this.adornerContainer = undefined;
  }

  public CalculateVisible(): string {
    if (this.sourceElement === undefined) {
      return 'hidden';
    }

    if (this.sourceElement !== undefined && !Utility.isDomVisible(this.sourceElement)) {
      return 'hidden';
    }

    return this.adorner.SourceElement.GetVisible();
  }
  // max zoom value
  private static readonly _zmax = 20 * 1.2;
  // default thickness of the adorner stroke at the zoom = 1
  private static readonly _adefault = 1;
  // min thickness of the adorner stroke at the min zoom = 0
  private static readonly _amin = 0.1;
  // max thickness of the adorner stroke at the max zoom
  private static readonly _amax = 5;
  // factor to transform current zoom to current adorner stroke thickness when zoom < 1
  private static readonly _factor1 = GmsAdornerComponent._adefault - GmsAdornerComponent._amin;
  // factor to transform current zoom to current adorner stroke thickness when zoom >= 1
  private static readonly _factor2 = (GmsAdornerComponent._amax - GmsAdornerComponent._adefault) / (GmsAdornerComponent._zmax - 1);

  public GetAdornerStrokeWidth(): number {
    let w: number = GmsGraphic.DEFAULT_ADORNER_THICKNESS;
    let scale = 1;
    let test = 1;
    const graphic: GmsGraphic = this.adorner?.SourceElement?.Graphic as GmsGraphic;
    if (graphic !== null) {
      test = this.adorner.SourceElement.ScaleX;
      w = this.adorner.SourceElement.IsSelected ? graphic.AdornerStrokeThickness :
        (graphic.AdornerStrokeThickness + 1) / 2;
      const zoom: number = graphic.CurrentZoomLevel;
      if (zoom > GmsAdornerComponent._zmax) {
        scale = GmsAdornerComponent._amax;
      } else if (zoom >= 1) {
        scale = 1 + GmsAdornerComponent._factor2 * (zoom - 1);
      } else {
        scale = GmsAdornerComponent._amin + GmsAdornerComponent._factor1 * zoom;
      }
    }
    return w * scale * test;
  }
  private Update(): void {
    this.changeDetector.detectChanges();
  }
  private async UpdateAdorner(): Promise<any> {
    if (this.sourceElement == null) {
      return;
    }

    // Get transform matrix of source element. Contains zoom/pan transformations
    const originalTransform: SVGMatrix = this.locatableElement.getCTM();

    if (this.svgZoomTransFormGroup !== null) {
      // cancel duplicated zoom/pan transformations
      this.referenceSourceElementTransformMatrix = this.svgZoomTransFormGroup.getCTM().inverse().multiply(originalTransform);
    } else { // Incase of inclusive adorners ( Replication Container )
      this.referenceSourceElementTransformMatrix = originalTransform;
      this.strokeWidth = 1; // 1px incase of adorner
    }

    // all transformations from root to source element
    const transform: SVGTransform = this.svgroot.createSVGTransformFromMatrix(this.referenceSourceElementTransformMatrix);

    this.width = this.adorner.SourceElement.AdornerWidth + GmsAdornerComponent.ADORNER_OFFSET * 2;
    this.height = this.adorner.SourceElement.AdornerHeight + GmsAdornerComponent.ADORNER_OFFSET * 2;
    this.x = this.adorner.SourceElement.AdornerX - GmsAdornerComponent.ADORNER_OFFSET;
    this.y = this.adorner.SourceElement.AdornerY - GmsAdornerComponent.ADORNER_OFFSET;

    this.changeDetector.detectChanges();

    this.adornerContainer.transform.baseVal.initialize(transform);
  }

}
