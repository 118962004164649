export class UrlUtils {
  public static hasHttpProtocol(urlString: string): boolean {
    try {
      const url: URL = new URL(urlString);
      const protocol: string = url.protocol;
      const result: boolean = protocol === 'http:' || protocol === 'https:';
      return result;
    } catch (error: any) {
      return false;
    }
  }

  public static readonly patterns = {
    PROTOCOL: /^https?:$/i,
    DOMAIN: /^([a-z\d]([a-z\d-]*[a-z\d])*\.)+[a-z]{2,}$/i,
    isValidProtocol(protocol: string): boolean {
      return this.PROTOCOL.test(protocol);
    },
    isValidDomainName(domain: string): boolean {
      return this.DOMAIN.test(domain);
    },
    isValidIPv4: (ip: string): boolean => {
      const octets: string[] = ip.split('.');
      if (octets.length !== 4) {
        return false;
      }

      const result: boolean = octets.every(octet => {
        const num = parseInt(octet, 10);
        const isValid = num >= 0 && num <= 255;
        return isValid;
      });

      return result;
    }
  }

  public static validateUrl(urlString: string): boolean {
    try {
      const url = new URL(urlString);

      const isValidProtocol: boolean = this.patterns.isValidProtocol(url.protocol);
      if (!isValidProtocol) {
        return false;
      }

      const isValidDomainName: boolean = this.patterns.isValidDomainName(url.hostname);
      if (!isValidDomainName) {
        return false;
      }

      const isValidIPV4Address = this.patterns.isValidIPv4(url.hostname);
      if (!isValidIPV4Address) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }
}
