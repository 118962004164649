import { CdkPortal } from '@angular/cdk/portal';
import {
  AfterViewInit, Component, ElementRef, HostBinding, HostListener, inject, Inject, OnDestroy,
  OnInit, Renderer2, TemplateRef, ViewChild
} from '@angular/core';
import { SiAppListService } from '@building-x/common-ui-ng';
import {
  ChildrenId, FrameInfo, FullQParamId, HfwFrame, IHfwMessage, ISnapInConfig, MessageParameters, MobileNavigationService,
  PrimaryBarConfig, PrimaryItem, StateService, VerticalBarConfig, VerticalBarItem
} from '@gms-flex/core';
import { NavBarPrimaryComponent, UtilityPanelsEn } from '@gms-flex/navigation-bar';
import {
  APP_CONFIGURATION_TOKEN, AppConfiguration, AppRightsService,
  GraphicsCommonTemplateServiceBase, LicenseOptionsService, Operation,
  SiteSelectionServiceBase
} from '@gms-flex/services';
import { isNullOrUndefined, ProductInfo, ProductService } from '@gms-flex/services-common';
import { EventsCommonServiceBase } from '@gms-flex/snapin-common';
import { SystemBrowserSnapInService } from '@gms-flex/system-browser';
import { NavbarItem, SiSidePanelService } from '@simpl/element-ng';
import { map, of, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { SelectedSite } from '../bx-gms-mapper/event/event-bx-substitute-proxy.service';
import { PersistenceService } from "../core/services/persistence.service";
import { CustomerSelectorStateService } from '../customer-selection/customer-selector-state.service';
import { PortfolioSnapinService } from '../features/portfolio/portfolio-snapin.service';
import { RightPanelStateService } from '../right-panel/right-panel-state.service';
import { SytemRPComponent } from '../right-panel/sytem-rp/sytem-rp.component';
import { PrimaryItemStore } from './primary-item.store';

export const NAVBAR_RESOURCE_KEY = 'NAVBAR.';
const appSwitcherSourceUrl = `${environment.bxPlatform.webAppVerticalApiUrl}/api/v1/me/apps`
@Component({
  selector: 'cbms-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  providers: [
    SystemBrowserSnapInService
  ]
})
export class MainComponent implements AfterViewInit, OnInit, OnDestroy {
  private static readonly aboutId = 'about-frame-id';
  private static readonly portfolioManagerId = 'portfolio-manager';
  private static readonly verticalBarSystemManagerId = 'NAVBAR.system';
  private static readonly verticalBarEventsId = 'NAVBAR.events';

  private static readonly queryMobileVerticalBarDrawer = `[class*="${'collapse-toggle mobile-drawer'}"]`;
  @ViewChild('graphicsCommon', { static: true }) public graphicsCommonTemplate: TemplateRef<any>;

  @HostBinding('class') public get classAttribute(): string {
    return 'has-navbar-fixed-top si-layout-fixed-height h-100';
  }

  public isCustomerSelectorActive = false;
  public appSwitcherData: any;

  public frames: HfwFrame[];
  public primaryItems: NavbarItem[] = [];
  public currentVerticalItems: NavbarItem[];

  public isVerticalVisible = false;

  public currentFrameId: string;
  public rightPanelExpandFlag: boolean;

  public isSiteTitleVisible: boolean | undefined = false;
  public selectedSiteName: string | undefined;

  @ViewChild('systemRP', { read: SytemRPComponent }) public systemRP!: SytemRPComponent;
  @ViewChild('navbar', { read: NavBarPrimaryComponent }) public navbar!: NavBarPrimaryComponent;
  @ViewChild('custSelector', { read: CdkPortal }) public customerSelector!: CdkPortal;

  public isRPCollapsible = false;

  private primaryBarConfig: PrimaryBarConfig;

  // <primaryItemId - primaryStore>
  private readonly primaryItemMap: Map<string, PrimaryItemStore> = new Map<string, PrimaryItemStore>();

  private readonly verticalItemMap: Map<string, NavbarItem[]> = new Map<string, NavbarItem[]>();
  private readonly verticalConfigPerFrame: Map<string, string> = new Map<string, string>();
  private readonly verticalNavItemPerFrame: Map<string, NavbarItem> = new Map<string, NavbarItem>();

  private verticalBarConfigs: VerticalBarConfig[];

  private readonly subscriptions: Subscription[] = [];

  private rightPanelOpenSub: Subscription;
  private rightPanelTempContentSub: Subscription;
  private viewChangeSub: Subscription;

  private selectedSite: SelectedSite | undefined = undefined;
  private selectedNodeMessage: MessageParameters | undefined;

  private pendingUtilityId: UtilityPanelsEn | undefined;
  private brandProductName = '';

  private isMobileView = false;
  private hasSystemManagerQParam = false;

  private readonly appConfig: AppConfiguration = inject<AppConfiguration>(APP_CONFIGURATION_TOKEN);

  constructor(private readonly config: ISnapInConfig,
    private readonly messageBroker: IHfwMessage,
    private readonly stateService: StateService,
    @Inject(SiSidePanelService) private readonly sidePanelService: SiSidePanelService,
    private readonly rightPanelState: RightPanelStateService,
    private readonly appRightsService: AppRightsService,
    private readonly licenseOptionsService: LicenseOptionsService,
    private readonly productService: ProductService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly mobileNavigationService: MobileNavigationService,
    private readonly csStateService: CustomerSelectorStateService,
    private readonly graphicsCommonTemplateServiceBase: GraphicsCommonTemplateServiceBase,
    private readonly eventsCommonService: EventsCommonServiceBase,
    private readonly siAppListService: SiAppListService,
    private readonly siteSelectionService: SiteSelectionServiceBase,
    private readonly portfolioSnapinService: PortfolioSnapinService,
    private readonly persistenceService: PersistenceService,
    @Inject(SystemBrowserSnapInService) private readonly systemBrowserSnapinService: SystemBrowserSnapInService
  ) {
    this.productService.getProductSettings().subscribe((productSettings: ProductInfo) => {
      if (productSettings != null) {
        this.brandProductName = productSettings.brandProductName;
      }
    });
  }

  public ngOnInit(): void {
    this.frames = this.stateService.getFrames() ?? [];

    this.updateDataStructure();

    this.subscribeToFrameChange();

    // Mobile visibility
    this.isMobileView = this.mobileNavigationService.mobileOnlyVisibilityLast ?? false;

    this.subscriptions.push(this.mobileNavigationService.mobileOnlyVisibility$.subscribe((isVisible: boolean) => {
      this.isMobileView = isVisible;
    }));

    const fullQParamId = new FullQParamId('system-manager', 'SystemQParamService', 'primary');
    this.subscriptions.push(
      this.messageBroker.getQueryParam(fullQParamId).subscribe(qParam => {
        this.hasSystemManagerQParam = !!qParam
      })
    );
  }

  public ngAfterViewInit(): void {
    // Add a subscription to the subscriptions array to manage lifecycle and prevent memory leaks
    this.subscriptions.push(
      // Start by fetching all permissions from the csStateService
      this.csStateService
        .getAllPermissions()
        .pipe(
          // Use switchMap to handle the observable chain and switch to a new observable
          switchMap(allPermissions => {
            // Check if permissions exist in the response; if not, return an empty array as a fallback observable
            if (!allPermissions) {
              return of([]); // Emits an empty array to avoid breaking the chain
            }
            // Fetch the app switcher data using siAppListService
            return this.siAppListService.getAppSwitcherData(appSwitcherSourceUrl).pipe(
              // Use map to process the fetched appSwitcherAllData
              map(appSwitcherAllData => {
                // Filter the app switcher data based on permissions
                return this.filterAppSwitcherData(appSwitcherAllData, allPermissions);
              })
            );
          })
        )
        // Subscribe to the final filtered data
        .subscribe(filteredData => {
          // Assign the filtered data to the appSwitcherData property which will be displayed in SiAppSwitcher (NAVBAR on the top-left)
          this.appSwitcherData = filteredData;
        })
    );

    if (this.navbar && this.pendingUtilityId !== undefined) {
      this.navbar.showUtility(this.pendingUtilityId);
    }
    this.verticalBarVisible();
    // Create a MutationObserver to observe changes in the DOM
    const observer = new MutationObserver(() => {
      this.verticalBarVisible();
    });
    // Start observing the entire DOM, including child elements
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true
    });

    this.graphicsCommonTemplateServiceBase.GraphicsCommonTemplate = this.graphicsCommonTemplate;

    // Show/Hide Customer - Partition Selection Panel
    this.navbar.customerSelector$.subscribe(() => {
      this.showCustomerSelector();
    });

    // Site selection subscription
    this.siteSelectionService.selectedSite.subscribe(selectedSite => {
      this.selectedSite = selectedSite; // If a site is selected update vertical navigation bar
      this.updateDataStructure();
      this.isSiteTitleVisible = selectedSite.singleSiteActive;
      this.selectedSiteName = selectedSite.siteName;
      this.subscribeToFrameChange();
    });

    // Node message selection subscription
    this.subscriptions.push(this.systemBrowserSnapinService.selectedNodeMessage.subscribe(selectedNodeMessage => {
      this.selectedNodeMessage = selectedNodeMessage;
    }));
  }

  /**
   * Filters appSwitcherAllData based on the combined permissions from the root and all partitions.
   *
   * @param {any[]} appSwitcherAllData - The array of app data objects, each containing details about an app available in app-switcher.
   * @param {any} allPermissions - The permissions object containing root permissions and partitions with permissions.
   * 
   * @returns {any[]} - A filtered array of appSwitcherAllData objects that match the combined valid permissions.
   */
  public filterAppSwitcherData(appSwitcherAllData: any[], allPermissions: any): any[] {
    // Step 1: Extract and combine permissions
    const rootPermissions = allPermissions?.permissions || [];
    const partitionPermissions = (allPermissions?.partitions || [])
      .flatMap((partition: any) => partition.permissions || []);

    // Combine all permissions into a single array
    const combinedPermissions = [...rootPermissions, ...partitionPermissions];

    // Step 2: Filter combined permissions to include only those starting with "app."
    const validPermissions = new Set(
      combinedPermissions.filter(permission => permission.startsWith('app.'))
    );

    // Step 3: Filter appSwitcherAllData based on valid permissions
    return appSwitcherAllData.filter(app => validPermissions.has(app.id));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
    this.unsubscribeRightPanelIsOpen();
    this.unsubscribeRightPanelTempContent();
    this.unsubscribeViewChangeSub();
  }

  /**
   * Manages the visibility of the vertical bar (mobile drawer) based on the current view.
   * In the mobile version, this method hides the vertical bar by setting its display property to 'none'.
   * In desktop or non-mobile views, it ensures the vertical bar is visible by setting its display property to 'block'.
   */
  public verticalBarVisible(): void {
    // Query and select all elements matching the mobile vertical bar drawer selector
    const elements = this.el.nativeElement.querySelectorAll(MainComponent.queryMobileVerticalBarDrawer);

    // Check if the current view is a mobile view
    if (this.isMobileView) {
      // Hide the vertical bar in the mobile version
      elements.forEach((element: HTMLElement) => {
        this.renderer.setStyle(element, 'display', 'none');
      });
    } else {
      // Ensure the vertical bar is visible in desktop or non-mobile views
      elements.forEach((element: HTMLElement) => {
        this.renderer.setStyle(element, 'display', 'block');
      });
    }
  }

  public contentResize(): void {
    // It will check the current state of right Panel whether open or close.
    const frameRPState = this.rightPanelState.getRightPanelState(this.currentFrameId);
    this.rightPanelExpandFlag = frameRPState;
  }

  public primaryItemClicked(itemId: string): void {
    const itemStore: PrimaryItemStore | undefined = this.primaryItemMap.get(itemId);
    if (itemStore) {
      const frameId: string = !isNullOrUndefined(itemStore.lastFrameIdSelected) ? itemStore.lastFrameIdSelected : itemStore.frameIds[0];
      this.eventsCommonService.destinationFrame = frameId;
      this.messageBroker.switchToNextFrame(frameId).subscribe((frameChanged: boolean) => {
        //
      });
    }
  }

  public switchFrame(frame: string): void {
    if (frame === "system-manager") {
      if (!this.hasSystemManagerQParam && this.persistenceService.singleSiteQParam) {
        const partitionIdAndSiteId = this.persistenceService.singleSiteQParam.split(":")[1].split(".")
        const partitionId = partitionIdAndSiteId[0]
        const siteId = partitionIdAndSiteId[1]
        this.portfolioSnapinService.selectSiteInSystemManager(partitionId, siteId)
        return;
      }
      this.messageBroker.switchToNextFrame(frame, this.selectedNodeMessage).subscribe((frameChanged: boolean) => { });
      return;
    }

    this.messageBroker.switchToNextFrame(frame, this.portfolioSnapinService.getSelectionMessage()).subscribe((frameChanged: boolean) => { });
  }

  public switchView(frame: string, viewId: string): void {
    this.messageBroker.changeView(frame, viewId).subscribe((frameChanged: boolean) => { });
  }

  public onUtilityPanelChanged(utilityId: UtilityPanelsEn | null): void {
    if (utilityId != null) {
      this.rightPanelState.setUtilityRightPanelState(UtilityPanelsEn[utilityId]);
    } else {
      this.rightPanelState.resetUtilityRightPanelState();
    }
  }

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.altKey && (event.code === 'KeyO')) {
      this.showCustomerSelector();
    }
  }

  // This is a workaround to allow the resolution of the graphic step within the assisted treatment.
  public onResolveGraphicStep(resolveExecutionResult: any, step: any): void {
    resolveExecutionResult.emit({ status: 1, step: step });
  }

  public get notificationSupported(): boolean {
    return this.appConfig.notificationSupported;
  }

  public get appSwitcherSupported(): boolean {
    return this.appConfig.appSwitcherSupported;
  }

  public get partitionSelectionSupported(): boolean {
    return this.appConfig.partitionSelectionSupported;
  }

  private showCustomerSelector(): void {
    this.showHideLayout();
  }

  private showHideLayout(): void {
    if (this.hideLayout()) {
      return;
    }
    this.isCustomerSelectorActive = true;
    if (this.isMobileView) {
      this.mobileNavigationService.setRightPanelState(true);
      this.sidePanelService.toggle();
    }
    this.sidePanelService.showTemporaryContent(this.customerSelector).subscribe(() => this.hideLayout());
  }

  private hideLayout(): boolean {
    if (this.isCustomerSelectorActive) {
      this.isCustomerSelectorActive = false;
      this.mobileNavigationService.setRightPanelState(false);
      this.sidePanelService.hideTemporaryContent();
      return true;
    }
    return false;
  }

  private subscribeToFrameChange(): void {
    const frameInfo$ = this.messageBroker.getCurrentWorkAreaFrameInfo();
    if (!frameInfo$) {
      return;
    }

    this.subscriptions.push(frameInfo$.subscribe(frame => {
      if (frame) {
        this.unsubscribeViewChangeSub();
        this.viewChangeSub = this.messageBroker.getCurrentWorkAreaView().subscribe(view => {

          const isFirstFrameIdAssignment = this.currentFrameId === undefined;
          this.currentFrameId = frame.id;

          if (isFirstFrameIdAssignment) {
            const currentUtilityId = this.rightPanelState.getCurrentUtilityId();
            if (currentUtilityId) {
              this.pendingUtilityId = UtilityPanelsEn[currentUtilityId];
            }
          }
          this.unsubscribeRightPanelIsOpen();
          this.unsubscribeRightPanelTempContent();

          this.updateRightPanelComponentOnFrameChange();
          if (this.frameHasApplicationRightPanel(this.currentFrameId)) {
            this.subscribeRightPanelIsOpen();
          }
          this.subscribeRightPanelTempContent();

          // check if the vertical-bar is required:
          this.updateVerticalBarInputs(frame);

          // UPDATE Primary Item selected
          this.primaryItems.filter(x => x.isActive === true).forEach((item, index, items) => {
            item.isActive = false;
          });
          const primaryItemText: string = this.findPrimaryItemTextForFrame(frame.id);
          this.primaryItems.filter(x => x.title === NAVBAR_RESOURCE_KEY + primaryItemText).forEach((item, index, items) => {
            item.isActive = true;
          });
          const primaryItemStore = this.primaryItemMap.get(primaryItemText);
          if (primaryItemStore) {
            primaryItemStore.lastFrameIdSelected = frame.id;
          }

          // UPDATE Vertical Item selected
          this.verticalNavItemPerFrame.forEach((item: NavbarItem) => {
            item.isActive = false;
          });

          const selectedItemId = (view) ? frame.id + '.' + view : frame.id;

          const verticalNavItem = this.verticalNavItemPerFrame.get(selectedItemId)
          if (verticalNavItem) {
            verticalNavItem.isActive = true;
          } else {
            const verticalNavItemPerFrame = this.verticalNavItemPerFrame.get(frame.id);
            if (verticalNavItemPerFrame) {
              // in case of vertical bar entries 'without' primary bar entries (e.g for Account, Notification Settings):
              verticalNavItemPerFrame.isActive = true;
            }
          }

        });
      }
    }));
  }

  private checkNotificationLicenseRights(notificationRecipientViewer: any, notificationRecipientGroupViewer: any,
    notificationTemplateViewer: any, verticalEntries: any[]): boolean {
    if (verticalEntries.length > 0 && verticalEntries[0].items !== undefined) {
      if (notificationRecipientViewer !== -1 && notificationRecipientGroupViewer !== -1 && notificationTemplateViewer !== -1) {
        const appRightsNotification = this.appRightsService.getAppRights(1000);
        const showAppRightsReno = 32000;
        const showAppRightsRenoPlus = 32001;
        const licenseOptions = this.licenseOptionsService.getLicenseOptionsRights('sbt_gms_mns_opt_SMTPEmail');
        if (appRightsNotification != null) {
          const show: Operation[] = appRightsNotification.Operations.filter(f => f.Id === showAppRightsReno ||
            f.Id === showAppRightsRenoPlus);
          if (show.length === 0) {
            // remove Notification from vertical bar if all apprights of reno and reno plus are false
            return false;
          }
        } else {
          // remove Notification from vertical bar if apprights are not found
          return false;
        }

        // remove notification viewer if license is not available or required > available
        if (licenseOptions) {
          if (licenseOptions.Available === -1) {
            return true;
          } else if (licenseOptions.Available === 0) {
            return false;
          } else {
            // required <= assigned
            return licenseOptions.Required <= (licenseOptions.Available) ? true : false;
          }
        }

        return true; // TODO strictNullChecks: is this correct? 
        // if (appRightsNotification != null) && show.length !== 0 we come to evaluate licenseOptions.
        // if licenseOptions is null or undefined, is it ok to return true?
      }
      return false; // TODO strictNullChecks: ok to return false here?
    }
    return false; // TODO strictNullChecks: ok to return false here?
  }

  // TODO strictNullChecks: refactoring of the previous function to reduce complexity while removing strictNullChecks errors
  private checkNotificationLicenseRightsNew(notificationRecipientViewer: any, notificationRecipientGroupViewer: any,
    notificationTemplateViewer: any, verticalEntries: any[]): boolean {

    if (!verticalEntries || verticalEntries?.[0].items === undefined) {
      return false;
    }

    if (notificationRecipientViewer === -1 || notificationRecipientGroupViewer === -1 || notificationTemplateViewer === -1) {
      return false;
    }

    const appRightsNotification = this.appRightsService.getAppRights(1000);
    const showAppRightsReno = 32000;
    const showAppRightsRenoPlus = 32001;

    if (appRightsNotification === null || appRightsNotification === undefined) {
      // remove Notification from vertical bar if app rights are not found
      return false;
    }

    const show: Operation[] = appRightsNotification.Operations.filter(f => f.Id === showAppRightsReno || f.Id === showAppRightsRenoPlus);
    if (show.length === 0) {
      // remove Notification from vertical bar if all app rights of reno and reno plus are false
      return false;
    }

    // remove notification viewer if license is not available or required > available
    const licenseOptions = this.licenseOptionsService.getLicenseOptionsRights('sbt_gms_mns_opt_SMTPEmail');
    if (!licenseOptions) {
      return true; // TODO strictNullChecks: is this correct? see comment in the original implementation above
    }

    if (licenseOptions.Available === -1) {
      return true;
    }
    if (licenseOptions.Available === 0) {
      return false;
    }
    // required <= assigned
    return licenseOptions.Required <= (licenseOptions.Available) ? true : false;
  }

  private checkOperatorTasksRights(): boolean {
    const operatorTaskSnapinId = 110000;
    const showSnapin = 3520004;
    const operatorTaskLicenseId = 'sbt_gms_opt_operatortasks';

    const appRightsOperatorTask = this.appRightsService.getAppRights(operatorTaskSnapinId);
    const showOp: Operation | undefined = appRightsOperatorTask?.Operations?.find(value => value.Id === showSnapin);

    let licenseAvailable = false;
    const licenseOptionsDocument = this.licenseOptionsService.getLicenseOptionsRights(operatorTaskLicenseId);
    if (licenseOptionsDocument) {
      if (licenseOptionsDocument?.Available === -1) {
        licenseAvailable = true;
      } else {
        licenseAvailable = licenseOptionsDocument.Required <= (licenseOptionsDocument.Available);
      }
    }

    return showOp !== undefined && licenseAvailable;
  }

  // TODO strictNullChecks: changes for strictNullChecks to this method lead to the refactor in updateVerticalBarInputsNew
  private updateVerticalBarInputs(frame: FrameInfo): void {
    const frameHasAConfig = this.verticalConfigPerFrame.has(frame.id);
    let verticalEntries = [];

    if (frameHasAConfig) {
      verticalEntries = this.verticalItemMap.get(this.verticalConfigPerFrame.get(frame.id));
    }

    // Filter out specific entries for portfolio-manager
    if ((frame.id === MainComponent.portfolioManagerId || frame.id === MainComponent.aboutId) && !this.selectedSite?.buildingsIds) {
      verticalEntries = verticalEntries.filter(
        (entry: any) => entry.title !== MainComponent.verticalBarSystemManagerId && entry.title !== MainComponent.verticalBarEventsId
      );
    }

    // Check notification for application rights and license
    if (verticalEntries.length > 0 && verticalEntries[0].items !== undefined) {
      // Fetch notification entry from vertical entries
      const notificationViewer = verticalEntries.findIndex((i: any) => i.title === "NAVBAR.Notification");
      if (notificationViewer !== -1) {
        const notificationRecipientViewer = verticalEntries[notificationViewer].items.findIndex(
          (i: any) => i.title === "NAVBAR.Recipient-View"
        );
        const notificationRecipientGroupViewer = verticalEntries[notificationViewer].items.findIndex(
          (i: any) => i.title === "NAVBAR.Group-View"
        );
        const notificationTemplateViewer = verticalEntries[notificationViewer].items.findIndex(
          (i: any) => i.title === "NAVBAR.Template-View"
        );

        if (!this.checkNotificationLicenseRights(
          notificationRecipientViewer,
          notificationRecipientGroupViewer,
          notificationTemplateViewer,
          verticalEntries
        )) {
          // Remove notification entry if no application rights or license of notification
          verticalEntries.splice(notificationViewer, 1);
        }
      }
    }

    const countEntries = this.countVerticalEntries(verticalEntries);

    // Collapse vertical menu in system manager
    if (frame.id === "system-manager") {
      verticalEntries = this.collapseVerticalEntries(verticalEntries);
    }

    this.isVerticalVisible = frameHasAConfig && countEntries > 0;
    if (countEntries > 0) {
      this.currentVerticalItems = verticalEntries;
    } else {
      this.currentVerticalItems = [];
    }
  }

  private updateVerticalBarInputsNew(frame: FrameInfo): void {
    let verticalEntries: NavbarItem[] | undefined = [];
    const verticalConfig = this.verticalConfigPerFrame.get(frame.id);
    if (verticalConfig) {
      verticalEntries = this.verticalItemMap.get(verticalConfig);
    }

    this.currentVerticalItems = [];

    if (!verticalConfig || !verticalEntries || verticalEntries.length == 0) {
      this.isVerticalVisible = false;
      return;
    }

    // Check notification for application rights and license

    // Fetch notification entry from vertical entries
    const notificationViewer = verticalEntries.findIndex((i: any) => i.title === 'NAVBAR.Notification');
    if (notificationViewer !== -1) {
      const verticalEntryItems = verticalEntries[notificationViewer]?.items;
      if (verticalEntryItems) {
        const notificationRecipientViewer = verticalEntryItems.findIndex((i: any) => i.title === 'NAVBAR.Recipient-View');
        const notificationRecipientGroupViewer = verticalEntryItems.findIndex((i: any) => i.title === 'NAVBAR.Group-View');
        const notificationTemplateViewer = verticalEntryItems.findIndex((i: any) => i.title === 'NAVBAR.Template-View');
        if (!this.checkNotificationLicenseRights(
          notificationRecipientViewer,
          notificationRecipientGroupViewer,
          notificationTemplateViewer,
          verticalEntries
        )) {
          // Remove notification entry if no application rights or license of notification
          verticalEntries.splice(notificationViewer, 1);
        }
      }
    }

    // Collapse vertical menu in system manager
    if (frame.id === 'system-manager') {
      verticalEntries = this.collapseVerticalEntries(verticalEntries);
    }

    const countEntries = this.countVerticalEntries(verticalEntries);
    this.isVerticalVisible = countEntries > 1;
    if (countEntries > 1) {
      this.currentVerticalItems = verticalEntries;
    }
  }

  private frameHasApplicationRightPanel(frameId: string): boolean {
    return this.rightPanelState.hasRightPanelState(frameId);
  }

  private subscribeRightPanelIsOpen(): void {
    this.rightPanelOpenSub = this.sidePanelService.isOpen$.subscribe(isOpen => {
      if (this.frameHasApplicationRightPanel(this.currentFrameId) &&
        !this.sidePanelService.isTemporaryOpen()) {
        this.rightPanelState.setRightPanelState(this.currentFrameId, isOpen);
      }
    });
  }

  private unsubscribeRightPanelIsOpen(): void {
    if (this.rightPanelOpenSub != null) {
      this.rightPanelOpenSub.unsubscribe();
    }
  }

  private subscribeRightPanelTempContent(): void {
    this.rightPanelTempContentSub = this.sidePanelService.tempContent$.subscribe(value => {
      if (!this.frameHasApplicationRightPanel(this.currentFrameId)) {
        if (this.sidePanelService.isOpen() && value === undefined) {
          this.sidePanelService.toggle();
        }
      }

      // if (this.sidePanelService.isOpen() && value instanceof CdkPortal && value.context?.utilityId != null) {
      //   this.rightPanelState.setUtilityRightPanelState(value.context.utilityId);
      // } else {
      //   this.rightPanelState.resetUtilityRightPanelState();
      // }
    });
  }

  private unsubscribeViewChangeSub(): void {
    if (this.viewChangeSub != null) {
      this.viewChangeSub.unsubscribe();
    }
  }

  private unsubscribeRightPanelTempContent(): void {
    if (this.rightPanelTempContentSub != null) {
      this.rightPanelTempContentSub.unsubscribe();
    }
  }

  private updateRightPanelComponentOnFrameChange(): void {
    const frameHasAppRP = this.frameHasApplicationRightPanel(this.currentFrameId);
    this.isRPCollapsible = frameHasAppRP;

    if (frameHasAppRP && !this.sidePanelService.isTemporaryOpen()) {
      const frameRPState = this.rightPanelState.getRightPanelState(this.currentFrameId);
      if (frameRPState !== this.sidePanelService.isOpen()) {
        this.sidePanelService.toggle();
      }
    } else if (!frameHasAppRP && this.sidePanelService.isOpen() && !this.sidePanelService.isTemporaryOpen()) {
      this.sidePanelService.close();
    }
  }

  private countVerticalEntries(verticalEntries: any[]): number {
    let count = 0;
    verticalEntries.forEach(item => {
      if (!isNullOrUndefined(item.items)) {
        count += item.items.length;
      } else {
        count++;
      }
    });
    return count;
  }

  private collapseVerticalEntries(verticalEntries: any[]): any[] {
    verticalEntries.forEach(item => {
      if (!isNullOrUndefined(item.items) && (item.items.length > 0)) {
        if (!isNullOrUndefined(item.expanded)) {
          item.expanded = false;
        }
      }
    });

    return verticalEntries;
  }

  private findPrimaryItemTextForFrame(frameId: string): string {
    let res = '';
    this.primaryItemMap.forEach((store, key) => {
      const id: string | undefined = store.frameIds.find(s => s === frameId);
      if (id !== undefined) {
        res = key;
      }
    });

    return res;
  }

  private updateDataStructure(): void {
    this.updatePrimaryBarItems();
    this.updateVerticalBarItems();
  }

  private updatePrimaryBarItems(): void {
    this.primaryBarConfig = this.config.getPrimaryBarConfig();

    if (!isNullOrUndefined(this.primaryBarConfig)) {
      if (!isNullOrUndefined(this.primaryBarConfig.primaryItems)) {
        this.cleanUpPrimaryItems();

        const tasksFrameIndex = this.primaryBarConfig.primaryItems.findIndex(item => item.id === 'tasks');
        if (tasksFrameIndex > -1) {
          const tasksFrameHasRights = this.checkOperatorTasksRights();
          if (!tasksFrameHasRights) {
            this.primaryBarConfig.primaryItems.splice(tasksFrameIndex, 1);
          }
        }

        this.primaryBarConfig.primaryItems.forEach((item: PrimaryItem) => {
          if (!isNullOrUndefined(item.childrenIds) && item.childrenIds.length > 0 && !this.primaryItemMap.has(item.id)) {
            const primaryStore: PrimaryItemStore = new PrimaryItemStore();
            primaryStore.frameIds = [];
            item.childrenIds.forEach((element: ChildrenId) => {
              primaryStore.frameIds.push(element.id);
            });

            this.primaryItemMap.set(item.id, primaryStore);

            if (item.id == 'events') {
              this.primaryItems.splice(1, 0, {
                title: NAVBAR_RESOURCE_KEY + item.id,
                action: this.primaryItemClicked.bind(this, item.id)

              })
            } else {
              this.primaryItems.push(
                {
                  title: NAVBAR_RESOURCE_KEY + item.id,
                  // icon: frame.iconClass, // actually there is no icon displayed for primary items.
                  action: this.primaryItemClicked.bind(this, item.id)
                  // isActive: isActive // TODO: evaluate this
                }
              );
            }
          }
        });
      }
    }
  }

  private updateVerticalBarItems(): void {
    this.verticalBarConfigs = this.config.getVerticalBarConfig();

    if (!isNullOrUndefined(this.verticalBarConfigs)) {
      this.verticalBarConfigs.forEach((item: VerticalBarConfig) => {
        let items: NavbarItem[] = [];

        // Filter unwanted items for "portfolio-manager"
        if (item.id === MainComponent.portfolioManagerId) {
          items = item.verticalBarItems.filter(
            (subItem: VerticalBarItem) => subItem.id !== "system" && subItem.id !== "events"
          ).map((subItem: VerticalBarItem) => this.createNavItem(item.id, subItem));
        } else {
          // For other items, process normally
          items = item.verticalBarItems.map((subItem: VerticalBarItem) =>
            this.createNavItem(item.id, subItem)
          );
        }

        // Update the map with filtered or unfiltered items
        this.verticalItemMap.set(item.id, items);
      });
    }
  }

  private cleanUpPrimaryItems(): void {
    const toBeRemoved: string[] = [];
    this.primaryItemMap.forEach((store, key) => {
      if (this.primaryBarConfig.primaryItems.find(x => x.id === key) === undefined) {
        toBeRemoved.push(key);
      }
    });
    toBeRemoved.forEach(s => this.primaryItemMap.delete(s));
    this.primaryItems = this.primaryItems.filter(item => this.includedInPrimaryConfig(item));
  }

  private createNavItem(configId: string, subItem: VerticalBarItem): NavbarItem {
    const result: NavbarItem = { title: NAVBAR_RESOURCE_KEY + subItem.id, icon: subItem.icon };

    // Check if the item is a folder
    if (!isNullOrUndefined(subItem.verticalBarItems) && isNullOrUndefined(subItem.targetFrame)) {
      const children: NavbarItem[] = [];
      subItem.verticalBarItems.forEach((child: VerticalBarItem) => {
        children.push(this.createNavItem(configId, child));
      });
      result.items = children;
    } else if (!isNullOrUndefined(subItem.targetFrame)) {
      if (subItem.targetView) {
        result.action = this.switchView.bind(this, subItem.targetFrame, subItem.targetView);
        this.verticalConfigPerFrame.set(subItem.targetFrame, configId);
        this.verticalNavItemPerFrame.set(`${subItem.targetFrame}.${subItem.targetView}`, result);
      } else {
        result.action = this.switchFrame.bind(this, subItem.targetFrame);
        this.verticalConfigPerFrame.set(subItem.targetFrame, configId);
        this.verticalNavItemPerFrame.set(subItem.targetFrame, result);
      }
    }

    return result;
  }

  private includedInPrimaryConfig(item: any): boolean {
    const itemId = item.title.replace(NAVBAR_RESOURCE_KEY, '');
    return (this.primaryBarConfig.primaryItems.find(x => x.id === itemId) != null);
  }

}
