import { Injectable } from "@angular/core";
import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointAttributes, PointValue, PointValueTypeBasic } from "src/app/bx-services/point/point-proxy.model";

import { formatNumericString } from "../../../core/shared/string-utils";
import { DccDataType, PointDataType, PointTypeService } from "../point-type.service";
import { GlobalTextGroups, TextGroupBxService } from "../text-group-bx.service";
import { PropertyMapper } from "./property-mapper";

// do not change this name, it is the BacNet name for this property and used in property viewer as well!
export const bacPrioArrayPropertyName = 'Priority_Array';

@Injectable({
  providedIn: 'root'
})
export class BacPrioArrayMapperService extends PropertyMapper {

  constructor(
    private readonly textGroupBxService: TextGroupBxService,
    private readonly pointTypeService: PointTypeService,
    private readonly qudtPipe: SiqudtUnitConversionPipe) {
    super();
  }

  public createBacPrioArray(pointAttributes: PointAttributes, order: number): PropertyDetails {

    const txgCurrentPrio = this.textGroupBxService.getGlobalTextGroup(GlobalTextGroups.TextGroupIdCurrentPriority);

    const prios: string[] = []
    for (const [, value] of Object.entries(txgCurrentPrio.enum)) {
      prios.push(value?.label);
    }
    const dccUnit = this.getDccUnit(this.qudtPipe, pointAttributes.unit);

    // Workaraound:
    // The property viewer shows the priority array values only based on the raw values and not the display values for the type ExtendedBool
    // Thus we create a ExtendedEnum
    // See also issue: https://code.siemens.com/horizon/apps/app-cbms/-/issues/11
    let propType = this.pointTypeService.getDccType(pointAttributes);
    if (propType === DccDataType.ExtendedBool) {
      propType = DccDataType.ExtendedEnum;
    }
    // End workaround

    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: bacPrioArrayPropertyName,
      Descriptor: 'Priority Array',
      DisplayType: 1, // controls prio array
      IsArray: true,
      Order: order,
      Resolution: this.evalResolution(pointAttributes),
      Type: propType,
      Value: undefined,
      Min: formatNumericString(pointAttributes?.minimum),
      Max: formatNumericString(pointAttributes?.maximum),
      UnitDescriptor: dccUnit?.unit,
      UnitId: dccUnit?.id,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      DisplayOffNormalOnly: false,
      NormalValue: undefined,
      TextTable: undefined,
      PropertyType: 0, // Type = 0,Indexed = 1,Functions = 2
      ElementLabels: prios
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return propDetails;
  }

  public createBacPrioArrayValue(
    subGms: SubscriptionGmsVal, pointId: string, pointValue: PointValue, pointAttributes: PointAttributes, hide: boolean = false): ValueDetails | undefined {
    // TODO: finalize quality bits
    // let displayValue = '#NaN';

    let displayValue = '#NaN';
    let rawValue = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        if (pointValue?.attributes?.bac_prio) {
          rawValue = this.evalPrioArrayValue(pointValue.attributes.bac_prio);
          displayValue = this.calculatePrioArrayDisplayValue(pointId, pointValue.attributes.bac_prio);
        } else {
          // there is no bac prio property
          return undefined;
        }
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: pointAttributes ? this.evalPrioArrayValueType(pointAttributes) : DccDataType.BasicString,
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: true,
      Value: {
        // the bit positions which are set (e.g. value=6 => bit0=false, bit1=true, bit2=true, bit3=false)
        Value: rawValue,
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0',
        IsPropertyAbsent: hide
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private evalPrioArrayValue(bacPrio: PointValueTypeBasic[]): string {
    // Remark:
    // Unset entries (null) are formatted in DesigoCC to "NaN". Example: ["NaN","NaN","NaN","NaN",0.3,"NaN",...]
    // The stringify method formats them to null. Example:  [null,null,null,null,0.3,null,...]
    // Property Viewer also handles null properly according to tests.
    // Thus: doing the more simple and also hopefully more clean approach:
    // return JSON.stringify(bacPrio);
   
    // Workaraound:
    // The property viewer shows the priority array values only based on the raw values and not the displayed values for the type ExtendedBool
    // Thus we create an ExtendedEnum and need to convert the raw values (true, false) to 0 and 1
    // See also issue: https://code.siemens.com/horizon/apps/app-cbms/-/issues/11
    const arr: any[] = [];
    bacPrio.forEach(item => {
      if (item !== null && (item === true || item === false)) {
        item = (item === true) ? 1 : 0;
      }
      arr.push(item);
    });
    return JSON.stringify(arr);
    // End workaround
  }

  private calculatePrioArrayDisplayValue(pointId: string, bacPrio: PointValueTypeBasic[]): string {
    const arr: any[] = [];
    bacPrio.forEach(item => {
      if (item !== null) {
        const resolvedItem = this.textGroupBxService.resolveRawValue(pointId, item.toString());
        if (resolvedItem !== undefined) {
          item = resolvedItem;
        }
      }
      arr.push(item);
    });
    return JSON.stringify(arr);
  }

  private evalPrioArrayValueType(pointAttributes: PointAttributes): DccDataType {
    if (pointAttributes.dataType === PointDataType.Number) {
      return DccDataType.BasicFloat;
    } else if (pointAttributes.dataType === PointDataType.Integer) {
      return DccDataType.BasicInt;
    } else if (pointAttributes.dataType === PointDataType.Boolean) {
      return DccDataType.BasicBool;
    } else if (pointAttributes.dataType === PointDataType.String) {
      return DccDataType.BasicString;
    } else {
      /* eslint-disable-next-line no-console */
      console.error(`evalPrioArrayValueType.getDccType(): Point data type not handled: ${pointAttributes.dataType}`);
      return DccDataType.BasicString;
    }
  }
}
