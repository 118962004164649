import { AboutPopoverModule } from './about-popover/about-popover.module';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import {
  SiAccordionModule,
  SiActionDialogModule,
  SiCardModule,
  SiCircleStatusModule,
  SiColumnSelectionModalModule,
  SiContentActionBarModule,
  SiDropdownModule,
  SiEmptyStateModule,
  SiFilteredSearchModule,
  SiFormModule,
  SiInlineNotificationModule,
  SiLoadingSpinnerModule,
  SiMainDetailContainerModule,
  SiPromptDialogButtonsModule,
  SiResizeObserverModule,
  SiSearchBarModule,
  SiSplitModule,
  SiTreeViewModule
} from '@simpl/element-ng';
import { SiDatatableModule } from '@simpl/element-ng/datatable';
import { SiStepperModule } from '@simpl/element-ng/stepper';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { HfwControlsModule } from '@gms-flex/controls';
import { HFW_TRANSLATION_FILE_TOKEN } from '@gms-flex/services-common';
import {
  AssistedTreatmentProxyService,
  AssistedTreatmentProxyServiceBase,
  AssistedTreatmentService,
  EventService, GmsEasyNavigationBarServiceBase
} from '@gms-flex/services';
import { IObjectSelection, IQParamService } from '@gms-flex/core';
import { ObjectManagerServiceBase } from './object-manager-service/object-manager.service.base';
import { ObjectManagerService } from './object-manager-service/object-manager.service';
import { ObjectManagerCoreServiceBase } from './object-manager-core/object-manager-core.service.base';
import { ObjectManagerCoreService } from './object-manager-core/object-manager-core.service';
import { EventsCommonServiceBase } from './events/services/events-common.service.base';
import { EventsCommonService } from './events/services/events-common.service';
import { ObjectManagerComponent } from './object-manager/object-manager.component';
import { BrowserViewComponent } from './object-manager/browser-view/browser-view.component';
import { ServiceTemplateComponent } from './object-manager-service/service-template/service-template.component';
import {
  AlarmSuppressionPipe,
  FilterSelectorComponent
} from './object-manager/filter-selector/filter-selector.component';
import { FilterResultsComponent } from './object-manager/filter-results/filter-results.component';
import { TablesService } from './events/services/tables.service';
import { MemoPopoverServiceBase } from './memo-popover/services/memo-popover.service.base';
import { MemoPopoverService } from './memo-popover/services/memo-popover.service';
import { AboutObjectServiceBase } from './about-popover/services/about-object.service.base';
import { AboutObjectService } from './about-popover/services/about-object.service';
// import { ObjectListViewComponent } from './about-popover/view/object-list.component';
// import { ObjectPathViewComponent } from './about-popover/view/object-path.component';
// import { ObjectInfoViewComponent } from './about-popover/view/object-detail.component';
import { MemoPopoverComponent } from './memo-popover';
import { MemoViewComponent } from './memo-popover/view/memo.component';
import { ObjectSelectionService } from './shared/object-selection.service';
import { SystemQParamService } from './q-param-services/system-qparam.service';
import { EventQParamService } from './q-param-services/event-qparam.service';
import { TileFooterComponent } from './tile-footer/tile-footer.component';
import { TileBodyComponent } from './tile-body/tile-body.component';
import { DateFormatPipe, HistoryLogService, LogViewerRowDetailsComponent, LogViewerTableComponent } from './log-viewer';
import { LogViewerComponent } from './log-viewer/log-viewer.component';
import { ReportViewModule } from './report-viewer';
import { GmsGraphicsCommonModule } from './graphics-common/src/gms-graphics-common.module';
import { GraphicViewComponent } from './graphics-common/src/views/graphic-view.component';
import { GraphicsCommonComponent } from './graphics-common/src/snapin';
import { EventsModule } from './events';
import { DocumentViewerModule } from './document-viewer';
import { ValidationDialogModule } from './validation-dialog/validation-dialog.module';
import { ValidationMenuComponent } from './validation-dialog/validation-menu/validation-menu.component';
import { FilterPillComponent } from './om-filter-pill';
import { EventsValidationHelperService } from './events/services/events-validation-helper.service';
import { EventsValidationHelperServiceBase } from './events/services/events-validation-helper.service.base';
import { GmsEasyNavigationBarModule } from './gms-easy-navigation-bar/gms-easy-navigation-bar.module';
import { GmsEasyNavigationBarService } from "./gms-easy-navigation-bar/services/gms-easy-navigation-bar.service";

@NgModule({ declarations: [
  AlarmSuppressionPipe,
  BrowserViewComponent,
  DateFormatPipe,
  FilterPillComponent,
  FilterResultsComponent,
  FilterSelectorComponent,
  LogViewerComponent,
  LogViewerRowDetailsComponent,
  LogViewerTableComponent,
  MemoPopoverComponent,
  MemoViewComponent,
  // ObjectInfoViewComponent,
  // ObjectListViewComponent,
  // ObjectPathViewComponent,
  ObjectManagerComponent,
  ServiceTemplateComponent,
  TileBodyComponent,
  TileFooterComponent
],
exports: [
  DateFormatPipe,
  GmsEasyNavigationBarModule,
  GraphicsCommonComponent,
  GraphicViewComponent,
  LogViewerComponent,
  LogViewerRowDetailsComponent,
  LogViewerTableComponent,
  MemoPopoverComponent,
  ObjectManagerComponent,
  ReportViewModule,
  ServiceTemplateComponent,
  TileBodyComponent,
  TileFooterComponent,
  ValidationDialogModule,
  ValidationMenuComponent
],
schemas: [
  CUSTOM_ELEMENTS_SCHEMA
], imports: [AboutPopoverModule,
  AccordionModule,
  BsDropdownModule,
  CommonModule,
  DocumentViewerModule,
  EventsModule,
  FormsModule,
  GmsGraphicsCommonModule,
  HfwControlsModule,
  ModalModule,
  NgxDatatableModule,
  PopoverModule,
  ReportViewModule,
  SiAccordionModule,
  SiActionDialogModule,
  SiCardModule,
  SiCircleStatusModule,
  SiColumnSelectionModalModule,
  SiContentActionBarModule,
  SiDatatableModule,
  SiDropdownModule,
  SiEmptyStateModule,
  SiFilteredSearchModule,
  SiFormModule,
  SiInlineNotificationModule,
  SiLoadingSpinnerModule,
  SiMainDetailContainerModule,
  SiPromptDialogButtonsModule,
  SiResizeObserverModule,
  SiSearchBarModule,
  SiSplitModule,
  SiStepperModule,
  SiTreeViewModule,
  ValidationDialogModule], providers: [
  BsModalRef,
  provideHttpClient(withInterceptorsFromDi())
] })
export class GmsSnapInCommonModule {
  public static forRoot(): ModuleWithProviders<GmsSnapInCommonModule> {
    return {
      ngModule: GmsSnapInCommonModule,
      providers: [
        { provide: HFW_TRANSLATION_FILE_TOKEN, useValue: './@gms-flex/snapin-common/i18n/', multi: true },
        { provide: ObjectManagerServiceBase, useClass: ObjectManagerService },
        { provide: GmsEasyNavigationBarServiceBase, useClass: GmsEasyNavigationBarService },
        { provide: HistoryLogService, useClass: HistoryLogService },
        { provide: ObjectManagerCoreServiceBase, useClass: ObjectManagerCoreService },
        { provide: EventsCommonServiceBase, useClass: EventsCommonService },
        { provide: TablesService, useClass: TablesService },
        { provide: EventService, useClass: EventService },
        { provide: AssistedTreatmentService, useClass: AssistedTreatmentService },
        { provide: AssistedTreatmentProxyServiceBase, useClass: AssistedTreatmentProxyService },
        { provide: AboutObjectServiceBase, useClass: AboutObjectService },
        { provide: MemoPopoverServiceBase, useClass: MemoPopoverService },
        { provide: IObjectSelection, useClass: ObjectSelectionService },
        { provide: IQParamService, useClass: SystemQParamService, multi: true },
        { provide: IQParamService, useClass: EventQParamService, multi: true },
        { provide: EventsValidationHelperServiceBase, useClass: EventsValidationHelperService }
      ]
    };
  }
}
