import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConnectionState, LanguageInfo, SystemInfo, SystemsProxyServiceBase, SystemsResponseObject, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, map, Observable, of, scheduled, Subject, switchMap, take, throwError } from 'rxjs';

import { PartitionService } from '../../bx-services/subscription/partition.service';
import { ContextService } from '../state/context.service';
import { SystemsMapperBxToGmsService } from './systems-mapper-bx-to-gms.service';

const systemLanguages: LanguageInfo[] = [
  {
    /* eslint-disable @typescript-eslint/naming-convention */
    ArrayIndex: 0,
    Descriptor: 'English (United States)',
    Code: 'en-US'
    /* eslint-enable @typescript-eslint/naming-convention */
  }
];

@Injectable()
export class SystemsBxSubstituteProxyService implements SystemsProxyServiceBase {
  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _notifySystems: Subject<SystemInfo[]> = new Subject<SystemInfo[]>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly contextService: ContextService,
    private readonly partitionService: PartitionService,
    private readonly systemsMapper: SystemsMapperBxToGmsService) {

    this.contextService.selectedData$
      .pipe(takeUntilDestroyed())
      .subscribe(selectedData => {
        if (selectedData.partitions.length > 0) {
          this.traceService.info(TraceModules.systems, `Current selected partitions: ${selectedData.partitions.map(partition => partition.id).join()}`);
          this.getSystemsExt().subscribe(sro => {
            this._notifySystems.next(sro.Systems);
          });
        } 
      });

    this.traceService.info(TraceModules.systems, 'SystemsBxSubstituteProxyService created.');
  }

  public getSystemsExt(): Observable<SystemsResponseObject> {
    return this.createSystems().pipe(
      map(systemInfos => {
        /* eslint-disable @typescript-eslint/naming-convention */
        const sro: SystemsResponseObject = {
          Systems: systemInfos,
          Languages: systemLanguages,
          IdLocal: systemInfos[0].Id,
          IsDistributed: true
        };
        /* eslint-enable @typescript-eslint/naming-convention */
        return sro;
      })
    );
  }
  
  public getSystems(): Observable<SystemInfo[]> {
    return this.createSystems();
  }

  public getSystemsById(systemId?: string): Observable<SystemInfo[]> {
    return this.createSystems(systemId);
  }

  public getSystem(systemId: any): Observable<SystemInfo> {
    return this.createSystems().pipe(
      map(systemInfos => systemInfos.find(sInfo => sInfo.Id === systemId))
    );
  }

  public getSystemLocal(): Observable<SystemInfo> {
    return this.createSystems().pipe(
      map(systemInfos => systemInfos[0])
    );
  }

  public getSystemLanguages(): Observable<LanguageInfo[]> {
    return scheduled([systemLanguages], asapScheduler);
  }

  public subscribeSystems(): Observable<boolean> {
    return scheduled([true], asapScheduler);
  }

  public unSubscribeSystems(): Observable<boolean> {
    return scheduled([false], asapScheduler);
  }

  public systemsNotification(): Observable<SystemInfo[]> {
    return this._notifySystems.asObservable();
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public setSystemPath?(_systemId: string): Observable<any> {
    return throwError(() => new Error('SystemsBxSubstituteProxyService.setSystemPath(): Not Implemented!'));
  }

  public downloadCredentialsPack?(_systemId: string): Observable<any> {
    return throwError(() => new Error('SystemsBxSubstituteProxyService.downloadCredentialsPack(): Not Implemented!'));
  }

  public getCredentialsPack?(_url: string): Observable<any> {
    return throwError(() => new Error('SystemsBxSubstituteProxyService.getCredentialsPack(): Not Implemented!'));
  }

  private createSystems(systemId?: string): Observable<SystemInfo[]> {
    return this.contextService.selectedData$.pipe(
      take(1),
      switchMap(selectedData => {
        const partition = systemId ? selectedData.partitions.find(p => p.id === systemId) : undefined;
        const partitions = partition ? [partition] : selectedData.partitions;     
        return of(this.systemsMapper.mapPartitions(partitions));
      })
    );    
  }
}
