import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { asapScheduler, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GmsAlarm } from '../elements/gms-alarm';
import { GmsElement } from '../elements/gms-element';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsSymbolInstance } from '../elements/gms-symbol-instance';
import { AlarmState } from '../types/datapoint/gms-alarm-state';
import { Utility } from '../utilities/utility';

@Component({
  selector: '[gms-alarm]',
  template: `<svg:g #alarmGroup
                [attr.x]="x"
                [attr.y]="y"
                [attr.visibility]="GetVisible()"
                (click)="onAlarmClick()">
                <!--translate to center indication at the top left-->
                <!-- Multiple foreign objects are used to allow overlaying of alarm icons (e.g. background icon and tick icon) for Apple devices. -->
                <svg:g [attr.transform]="GetTransformations()" class="can-click-cursor">

                  <!--Background circle with Discipline indication-->
                  <svg *ngIf="alarm.State !== alarmState.None" [attr.x]="BackgroundOffset" class="alarm-elevation" [attr.y]="BackgroundOffset"
                  [attr.width]="BackgroundSize" [attr.height]="BackgroundSize" viewBox="-1 -1 22 22">
                    <ellipse cx="10" cy="10" rx="10" ry="10" class="alarm-background" />
                    <ellipse cx="18" cy="3" rx="2" ry="2" style="fill:{{alarm.DisciplineColor}}" />
                  </svg>

                  <svg:foreignObject [attr.width]="ICON_SIZE" [attr.height]="ICON_SIZE">
                    <!--Faulty, Unacknowledged-->
                    <xhtml:div *ngIf="alarm.State === alarmState.Unprocessed && alarm.IsActive" class="alarm-icon-size element-alarm-filled alarm-active-color" [style.font-size]="ICON_SIZE"/>
                    <!--Not faulty, Unacknowledged-->
                    <xhtml:div *ngIf="alarm.State === alarmState.Unprocessed && !alarm.IsActive" class="alarm-icon-size element-alarm alarm-background-color" [style.font-size]="ICON_SIZE"/>
                    <!--Faulty, Acknowledged (Background Icon) -->
                    <xhtml:div *ngIf="alarm.State === alarmState.Acked && alarm.IsActive" class="alarm-icon-size element-alarm-background-filled alarm-active-color" [style.font-size]="ICON_SIZE"/>
                    <!--Faulty, ReadyToReset (Background Icon) -->
                    <xhtml:div *ngIf="alarm.State === alarmState.ReadyToBeReset && alarm.IsActive" class="alarm-icon-size element-alarm-background-filled alarm-active-color" [style.font-size]="ICON_SIZE"/>
                    <!--Not faulty, Acknowledged (Background Icon)-->
                    <xhtml:div *ngIf="alarm.State === alarmState.ReadyToBeReset && !alarm.IsActive" class="alarm-icon-size element-alarm-background alarm-background-color" [style.font-size]="ICON_SIZE"/>
                  </svg:foreignObject>

                  <svg:foreignObject [attr.width]="ICON_SIZE" [attr.height]="ICON_SIZE">
                     <!--Faulty, Acknowledged (Foreground Icon) -->
                     <xhtml:div *ngIf="alarm.State === alarmState.Acked && alarm.IsActive" class="alarm-icon-size element-alarm-tick alarm-foreground-color" [style.font-size]="ICON_SIZE"/>
                     <!--Faulty, ReadyToReset (Foreground Icon) -->
                     <xhtml:div *ngIf="alarm.State === alarmState.ReadyToBeReset && alarm.IsActive" class="alarm-icon-size element-alarm-tick alarm-foreground-color" [style.font-size]="ICON_SIZE"/>
                     <!--Not faulty, Acknowledged (Foreground Icon)-->
                     <xhtml:div *ngIf="alarm.State === alarmState.ReadyToBeReset && !alarm.IsActive" class="alarm-icon-size element-alarm-tick alarm-foreground-color" [style.font-size]="ICON_SIZE"/>
                  </svg:foreignObject>
                </svg:g>
               </svg:g>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './gms-alarm.component.scss'
})

export class GmsAlarmComponent implements OnInit, OnDestroy {

  constructor(private readonly changeDetector: ChangeDetectorRef,
    private readonly zone: NgZone) {
  }

  public get Offset(): number {
    let offset: number = this.ICON_SIZE * 0.75;
    if (this.currentZoom <= 1) {
      offset = offset * this.currentZoom;
    }

    return offset;
  }

  /**
    Size of the alarm background circle shall be 30% more than the actual alarm size
    for appropriate padding around the alarm icon.
    example: for 16 as alarm icon size, background size is ~21 - with intention to have the
    smallest footprint for the alarm considering repication container scenarios.
   */
  public get BackgroundSize(): number {
    return this.ICON_SIZE + (this.ICON_SIZE * 0.30);
  }

  /**
    offset to position the alarm background circle
   */
  public get BackgroundOffset(): number {
    return -(this.ICON_SIZE * 0.30 / 2);
  }

  @Input() public alarm: GmsAlarm = null;
  @Output() public readonly openAlarmPopover: EventEmitter<any> = new EventEmitter<any>();
  public x = 0;
  public y = 0;
  public alarmState: any = AlarmState; // Store a reference to the enum, so that we can compare in the template
  public ICON_SIZE = 16;
  public sourceElement: SVGElement = null;
  private readonly FIXED_ICON_SIZE: number = 10;
  private _alarmschangedSubscription: Subscription = undefined;
  private _sourceShapeChanged: Subscription = undefined;
  private _zoomChangedSubscription: Subscription = undefined;
  private _propertyChangedSubscription: Subscription = undefined; // Only used for source element initialization
  @ViewChild('alarmGroup', { static: true }) private readonly alarmContainerRef: ElementRef;
  private alarmContainer: SVGGElement;
  private svgRoot: SVGSVGElement;
  private locatableElement: SVGGraphicsElement;
  private svgZoomTransFormGroup: SVGGElement;

  private zoomThresholdReached = false;
  private currentZoom: number = undefined;

  public ngOnInit(): void {

    const sourceElement: GmsElement = this.alarm.SourceElement;
    const graphic: GmsGraphic = sourceElement.Graphic as GmsGraphic;
    const actualIconSize: number = sourceElement.FixedAlarmSize ? this.FIXED_ICON_SIZE : graphic.AlarmIconSize;
    if (actualIconSize > 0 && actualIconSize !== this.ICON_SIZE) {
      this.ICON_SIZE = actualIconSize;
    }

    this._alarmschangedSubscription = this.alarm.alarmChanged.subscribe(value => {
      this.changeDetector.detectChanges(); // Update State Changes
    });

    this._sourceShapeChanged = sourceElement.shapeChanged.pipe(debounceTime(10)).subscribe(value => {
      if (this.zone !== undefined) {
        this.zone.runOutsideAngular(() => setTimeout(() => this.setPosition(graphic.CurrentZoomLevel), 0));
      }
    });

    this._propertyChangedSubscription = sourceElement.propertyChanged.pipe(debounceTime(10)).subscribe(value => {
      // Initialize source element from DOM
      if (this.sourceElement === null) {
        this.initializeSourceElement();
      }

      // Update visible state, if it has changed
      if (this.alarm !== undefined && sourceElement !== undefined) {
        if (value === 'Visible') { // visible property changes only
          // Trigger GetVisible re-evaluation
          this.changeDetector.detectChanges();
        }
      }
    });

    // Subscribe to listen to zoom changes
    this._zoomChangedSubscription = graphic.zoomChanged.subscribe(({ currentZoom, update }) => {
      asapScheduler.schedule(() => this.zone.runOutsideAngular(() => {
        if (update) {
          this.setPosition(currentZoom);
        }
      }), 0);
    });

    this.alarmContainer = this.alarmContainerRef.nativeElement as SVGGElement;

    this.initializeSourceElement();

    if (this.zone !== undefined) {
      this.zone.runOutsideAngular(() => setTimeout(() => this.setPosition(graphic.CurrentZoomLevel), 0));
    }
  }

  public ngOnDestroy(): void {
    this.alarmContainer = undefined;
    this.sourceElement = null;
    this.locatableElement = undefined;
    this.svgZoomTransFormGroup = undefined;

    if (this._zoomChangedSubscription !== undefined) {
      this._zoomChangedSubscription.unsubscribe();
      this._zoomChangedSubscription = undefined;
    }

    if (this._alarmschangedSubscription !== undefined) {
      this._alarmschangedSubscription.unsubscribe();
      this._alarmschangedSubscription = undefined;
    }

    if (this._sourceShapeChanged !== undefined) {
      this._sourceShapeChanged.unsubscribe();
      this._sourceShapeChanged = undefined;
    }

    if (this._propertyChangedSubscription !== undefined) {
      this._propertyChangedSubscription.unsubscribe();
      this._propertyChangedSubscription = undefined;
    }
  }

  public onAlarmClick(): void {
    const alarmRect: ClientRect = this.alarmContainerRef?.nativeElement?.getBoundingClientRect();
    if (alarmRect === undefined) {
      return;
    }

    const srcDesignations: string[] = this.alarm.getDesignations();
    const openAlarmPopover: Subject<any> = (this.alarm?.SourceElement?.Graphic as any)?.openAlarmPopover;
    openAlarmPopover?.next({ srcDesignations, alarmRect });
  }

  public GetTransformations(): string {
    return `translate(${this.x},${this.y})translate(${-this.Offset},${-this.Offset})`;
  }

  public GetVisible(): string {
    if (this.alarm === undefined || this.sourceElement === undefined) {
      return 'hidden';
    }

    if (this.zoomThresholdReached) {
      return 'hidden';
    }

    if (!!this.sourceElement && !Utility.isDomVisible(this.sourceElement)) {
      return 'hidden';
    }

    return this.alarm.SourceElement.GetVisible() === 'inherit' ? 'inherit' : 'hidden';
  }

  private initializeSourceElement(): void {
    // already initialized
    if (this.sourceElement !== null) {
      return;
    }

    let sourceElementToPosition: GmsElement = this.alarm.SourceElement;

    // Get the alarm-anchor if exists
    if (this.alarm.SourceElement instanceof GmsSymbolInstance) {
      const symbolInstance: GmsSymbolInstance = this.alarm.SourceElement as GmsSymbolInstance;
      sourceElementToPosition = symbolInstance
        .AlarmAnchor !== undefined ? symbolInstance.AlarmAnchor : sourceElementToPosition;
    }

    // Used for visibility of the alarm
    this.sourceElement = document.getElementById(this.alarm.SourceElement.Id) as any as SVGElement;

    // Used for positioning and transformation
    let element: SVGElement = this.sourceElement;

    // If alarm-anchor exists
    if (this.alarm.SourceElement !== sourceElementToPosition) {
      element = document.getElementById(sourceElementToPosition.Id) as any as SVGElement;
    }

    if (element !== null) {

      this.locatableElement = element as any as SVGGraphicsElement;

      // the top-level svg element
      this.svgRoot = this.locatableElement.viewportElement as SVGSVGElement;

      // the graphic component container for child elements, affected by zooming and panning
      this.svgZoomTransFormGroup = this.svgRoot.getElementById('ContentGroup') as SVGGElement;

      this.setPosition(this.currentZoom);
    }
  }

  private async setPosition(currentZoom: number = 1): Promise<void> {

    if (this.sourceElement === null) {
      return;
    }

    // Initialize the current zoom level
    this.currentZoom = currentZoom;

    // Get transform matrix of source element. Contains zoom/pan transformations
    const originalTransform: SVGMatrix = this.locatableElement.getCTM();
    const boundingBox: DOMRect = this.locatableElement.getBBox();
    if (this.alarm.SourceElement === undefined) {
      return;
    }

    let matrix: SVGMatrix;
    if (this.svgZoomTransFormGroup !== null) {
      // cancel duplicated zoom/pan transformations
      matrix = this.svgZoomTransFormGroup.getCTM().inverse().multiply(originalTransform);
    } else if (this.alarm.SourceElement.IsReplicationClone) {
      const cloneCTM: SVGMatrix = this.alarm.SourceElement
        .AlarmsContainerRef instanceof GmsGraphic ? this.getCloneCTM() : null;
      matrix = cloneCTM !== null ? cloneCTM : originalTransform;
    } else {
      matrix = originalTransform;
    }

    // all transformations from root to source element
    const transform: SVGTransform = this.svgRoot.createSVGTransformFromMatrix(matrix);

    const negateScale: number = Math.abs(1 / currentZoom);

    this.zoomThresholdReached = negateScale >= 3;

    if (this.currentZoom <= 1) {
      this.x = boundingBox.x * this.currentZoom;
      this.y = boundingBox.y * this.currentZoom;
    } else {
      this.x = boundingBox.x;
      this.y = boundingBox.y;
    }

    transform.matrix.a = currentZoom >= 1 ? 1 : negateScale;
    transform.matrix.b = 0;
    transform.matrix.c = 0;
    transform.matrix.d = currentZoom >= 1 ? 1 : negateScale;

    this.changeDetector.detectChanges();
    this.alarmContainer.transform.baseVal.initialize(transform);
  }

  // Cumulative matrix calculation for element in nested svg documents
  // Needed for replication without scrollbars
  // Alarms needed to be displayed at the graphic level.
  // VisualTree structure for Simple & Nested Replication(s)
  // Simple - svg(root)->foreignobject->div->svg
  // Nested - svg(root)->foreignObject(parent replication)->div>svg->foreignObject(child replication)->div->svg..so on
  private getCloneCTM(): SVGMatrix {
    const element: any = this.locatableElement as any;
    let viewportElement: any = this.locatableElement.viewportElement;
    let matrix: SVGMatrix = this.locatableElement.getCTM();

    // Traverse up in visual tree and consider the transformations
    // from the parent svg documents
    do {
      let parentforeignObject: any = null;
      if (viewportElement.parentElement && viewportElement.parentElement.parentElement) {
        parentforeignObject = viewportElement.parentElement.parentElement;
        if (parentforeignObject instanceof SVGForeignObjectElement) {
          matrix = parentforeignObject.getCTM().multiply(matrix);
        }
      }

      // Negate the zoom transforms from the root svg
      if (viewportElement.id === 'svgDoc') { // detect root svg
        const contentGroup: any = viewportElement.getElementById('ContentGroup');
        if (contentGroup !== null) {
          matrix = contentGroup.getCTM().inverse().multiply(matrix);
        }
        viewportElement = null;
      } else {
        if (viewportElement.viewportElement) {
          viewportElement = viewportElement.viewportElement;
        } else if (parentforeignObject !== null) {
          viewportElement = parentforeignObject.viewportElement;
        }
      }
    } while (!!viewportElement);

    return matrix;
  }
}
