import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { catchError, map, Observable, of, tap, throwError } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { FilterType } from "../shared/base.model";
import { HttpUtilityService } from "../shared/http-utility.service";
import { scheduleBACnetDescription, scheduleData } from "./models/scheduleBACnetDescription";
import { scheduleBACnetDescriptionUpdatable } from "./models/scheduleBACnetDescriptionUpdatable";
import { scheduleCollection, scheduleCollectionElement } from "./models/scheduleCollectionElement";
import { ScheduleStatus } from "./schedule-proxy.model";
import { ScheduleProxyService } from "./schedule-proxy.service";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly scheduleProxyService: ScheduleProxyService,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesSchedules, 'ScheduleService created.');
  }

  public getSchedules(partitionId: string, entityId: string, filter?: string, filterType?: FilterType): Observable<scheduleCollectionElement[]> {
    this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules() called: partitionId: ${partitionId}, entityId: ${entityId}`);
    return this.scheduleProxyService.getSchedules(partitionId, entityId)
      .pipe(
        map(result => this.filterSchedules(result, filter, filterType)),
        tap(schedules => {
          this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules() returned: no of schedules: ${schedules.length} from backend
        partitionId=${partitionId}, filter=${filter}`);
        }),
        catchError(error => {
          this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules(): Error: ${entityId}, error=${error}`);
          this.httpUtilityService.handleError(error, "ScheduleService.getSchedules()");
          return of([]);
        })
      );
  }

  public getSchedule(partitionId: string, scheduleId: string): Observable<scheduleData> {
    this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedule() called: partitionId: ${partitionId},
    scheduleId: ${scheduleId}`);
    
    return this.scheduleProxyService.getSchedule(partitionId, scheduleId);
  }

  public saveSchedule(partitionId: string, scheduleId: string, schedule: scheduleBACnetDescription): Observable<boolean> {
    
    const scheduleToBeSaved: scheduleBACnetDescriptionUpdatable = {
      data: {
        id: scheduleId,
        type: schedule.type,
        attributes: {
          defaultValue: schedule.attributes?.defaultValue,
          priority: schedule.attributes?.priority,
          dateRange: schedule.attributes?.dateRange,
          weeklySchedule: schedule.attributes?.weeklySchedule,
          exceptionSchedule: schedule.attributes?.exceptionSchedule
        }
      }
    }
   
    return this.scheduleProxyService.updateSchedule(partitionId, scheduleId, scheduleToBeSaved).pipe(
      map(() => { return true }),
      catchError(error => {
        this.traceService.warn(TraceModules.bxServicesSchedules, `ScheduleService.saveSchedule(): error in update schedule: ${scheduleId}, error=${error}`);
        return this.httpUtilityService.handleError(error, "ScheduleService.saveSchedule()");
      })
    )
  }

  private filterSchedules(schedules: scheduleCollection, filter?: string, filterType?: FilterType): scheduleCollectionElement[] {
    if (filter) {
      if (filterType === 'Name') {
        const found = this.findSchedule(schedules, filter);
        return found ? [found] : [];
      } else {
        const found = this.findScheduleById(schedules, filter);
        return found ? [found] : [];
      }
    } else {
      return schedules.data;
    }
  }

  private findSchedule(scheduleCollectionData: scheduleCollection, name: string): scheduleCollectionElement | undefined {
    return scheduleCollectionData.data.find(schedule => schedule.name === name);
  }

  private findScheduleById(scheduleCollectionData: scheduleCollection, id: string): scheduleCollectionElement | undefined {
    return scheduleCollectionData.data.find(schedule => schedule?.id === id);
  }
}
