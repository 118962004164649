import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SiCardComponent } from '@simpl/element-ng/card';
import { AccentLineType, MenuItem } from '@simpl/element-ng/common';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

import { SiWidgetBaseComponent } from '../si-widget-base.component';
import { SiTimelineWidgetBodyComponent } from './si-timeline-widget-body.component';
import { SiTimelineWidgetItem } from './si-timeline-widget-item.component';

@Component({
  selector: 'si-timeline-widget',
  templateUrl: './si-timeline-widget.component.html',
  standalone: true,
  imports: [
    NgClass,
    SiLinkDirective,
    SiCardComponent,
    SiTranslateModule,
    SiTimelineWidgetBodyComponent
  ]
})
export class SiTimelineWidgetComponent
  extends SiWidgetBaseComponent<SiTimelineWidgetItem[]>
  implements OnChanges
{
  /**
   * Value widget header text.
   */
  @Input() heading?: TranslatableString;
  /**
   * Input list of primary action items. Supports up to **4** actions and omits additional ones.
   *
   * @defaultValue []
   */
  @Input() primaryActions: MenuItem[] = [];
  /**
   * Input list of secondary action items.
   *
   * @defaultValue []
   */
  @Input() secondaryActions: MenuItem[] = [];
  /**
   * Link that leads the user to more information
   * or triggers and action. The `link.title` is displayed.
   * The title will be translated.
   */
  @Input() link?: Link;

  /**
   * Number of skeleton progress indication items.
   *
   * @defaultValue 4
   */
  @Input() numberOfItems = 4;

  /**
   * Whether to show or hide the description row during skeleton progress indication.
   *
   * @defaultValue `true`
   */
  @Input() showDescription = true;
  /**
   * Optional accent line
   */
  @Input() accentLine?: AccentLineType;

  protected get accentClass(): string {
    return this.accentLine ? 'accent-' + this.accentLine : '';
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
