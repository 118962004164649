import { Observable, Subject } from 'rxjs';

import { Action, NotifConfiguration, Notification } from './data.model';

/**
 * Base class for the notification service.
 *
 */
export abstract class NotificationServiceBase {
  public abstract closeRightPanel: Subject<boolean>;

  /**
   * Register to the notification center, opening the channel with a sender string to send all the notifications.
   *
   * @abstract
   * @param {string} sender
   * @memberof NotificationServiceBase
   */
  public abstract register(sender: string, configuration: NotifConfiguration): void;

  /**
   * Push to the notification center a notification specifying the sender. This method could be used
   * also to update and existing notification simply recalling it.
   *
   * @abstract
   * @param {string} sender
   * @param {Notification} notification
   * @memberof NotificationServiceBase
   */
  public abstract notify(sender: string, notification: Notification): void;

  /**
   * Returns an array of all senders and its configurations.
   *
   * @abstract
   * @type {Map<string, NotifConfiguration>}
   * @memberof NotificationServiceBase
   */
  public abstract getConfigurations(): Map<string, NotifConfiguration>;

  /**
   * Updates all sender configurations.
   *
   * @abstract
   * @param {Map<string, NotifConfiguration>} configurations
   * @memberof NotificationServiceBase
   */
  public abstract updateConfigurations(configurations: Map<string, NotifConfiguration>): void;

  /**
   * Returns an array of all the active notifications for the specified sender.
   *
   * @abstract
   * @param {string} sender
   * @type {Notification[]}
   * @memberof NotificationServiceBase
   */
  public abstract getActiveNotifications(sender: string): Notification[];

  /**
   * Create a subscription with the NotificationService so that the consumer will be notified
   * in case of any change to notifications.
   *
   * @abstract
   * @type {Observable<Notification>}
   * @memberof NotificationServiceBase
   */
  public abstract subscribeNotifications(): Observable<Notification>;

  /**
   * Create a subscription with the NotificationService so that the consumer will be notified
   * in case of any change to notification configurations.
   *
   * @abstract
   * @type {Observable<Map<string, NotifConfiguration>>}
   * @memberof NotificationServiceBase
   */
  public abstract subscribeConfigurations(): Observable<Map<string, NotifConfiguration>>;

  /**
   * Remove the notification having the specified sender and notificationID.
   *
   * @abstract
   * @param {string} sender
   * @param {number} notificationId
   * @memberof NotificationServiceBase
   */
  public abstract cancel(sender: string, notificationId: number): void;

  /**
   * Remove all the notifications emitted by a specific sender.
   *
   * @abstract
   * @param {string} sender
   * @memberof NotificationServiceBase
   */
  public abstract cancelAll(sender: string): void;

  /**
   * Performs a specified action on the notification.
   *
   * @abstract
   * @param {Notification} notification
   * @param {Action} action
   * @memberof NotificationServiceBase
   */
  public abstract performAction(notification: Notification, action: Action): void;

  /**
   * Performs a specified action on all notifications from a specific sender.
   *
   * @abstract
   * @param {sender} string
   * @memberof NotificationServiceBase
   */
  public abstract performGroupAction(sender: string): void;

  /**
   * Hide all notifications that share a custom sub config id.
   *
   * @abstract
   * @param {subId} number
   * @memberof NotificationServiceBase
   */
  public abstract hideSub(subId: number): void;
}
