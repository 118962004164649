import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { SiCookiesService } from '@building-x/common-ui-ng';
import { StateService } from '@gms-flex/core';
import { AppContextService, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiThemeService, Theme, ThemeType } from '@simpl/element-ng';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { SessionService } from './core/services/session.service';
import { NotifyDialogComponent } from './notification-dialog/notify-dialog.component';

export const trcModuleNameApp = 'cbmsApplication_Application';
const gmsDeepLink = 'cbms-deep-link';

@Component({
  selector: 'cbms-app',
  templateUrl: './app.component.html'
})

export class ApplicationComponent implements OnInit, OnDestroy {
 
  private readonly routingSub: Subscription;
  private modalRef: BsModalRef;
  constructor(private readonly traceService: TraceService,
    private readonly modalService: BsModalService,
    private readonly stateService: StateService,
    private readonly router: Router,
    private readonly appContextService: AppContextService,
    private readonly translateService: TranslateService,
    private readonly siThemeService: SiThemeService,
    private readonly sessionService: SessionService,
    private readonly httpClient: HttpClient,
    private readonly cookieService: SiCookiesService
  ) {
    this.traceService.info(trcModuleNameApp, 'Launching application…');

    if (window.location.href.includes('#') && !window.location.href.includes('/#')) {
      window.location.href = window.location.origin + '/#' + window.location.hash.substring(1);
    }
    this.routingSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart
        || event instanceof NavigationEnd
        || event instanceof NavigationError
        || event instanceof NavigationCancel
        || event instanceof RoutesRecognized) {
        this.saveFirstUrl(event);
      }
    });
    this.appContextService.themeType.subscribe(type => {
      this.traceService.info(trcModuleNameApp, `Applying theme type: Type: ${type}`);
      if (this.siThemeService.resolvedColorScheme === type) {
        // Note:
        // SiSchemeService will not do a change of its theme type in this case.
        // If the selected scheme type is 'auto', the OS setting is 'dark' => the resolvedColorScheme is 'dark',
        // if we change now the selected theme type to 'dark', this should cause a change nevertheless. But SiThemeService remains on 'auto'
        // This is a workaround to fix the issue: Force an (unneeded) theme type change which triggers SiThemeService.
        this.siThemeService.applyThemeType((type === 'light') ? 'dark' : 'light');
      }
      this.siThemeService.applyThemeType(type);
    });
  }

  /*
  * Handles initialization after directive's data-bound properties have been initialized.
  */
  public ngOnInit(): void {
    const culture = this.cookieService.getCookie('lang') || 'en';
    this.appContextService.setUserCulture(culture);
    
    this.translateService.setDefaultLang('en');
    this.translateService.use(culture);
 
    this.sessionService.getAccountInfo().then(accountInfo => {
      this.appContextService.setUserDescriptor(accountInfo.name);
      this.appContextService.setUserName(accountInfo.email);
    });

    this.siThemeService.themeNames$.pipe(first()).subscribe(names => {
      const customThemeName = 'custom';
      if (!names.includes(customThemeName)) {
        const customThemePath = './assets/themes/theme-' + customThemeName + '.json';
        this.httpClient.get<Theme>(customThemePath).subscribe({
          next: theme => {
            this.traceService.info('Added new custom theme, ' + customThemePath);
            this.siThemeService.addOrUpdateTheme(theme);
            this.traceService.info('Setting active theme to: ' + customThemeName);
            this.siThemeService.setActiveTheme(customThemeName);
          },
          error: error =>
            this.traceService.error('Error loading custom theme ' + customThemePath + ', default theme element is now active, details: ' + error + '.')
        });
      } else {
        this.traceService.info('Setting active theme to: ' + customThemeName);
        this.siThemeService.setActiveTheme(customThemeName);
      }
    });

    this.applyThemeType('auto');
  }

  public ngOnDestroy(): void {
    this.traceService.info(trcModuleNameApp, 'Application component destroyed.');
  }
  
  private applyThemeType(themeType?: ThemeType): void {
    if (themeType === undefined) {
      themeType = 'auto';
    }
    this.appContextService.setThemeType(themeType);
  }

  private showNotifyDialog(heading: string, message: string): void {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      keyboard: false,
      animated: true
    };
    initialState.initialState = {};
    initialState.initialState.heading = heading;
    initialState.initialState.message = message;

    this.modalRef = this.modalService.show(NotifyDialogComponent, initialState);
  }

  private hideNotifyDialog(): void {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }

  private saveFirstUrl(event: any): void {
    this.routingSub.unsubscribe();
    let url: string | null = (event.url).toString();
    if (url?.startsWith('/#')) {
      url = url.substring(2);
    }
    if (!url) {
      return;
    }
    if (url.startsWith('/login?code')) {
      url = sessionStorage.getItem(gmsDeepLink);
      if (url) {
        sessionStorage.removeItem(gmsDeepLink);
        this.stateService.redirectUrl = url;
      } else {
        this.stateService.redirectUrl = '/';
      }
    } else if (url === '/' || url === '/loading' || !url.startsWith('/main/page')) {
      this.stateService.redirectUrl = '/';
      if (sessionStorage.getItem(gmsDeepLink)) {
        sessionStorage.removeItem(gmsDeepLink);
      }
    } else {
      sessionStorage.setItem(gmsDeepLink, url);
      this.stateService.redirectUrl = url;
    }
  }

}
