import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScheduleServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { map, Observable, of } from 'rxjs';

import { ontology, scheduleBACnetDescription, ScheduleService } from '../../bx-services/schedule';
import { SystemBrowserMapperBxToGmsService } from '../system-browser/system-browser-mapper-bx-to-gms.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleBxSubstituteService extends ScheduleServiceBase {

  /**
   * Constructor
   * @param {TraceService } traceService The trace service
   * @param {HttpClient } httpClient  The http service
   * @param {WsiEndpointService } wsiEndpoint The WSI endpoint service.
   */
  public constructor(
    public traceService: TraceService,
    protected httpClient: HttpClient,
    private readonly scheduleService: ScheduleService,
    private readonly systemBrowserMapper: SystemBrowserMapperBxToGmsService) {
    super();
    this.traceService.info(TraceModules.scheduler, 'scheduler service created.');
  }

  public getSchedulesList(objectId: string): Observable<any> {
    this.traceService.info(TraceModules.scheduler, 'getSchedulesList() called for: ' + objectId);
    const substrings = objectId.split(':');
    const partitionId = substrings[0]

    // return this.scheduleService.getSchedules(partitionId, entityId);
    return of([]);
  }

  /*
    * Get the schedule for the given objectId
    * @param {string} objectId The object id
    * @returns {Observable<any>} The observable of the schedule
    * Get schedule return schedule id in the form of partitionId:entityId:ScheduleId
    * Reason: To get associated calendars w.r.t schedule or device need entity id
  */
  public getSchedules(objectId: string): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'getSchedules() called for: ' + objectId);

    const substrings = objectId.split(':');

    const entityId = this.systemBrowserMapper.getParentEntityId(substrings[1]);
   
    return this.scheduleService.getSchedule(substrings[0], substrings[1]).pipe(
      map(schedule => {
        // set schedule id in the form of partitionId:entityId:ScheduleId
        schedule.data.id = `${substrings[0]}:${entityId}:${substrings[1]}`;
        return schedule;
      }));
  }

  public getCalendarList(objectId: string): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'getCalendarList() called for: ' + objectId);

    return of([]);
  }

  public getCalendarExceptions(calendarObjectIds: string[]): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'getCalendarExceptions() called', calendarObjectIds);
    return of([]);
  }

  public saveSchedule(schedule: scheduleBACnetDescription): Observable<any> {
    const substrings = schedule.id.split(':');
    return this.scheduleService.saveSchedule(substrings[0], substrings[2], schedule);
  }

  public saveExceptions(schedule: scheduleBACnetDescription): Observable<any> {

    const substrings = schedule.id.split(':');
    return this.scheduleService.saveSchedule(substrings[0], substrings[2], schedule);
  }

  public saveScheduleOptions(schedule: any): Observable<any> {
    this.traceService.info(TraceModules.scheduler, 'saveScheduleOptions() called for: ' + schedule.Name);
    return of([]);
  }
}
