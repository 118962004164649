import { Injectable } from '@angular/core';

import { ObjectManagerSettingsServiceBase } from './object-manager-settings.service.base';

/**
 * ObjectManagerSettings service.
 * Provides the methods to get if object manager functionalities are visible.
 *
 * @export
 * @class ObjectManagerSettingsService
 * @extends {ObjectManagerSettingsServiceBase}
 */

@Injectable({
  providedIn: 'root'
})
export class ObjectManagerSettingsService implements ObjectManagerSettingsServiceBase {
  public showFilterButton(): boolean {
    return true;
  }
}
